import i18n from 'i18next';
import { toast } from 'react-toastify';

import {
    POST_FORGOTPASSWORD_START,
    POST_FORGOTPASSWORD_FAIL,
    POST_FORGOTPASSWORD,
    FORGOTPASSWORD_INFO_CHANGED
} from '../types';
import { badReq, noConnection } from '../../helpers/errors';
import request from '../../api';
import history from '../../history';
import PATHS from '../../Routers/PATHS';


export const forgotInfoChanged = ({ props, value }) => {
    return { type: FORGOTPASSWORD_INFO_CHANGED, payload: { props, value } };
};

export const postForgotPassword = (email) => async dispatch => {
    dispatch({ type: POST_FORGOTPASSWORD_START });
    try {
        await request.get(`/auth/account/resetpassword/code/${email}`);
        dispatch({ type: POST_FORGOTPASSWORD });
        toast.info(i18n.t('CheckEmail'), { position: toast.POSITION.BOTTOM_CENTER });
        history.push(PATHS.LANDING_HOME.URL);
    } catch (err) {
        if (err.response.status === 400) {
            return dispatch({ type: POST_FORGOTPASSWORD_FAIL, payload: i18n.t('EmailError') });
        }
        if (err.response) return badReq();
        else noConnection();
    }
};