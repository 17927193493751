import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import { Link, useLocation, matchPath } from 'react-router-dom';
import { Sidenav, Nav, Badge } from 'rsuite';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useProSidebar } from 'react-pro-sidebar';
import { getSideBarData } from '../SidebarData';
import { Languager } from '../../components';
import { signout } from '../../actions';
import { Theme } from '../../res';
import PATHS from '../../Routers/PATHS';
import OffIcon from '@rsuite/icons/Off';
import GlobalIcon from '@rsuite/icons/Global';
import NoticeIcon from '@rsuite/icons/Notice';
import AdminIcon from '@rsuite/icons/Admin';


const SideBarNav = (props) => {
    const { user, signout, notNormalSeen, openCloseSideBar, opened } = props;
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const { broken } = useProSidebar();

    return (
        <Sidenav expanded={opened} style={{ width: 'auto', minHeight: '100vh', backgroundColor: Theme.colors.PRIMARY_50, color: Theme.colors.WHITE }}>
            <Sidenav.Body style={{ backgroundColor: Theme.colors.PRIMARY_50 }}>
                <Nav style={{ backgroundColor: Theme.colors.PRIMARY_50, color: Theme.colors.WHITE }} >
                    {getSideBarData(user.isAdmin).map(item => <Nav.Menu
                        key={item.name}
                        icon={item.icon}
                        // as={!item.subs ? Link : ''}
                        // to={!item.subs ? item.link : ''}
                        eventKey={item.name}
                        title={item.name}
                        active={matchPath(pathname, { path: item.link, exact: true })?.isExact}
                    >
                        {item.subs.map(sub =>
                            <Nav.Item
                                style={{ color: Theme.colors.WHITE, backgroundColor: Theme.colors.PRIMARY_50 }}
                                key={sub.name}
                                icon={<Icon name={sub.icon} />}
                                // title={sub.name}
                                as={Link}
                                to={sub.link}
                                eventKey={sub.name}
                                onClick={broken ? () => openCloseSideBar(false) : undefined}
                                active={matchPath(pathname, { path: sub.link, exact: true })?.isExact}
                            >
                                {sub.name}
                            </Nav.Item>
                        )}
                    </Nav.Menu>
                    )}
                </Nav>
            </Sidenav.Body>

            <Nav style={{ backgroundColor: Theme.colors.PRIMARY_50, color: Theme.colors.WHITE }} pullRight>

                <Nav.Item
                    style={{ color: Theme.colors.WHITE, backgroundColor: Theme.colors.PRIMARY_50 }}
                    icon={<AdminIcon />}
                    as={Link}
                    to={PATHS.LANDING_PROFILE.URL}
                    eventKey={t('Profile')}
                    onClick={broken ? () => openCloseSideBar(false) : undefined}
                    active={matchPath(pathname, { path: PATHS.LANDING_PROFILE.URL, exact: true })?.isExact}
                >
                    {t('Profile')}
                </Nav.Item>

                <Nav.Item
                    style={{ color: Theme.colors.WHITE, backgroundColor: Theme.colors.PRIMARY_50 }}
                    icon={<NoticeIcon pulse={notNormalSeen !== 0} style={{ color: notNormalSeen !== 0 && Theme.colors.ERROR_75, transform: notNormalSeen !== 0 && 'rotate(20deg)' }} />}
                    as={Link}
                    to={PATHS.USER_NOTIFICATIONS.URL}
                    eventKey={t('Notifications')}
                    onClick={broken ? () => openCloseSideBar(false) : undefined}
                    active={matchPath(pathname, { path: PATHS.USER_NOTIFICATIONS.URL, exact: true })?.isExact}

                >
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        {t('Notifications')}
                        {notNormalSeen !== 0 && <Badge content={notNormalSeen} />}
                    </div>
                </Nav.Item>

                <Popup
                    on='click'
                    flowing
                    hoverable
                    trigger={
                        <Nav.Item
                            style={{ color: Theme.colors.WHITE, backgroundColor: Theme.colors.PRIMARY_50 }}
                            icon={<GlobalIcon />}
                        >
                            {t('Language')}
                        </Nav.Item>
                    }
                >
                    <Languager isMenu />
                </Popup>
                <Nav.Item
                    style={{ color: Theme.colors.WHITE, backgroundColor: Theme.colors.PRIMARY_50 }}
                    icon={<OffIcon />}
                    onClick={signout}
                >
                    {t('Logout')}
                </Nav.Item>
            </Nav>
        </Sidenav >
    );
};

const mapStateToProps = ({ notifications }) => {
    const { notNormalSeen } = notifications;
    return { notNormalSeen };
};

export default connect(mapStateToProps, { signout })(SideBarNav);
