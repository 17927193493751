import {
    CONFIGS_INFO_CHANGED,
} from '../types';


export const configsInfoChanged = ({ props, value }) => {
    return { type: CONFIGS_INFO_CHANGED, payload: { props, value } };
};


