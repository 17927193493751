import React, { useState, useRef } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Segment, Form, Message, TextArea } from 'semantic-ui-react';
import { Styles } from '../../res';
import { postContactUs, contactUsInfoChanged } from '../../actions';

const { solidBtn, userSegmentStyle } = Styles;

const ContactUsForm = (props) => {
    const { error, loading, contactUsInfoChanged, postContactUs } = props;
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const recaptchaRef = useRef();

    const clear = () => {
        setName('');
        setEmail('');
        setMessage('');
        setSubject('');
    };

    const validate = () => {
        if (!name) {
            setError({ ...error, name: t('Required') });
            return false;
        }
        if (!email) {
            setError({ ...error, email: t('Required') });
            return false;
        }
        if (!subject) {
            setError({ ...error, subject: t('Required') });
            return false;
        }
        if (!message) {
            setError({ ...error, message: t('Required') });
            return false;
        }
        setError('');
        return true;
    };


    const setError = (value) => contactUsInfoChanged({ props: 'error', value });


    const onSubmitPressed = async () => {
        if (validate()) {
            try {
                let token;
                token = await recaptchaRef.current.getValue();
                if (!token)
                    token = await recaptchaRef.current.executeAsync();
                postContactUs(name, email, subject, message, token, clear);
            } catch (err) {
                setError({ ...error, robot: t('Robot') });
            }
        }
    };
    const renderErrorMsg = () => {
        if (!error) return;
        return <Message negative icon='x' header={t('Error')} content={Object.values(error)[0]} />;
    };


    return (
        <Segment basic clearing style={{ ...userSegmentStyle, padding: 20, boxShadow: '0px 20px 50px rgba(0, 0, 0, 0.16)' }}>
            {renderErrorMsg()}
            <Form loading={loading}>
                <Form.Group widths='equal'>
                    <Form.Input
                        fluid
                        type='text'
                        label={t('Name')}
                        placeholder={`${t('John')} ${t('Doe')}`}
                        required
                        maxLength={30}
                        size='large'
                        value={name}
                        onChange={(e, { value }) => setName(value)}
                        onFocus={() => setError('')}
                        error={error.name}
                    />

                    <Form.Input
                        fluid
                        type='email'
                        label={t('Email')}
                        placeholder='example@ex.com'
                        required
                        maxLength={50}
                        size='large'
                        value={email}
                        onChange={(e, { value }) => setEmail(value.toLowerCase())}
                        onFocus={() => setError('')}
                        error={error.email}
                    />
                </Form.Group>

                <Form.Input
                    fluid
                    type='text'
                    label={t('Subject')}
                    placeholder={t('Subject')}
                    required
                    maxLength={30}
                    size='large'
                    value={subject}
                    onChange={(e, { value }) => setSubject(value)}
                    onFocus={() => setError('')}
                    error={error.subject}
                />
                <Form.Field
                    control={TextArea}
                    type='text'
                    label={t('Message')}
                    required
                    maxLength={300}
                    value={message}
                    error={error.message}
                    onChange={(e, { value }) => setMessage(value)}
                    onFocus={() => setError('')}
                />

                <Form.Field>
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey={process.env.REACT_APP_RECAPTCH_KEY}
                    />
                </Form.Field>

                <Form.Button type='submit' style={solidBtn} size='big' floated='right' onClick={onSubmitPressed}>{t('SendMessage').toUpperCase()}</Form.Button>
            </Form>
        </Segment>
    );

};

const mapStateToProps = ({ contactus }) => {
    const { loading, error } = contactus;
    return { loading, error };
};

export default connect(mapStateToProps, { postContactUs, contactUsInfoChanged })(ContactUsForm);