import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import PATHS from '../../Routers/PATHS';

export default (ChildComponent) => {

    const RequireNoAuth = (props) => {

        switch (props.user) {
            case null:
            case "":
                return <ChildComponent {...props} />;
            default:
                return <Redirect to={PATHS.ADMIN_DASHBOARD.URL} />;
        };
    };

    const mapStateToProps = ({ auth }) => {
        const { user } = auth;
        return { user };
    };

    return connect(mapStateToProps, {})(RequireNoAuth);
};