import React from 'react';
import { Redirect } from 'react-router-dom';
// import { Segment, Container, Header, Button } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import PATHS from '../../Routers/PATHS';


const NoCourse = ({ t }) => {
    return (
        <Redirect to={PATHS.USER_DASHBOARD.URL} />
        // <Segment basic>
        //     <Container style={{ width: '90vw' }}>
        //         <Header as='h4'>{t("PleaseSelectCourseToStudy")}</Header>
        //         <Button
        //             as={Link}
        //             to={PATHS.USER_DASHBOARD.URL}
        //             primary
        //             icon='arrow right'
        //             labelPosition='left'
        //             content={t('Dashboard')}
        //         />
        //     </Container>
        // </Segment>
    );
};

export default withTranslation()(NoCourse);