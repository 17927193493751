import __ from 'lodash';
import React, { useState, useRef } from 'react';
import { Button, Divider, Header, Form as SForm, Segment, Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import DataGrid, { Column, Editing, Form, Lookup, Button as DevButton } from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import stringShorten from '../../../helpers/stringShorten';
import { htmlToText } from 'html-to-text';
import TextEditor from '../../Generals/TextEditor';

const prepareCells = (rCount, cCount, cells) => {
    let c = [];
    for (let i = 0; i < rCount; i++) {
        const row = [];
        for (let j = 0; j < cCount; j++) {
            row.push(cells[i * cCount + j]);
        }
        c.push(row);
    }

    return c;
};

const TableSection = ({ data, saveSection, deleteSection, cloneSection, moveSectionDown, moveSectionUp, sectionIndex }) => {
    const { t } = useTranslation();
    const gridRef = useRef();
    const [rowsCount, setRowsCount] = useState(data?.rowsCount || 1);
    const [colsCount, setColsCount] = useState(data?.colsCount || 1);
    const [cells, setCells] = useState(data?.cells || []);

    const onGenerateCells = () => {
        const dims = parseInt(rowsCount) * parseInt(colsCount);
        if (dims > cells.length) {
            setCells([...cells, ...Array.from({ length: dims - cells.length }).map((_, index) => ({ colspan: 1, rowspan: 1, order: index + 1 + cells.length, cellId: `${__.uniqueId('cell')}_${new Date().getTime()}`, cellType: "NORMAL_TEXT", textEn: "", textAr: "", options: [] }))]);
        }
        else setCells(cells.slice(0, dims).map((c, index) => ({ ...c, order: index + 1 })));
    };

    const cloneCell = (e) => {
        const temp = [...cells];
        const newId = `${__.uniqueId('cell')}_${new Date().getTime()}`;
        const clonedItem = { ...e.row.data, cellId: newId, __KEY__: newId, options: e.row.data.options.map(o => ({ ...o, __KEY__: `${__.uniqueId('option')}_${new Date().getTime()}` })) };
        temp.splice(e.row.rowIndex + 1, 1, clonedItem);
        setCells(temp.map((c, index) => ({ ...c, order: index + 1 })));
    };

    const onReorder = (e) => {
        const visibleRows = e.component.getVisibleRows();
        const newCells = [...cells];

        const toIndex = newCells.findIndex((item) => item.cellId === visibleRows[e.toIndex].data.cellId);
        const fromIndex = newCells.findIndex((item) => item.cellId === e.itemData.cellId);

        newCells.splice(fromIndex, 1);
        newCells.splice(toIndex, 0, e.itemData);

        setCells(newCells.map((c, index) => ({ ...c, order: index + 1 })));
    };

    const renderMockTable = () => {
        const table = prepareCells(rowsCount, colsCount, cells);
        return (
            <Table basic='very' renderBodyRow celled unstackable collapsing verticalAlign='middle' textAlign='center' style={{ borderCollapse: 'collapse' }}>
                <Table.Body>
                    {
                        table.map((row, i) => <Table.Row key={i}>
                            {
                                row.map((col, j) => {
                                    if (!col) return null;
                                    if (col.colspan === 0 || col.rowspan === 0) return null;
                                    return <Table.Cell rowSpan={col?.rowspan || 1} colSpan={col?.colspan || 1} key={j}>
                                        {col.order}
                                    </Table.Cell>;
                                })
                            }
                        </Table.Row>)
                    }
                </Table.Body>
            </Table>
        );
    };

    const customizeItem = (item) => {
        let gridInstance = gridRef.current.instance;
        let editRowKey = gridInstance.option('editing.editRowKey');
        let index = gridInstance.getRowIndexByKey(editRowKey);
        let cellType = gridInstance.cellValue(index, "cellType");
        if (item && item.dataField === "correctAnswer") {
            if (cellType !== "NORMAL_QUESTION") item.disabled = true;
        } else if (item && item.dataField === "options") {
            if (cellType !== "MCQ_QUESTION") item.disabled = true;
        }
    };

    return (
        <Segment basic>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <Header icon="th list" content={`${t("Section")} #${data.order}`} />
                <div>
                    <Button icon='save' positive onClick={() => saveSection(data.sectionId, { ...data, rowsCount, colsCount, cells: gridRef.current._instance.getDataSource()._items })} />
                    <Button icon='copy' positive onClick={() => cloneSection({ ...data, rowsCount, colsCount, cells: gridRef.current._instance.getDataSource()._items.map(i => ({ ...i, cellId: `${__.uniqueId('cell')}_${new Date().getTime()}`, __KEY__: `${__.uniqueId('cell')}_${new Date().getTime()}`, options: i.options.map(o => ({ ...o, __KEY__: `${__.uniqueId('option')}_${new Date().getTime()}` })) })) })} />
                    <Button icon='trash' negative onClick={() => deleteSection(data.sectionId)} />
                    <Button title={t("Up")} size='small' icon='arrow up' color='blue' onClick={() => moveSectionUp(sectionIndex)} />
                    <Button title={t("Down")} size='small' icon='arrow down' color='blue' onClick={() => moveSectionDown(sectionIndex)} />
                </div>
            </div>
            <Divider />
            <Segment>
                <Header>{t("CellsManagement")}</Header>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <SForm >
                            <SForm.Group widths='equal'>
                                <SForm.Input
                                    placeholder={t("RowsCount")}
                                    value={rowsCount}
                                    type='number'
                                    onChange={(e, { value }) => setRowsCount(value)}
                                    label={t("RowsCount")}
                                />
                                <SForm.Input
                                    placeholder={t("ColumnsCount")}
                                    value={colsCount}
                                    type='number'
                                    onChange={(e, { value }) => setColsCount(value)}
                                    label={t("ColumnsCount")}
                                />
                            </SForm.Group>
                        </SForm>
                    </div>
                    {renderMockTable()}
                    <Button primary icon='cog' content={t("GenerateCells")} onClick={onGenerateCells} />
                </div>
            </Segment>
            <Segment>
                <DataGrid
                    ref={gridRef}
                    dataSource={cells}
                    showBorders
                    columnHidingEnabled
                    wordWrapEnabled
                    columnAutoWidth
                    paging={{
                        enabled: false
                    }}
                    rowDragging={{
                        allowReordering: true,
                        showDragIcons: true,
                        onReorder,
                    }}
                    onRowUpdated={({ data }) => {
                        const temp = [...cells];
                        const rowIndex = temp.findIndex(c => c.cellId === data.cellId);
                        if (rowIndex !== -1) {
                            temp[rowIndex] = data;
                            setCells(temp);
                        }
                    }}
                >
                    <Editing
                        mode='form'
                        useIcons
                        allowUpdating
                    >
                        <Form customizeItem={customizeItem}>
                            <Item itemType="group" colCount={2} colSpan={2}>
                                <Item dataField="order" />
                                <Item isRequired dataField="cellType" />
                                <Item dataField="textEn" colSpan={2} />
                                <Item dataField="textAr" colSpan={2} />
                                <Item dataField="rowspan" />
                                <Item dataField="colspan" />
                                <Item dataField="correctAnswer" colSpan={2} />
                                <Item dataField="options" colSpan={2} />
                            </Item>
                        </Form>
                    </Editing>

                    <Column dataField="order" caption={t('Order')} />
                    <Column dataField="textEn" caption={t('EnglishName')} cellRender={({ value }) => <div className='english'>{stringShorten(htmlToText(value, { wordwrap: null }))}</div>} editCellComponent={TextEditor} />
                    <Column dataField="textAr" caption={t('ArabicDescription')} cellRender={({ value }) => <div className='arabic'>{stringShorten(htmlToText(value, { wordwrap: null }))}</div>} editCellRender={({ value, setValue }) => <TextEditor isArabic data={{ value, setValue }} />} />
                    <Column dataField="cellType" caption={t('Type')}
                        setCellValue={(newData, value) => {
                            newData.cellType = value;
                            if (value === "NORMAL_TEXT") {
                                newData.option = [];
                                newData.correctAnswer = null;
                            } else if (value === "NORMAL_QUESTION") {
                                newData.option = [];
                            } else if (value === "MCQ_QUESTION") {
                                newData.correctAnswer = null;
                            }
                        }}>
                        <Lookup
                            dataSource={["NORMAL_TEXT", "NORMAL_QUESTION", "MCQ_QUESTION"]}
                        />
                    </Column>
                    <Column dataField="rowspan" dataType="number" caption={t('RowSpan')} />
                    <Column dataField="colspan" dataType="number" caption={t('ColSpan')} />
                    <Column dataField="correctAnswer" caption={t('CorrectAnswer')} />
                    <Column visible={false} dataField="options" caption={t('MultiChoiceQuestionOptions')} editCellComponent={MultiChoiceQuestionOptions} />

                    <Column type="buttons" width={110}>
                        <DevButton name="edit" />
                        <DevButton hint={t('Clone')} icon="copy" visible={(e) => e.row.rowIndex < cells.length - 1} onClick={cloneCell} />
                    </Column>
                </DataGrid>
            </Segment>
        </Segment>
    );
};

const MultiChoiceQuestionOptions = ({ data: { value, setValue } }) => {
    const { t } = useTranslation();
    return (
        <DataGrid
            dataSource={value}
            showBorders
            columnHidingEnabled
            wordWrapEnabled
            columnAutoWidth
            onSaved={({ component }) => setValue(component.getDataSource().items())}
        >
            <Editing
                mode='batch'
                useIcons
                allowAdding
                allowUpdating
                allowDeleting
            >
                <Form>
                    <Item itemType="group" colCount={2} colSpan={2}>
                        <Item isRequired dataField="textEn" />
                        <Item isRequired dataField="textAr" />
                        <Item dataField="correctAnswer" />
                    </Item>
                </Form>
            </Editing>

            <Column dataField="textEn" caption={t('EnglishName')} />
            <Column dataField="textAr" caption={t('ArabicName')} />
            <Column
                caption={t('Correct')}
                dataType="boolean"
                dataField='correctAnswer'
            />
        </DataGrid>
    );
};
export default TableSection;