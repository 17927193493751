import React, { useState } from 'react';
import { Button, Modal, Segment, Icon } from 'semantic-ui-react';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import { Calculator, ErrorSegment, FlexWindow, ReportForm, VideoPlayer } from '../../..';
import { Consts } from '../../../../res';
import PresentValues from '../../../../res/pdfs/presentvalues.pdf';
// import QuestionTranslationModal from '../QuestionTranslationModal';

const ExamFooter = ({ onFlagQuestion, onFavQuestion, Q, Qindex, isMockExam, isViewOnly, summary, onSelectQuestion, questions, additionalStyles, t, onGoingExam }) => {
    const { FooterButtonStyle } = styles;
    const isArabic = i18next.language === 'ar';
    const [showExplanation, setShowExplanation] = useState(false);
    const [showReportIssue, setShowReportIssue] = useState(false);
    const [showCalculator, setShowCalculator] = useState(false);
    // const [showTranslationModal, setShowTranslationModal] = useState(false);

    if (!Q)
        return null;

    const onNextClick = () => {
        if (window.ExamNav)
            window.ExamNav.goTo(Qindex + 1);
        onSelectQuestion(questions[Qindex + 1]);
    };

    const onBackClick = () => {
        if (window.ExamNav)
            window.ExamNav.goTo(Qindex - 1);
        onSelectQuestion(questions[Qindex - 1]);
    };

    return (
        <Segment inverted basic style={{ backgroundColor: '#4c4c4c', ...additionalStyles, position: 'absolute', left: 0, bottom: 0, width: '100%' }}>
            <div style={{display:'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                <div >
                    {!summary.show && !isMockExam && (Q.answerText || Q.answerOptionId || isViewOnly) ? <Button icon='video play' labelPosition='left' content={t('Explanation')} onClick={() => setShowExplanation(true)} style={FooterButtonStyle} /> : null}
                    {!isMockExam && <Icon size='large' title={t('ReportProblem')} name='exclamation triangle' color='red' link onClick={() => setShowReportIssue(true)} />}
                </div>

                <div style={{textAlign: 'center'}}>
                    {/* <Button icon='translate' content={t('Translate')} style={FooterButtonStyle} onClick={() => setShowTranslationModal(true)}/> */}
                    <a title={t('PresentValueTable')} href={PresentValues} rel='noreferrer' target='_blank'>
                        <Button content={t('PresentValueTable')} style={FooterButtonStyle} />
                    </a>
                    <Button content={t('ReviewAnswers')} onClick={() => summary.setShowSummary(!summary.show)} style={FooterButtonStyle} />
                </div>

                {!summary.show && <div style={{textAlign: 'right'}}>
                    <Button icon='calculator' onClick={() => setShowCalculator(true)} style={FooterButtonStyle} />
                    {!Q?.showFactOnly && <Button icon={Q.isFlagged ? 'flag outline' : 'flag'} onClick={() => onFlagQuestion(Q)} style={FooterButtonStyle} />}
                    {!Q?.showFactOnly && <Button icon={Q.isFav ? 'star' : 'star outline'} onClick={() => onFavQuestion(Q)} style={FooterButtonStyle} />}
                    <Button.Group>
                        <Button disabled={Qindex <= 0} onClick={onBackClick} content={t('Back').toUpperCase()} style={{ ...FooterButtonStyle, width: '6rem', marginLeft: 5, marginRight: 5, clipPath: isArabic ? 'polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%)' : 'polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%)' }} />
                        <Button disabled={Qindex >= questions.length - 1} onClick={onNextClick} content={t('Next').toUpperCase()} style={{ ...FooterButtonStyle, width: '6rem', marginLeft: 5, marginRight: 5, clipPath: isArabic ? 'polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%)' : 'polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%)' }} />
                    </Button.Group>
                </div>}
            </div>

            <Modal
                onClose={() => setShowExplanation(false)}
                open={showExplanation}
                content={Q && Q.correctAnswer && Q.correctAnswer.explanations && Q.correctAnswer.explanations.videos.length ? <VideoPlayer kind={Consts.VIDEOS_KINDS.EXPLANATION} videoDetails={{ ...Q.correctAnswer.explanations.videos[0], ...Q }} onClose={() => setShowExplanation(false)} /> : <ErrorSegment text={t('ComingSoon')} icon='wait' />}
                basic={Q && Q.correctAnswer && Q.correctAnswer.explanations && Q.correctAnswer.explanations.videos.length ? true : false}
                size='large'
                centered
                closeIcon={!(Q && Q.correctAnswer && Q.correctAnswer.explanations && Q.correctAnswer.explanations.videos.length)}
                closeOnDimmerClick={false}
            />

            <Modal
                onClose={() => setShowReportIssue(false)}
                open={showReportIssue}
                content={<ReportForm reportOn={Consts.REPORTS_TYPES.QUESTION} reportLink={Q} id={Q.questionId} showModal={setShowReportIssue} />}
                closeIcon
                header={t('ReportProblem')}
                size='small'
                centered
                closeOnDimmerClick={false}
            />

            {showCalculator && <FlexWindow
                isVisible={showCalculator}
                onClose={() => setShowCalculator(false)}
                title={t("Calculator")}
            >
                <Calculator />
            </FlexWindow>}
            {/* 
            <QuestionTranslationModal show={showTranslationModal} setShow={setShowTranslationModal} 
                question={Q} lang={isArabic ? 'en' : 'ar'} examRoundId={onGoingExam.examRoundId} /> */}

        </Segment>
    );
};

const styles = {
    FooterButtonStyle: {
        backgroundColor: '#8CC63F',
        color: 'white'
    }
};

export default withTranslation()(ExamFooter);