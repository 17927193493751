import { useState, useEffect } from "react";

const useMultiDataStore = (DataStores) => {
    const [data, setData] = useState({});
    const [status, setStatus] = useState({
        error: '',
        loading: true,
    });


    useEffect(() => {
        const getData = async () => {
            try {
                const promises = DataStores.map(({ store, loadOptions, otherParams }) => otherParams ? store(otherParams).load(loadOptions) : store.load(loadOptions));
                const data = await Promise.all(promises);
                const res = {};
                for (let i = 0; i < data.length; i++) {
                    res[DataStores[i].name] = data[i].data;
                }
                setData(res);
            } catch (err) {
                setStatus({ ...status, error: err });
            } finally {
                setStatus({ ...status, loading: false });
            }
        };
        getData();
        // eslint-disable-next-line
    }, []);

    return [data, status];
};

export default useMultiDataStore;