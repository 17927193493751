import React, { useState } from 'react';
import { Card, Image, Grid, GridColumn, Label, LabelGroup } from 'semantic-ui-react';
import i18next from 'i18next';
import stringShorten from '../../helpers/stringShorten';
import { Theme } from '../../res';
import { SocialMediaList } from '..';
import avatarImage from '../../res/images/avatar.svg';
import { Styles } from '../../res';


const { f_20_700_text, f_12_400_text } = Styles;

const InstructorCard = ({ instructor, isWhiteBg }) => {
    const isArabic = i18next.language === 'ar';
    const [selected, setSelected] = useState(false);

    return (
        <Card
            style={{ boxShadow: 'none', borderRadius: 8, backgroundColor: selected ? Theme.colors.PRIMARY_50 : isWhiteBg ? Theme.colors.WHITE : Theme.colors.PRIMARY_2 }}
            onMouseEnter={() => setSelected(true)}
            onMouseLeave={() => setSelected(false)}
        >

            <Card.Content style={{ border: 'none' }}>
                <Grid columns={2} stackable verticalAlign='middle'>
                    <GridColumn width={4}>
                        <Image loading="lazy" src={instructor.imgUrl || avatarImage} rounded fluid />
                    </GridColumn>

                    <GridColumn width={12}>
                        <Card.Header style={{ ...f_20_700_text, color: selected ? Theme.colors.WHITE : Theme.colors.BLACK }}>{isArabic ? instructor.fullNameAr : instructor.fullNameEn}</Card.Header>
                        <LabelGroup>
                            {
                                instructor.specialty.split(",").map((sp, indx) => <Label key={indx} style={{ backgroundColor: Theme.colors.PRIMARY_05, color: Theme.colors.PRIMARY_50 }}>{sp}</Label>)
                            }
                        </LabelGroup>
                    </GridColumn>
                </Grid>
            </Card.Content>

            <Card.Content style={{ border: 'none', paddingTop: 0 }}>
                <Card.Description style={{ ...f_12_400_text, color: selected ? Theme.colors.WHITE : Theme.colors.GREY_70 }}>
                    {selected ? instructor.bio : stringShorten(instructor.bio, 100)}
                </Card.Description>
            </Card.Content>

            <Card.Content textAlign='right' extra>
                <SocialMediaList data={instructor.socialUrls} />
            </Card.Content>
        </Card>
    );
};

export default InstructorCard;