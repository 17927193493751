import React from 'react';
import Avatar from 'react-avatar';
import { Feed, Header } from 'semantic-ui-react';
import { Styles } from '../../res';

import avatarImage from '../../res/images/avatar.svg';

const { f_20_700_text, f_14_400_text } = Styles;

const SuccessStoryCard = ({ name, quote, img }) => {
    return (
        // <Segment basic>
        <Feed size='large'>
            <Feed.Event>
                <Feed.Content>
                    <Feed.Summary style={{ textAlign: 'center', marginTop: 10 }}>
                        <Avatar src={img || avatarImage} size={100} round />
                        <Header as='h4' style={{ ...f_20_700_text, marginTop: 10 }}>{name}</Header>
                    </Feed.Summary>
                    <Feed.Extra text style={{ textAlign: 'center', minWidth: '40vw' }}>
                        {/* <div style={blockquoteUp}>“</div> */}
                        <p style={f_14_400_text}>{quote}</p>
                        {/* <div style={blockquoteDown}>”</div> */}
                    </Feed.Extra>
                </Feed.Content>
            </Feed.Event>
        </Feed>
        // </Segment>
    );
};

export default SuccessStoryCard;