import {
    GET_ALL_STUFF_OPERATION_START,
    GET_ALL_STUFF_OPERATION_FAIL,
    GET_ALL_STUFF_OPERATION_FINISH,
    GET_ALL_STUFF_SUCCESS
} from '../../actions/types';

const INITIAL_STATE = {
    error: '',
    loading: false,
    stuff: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_STUFF_OPERATION_START:
            return { ...state, loading: true };
        case GET_ALL_STUFF_OPERATION_FINISH:
            return { ...state, loading: false };
        case GET_ALL_STUFF_OPERATION_FAIL:
            return { ...state, error: { [action.payload.props]: action.payload.value } };
        case GET_ALL_STUFF_SUCCESS:
            return { ...state, stuff: action.payload };
        default:
            return state;
    }
};
