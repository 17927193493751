import React, { useState } from 'react';
import { Button, Divider, Header, Segment, Image, Grid, GridColumn, TextArea, Form } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { HtmlEditor, TableContextMenu, TableResizing } from 'devextreme-react/html-editor';
import { TextEditor } from '../../..';
import { Styles } from '../../../../res';

const { noExtraSpaces } = Styles;

const TextQuestionTemplate = ({ Q, onAnswerQuestion, isMockExam, isViewOnly, t }) => {
    const [answer, setAnswer] = useState(Q.answerText);
    const showAnswers = (!isMockExam && Q.answerText) || isViewOnly;

    const renderCorrectAnswer = () => {
        if (showAnswers) return (
            <>
                <Divider />
                <Header as='h4' color='green'>{t('Answer')}</Header>
                <HtmlEditor value={Q.correctAnswer.correctAnswerText.trim()} readOnly >
                    <TableContextMenu enabled />
                    <TableResizing enabled />
                </HtmlEditor>
                <Divider />
                {
                    Q.correctAnswer.explanations.imgs.map(i => <Image loading="lazy" key={i.url} src={i.url} size='large' rounded />)
                }
            </>
        );
    };

    const renderComments = () => {
        if (showAnswers) return (
            <Grid stackable columns='2' stretched>
                {!!Q.correctAnswer.worthDegree && <GridColumn width='4'>
                    <Segment textAlign='center'>
                        <Header as='h3' color='red' content={t('Degree')} />
                        <Header as='h4' content={Q.correctAnswer.worthDegree} />
                    </Segment>
                </GridColumn>}
                {Q.correctAnswer.correctorCommentTxt && <GridColumn width='12'>
                    <Segment>
                        <Header as='h3' color='red' content={t('CorrectorComment')} />
                        <Form>
                            <Form.Field
                                control={TextArea}
                                value={Q.correctAnswer.correctorCommentTxt}
                            />
                        </Form>
                    </Segment>
                </GridColumn>}
            </Grid>
        );
    };

    return (
        <Segment basic style={{ minHeight: '40vh', ...noExtraSpaces }}>
            <TextEditor isStudent data={{ value: answer, setValue: setAnswer }} onBlur={(isMockExam && !showAnswers) ? () => onAnswerQuestion({ ...Q, answerText: answer }) : undefined} />
            {!showAnswers && <Divider />}
            {!showAnswers && <Button positive disabled={answer === Q.answerText || showAnswers} icon='save' labelPosition='left' content={t('Save')} fluid onClick={() => onAnswerQuestion({ ...Q, answerText: answer })} />}
            {renderCorrectAnswer()}
            {renderComments()}
        </Segment>
    );
};

export default withTranslation()(TextQuestionTemplate);