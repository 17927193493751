import i18n from 'i18next';
import {
    GET_ALL_BLOGS_OPERATION_START,
    GET_ALL_BLOGS_OPERATION_FAIL,
    GET_ALL_BLOGS_OPERATION_FINISH,
    GET_ALL_BLOGS_SUCCESS
} from '../types';
import request from '../../api';

export const getAllBlogs = () => async dispatch => {
    dispatch({ type: GET_ALL_BLOGS_OPERATION_START });
    try {
        const { data: { data } } = await request.get('/public/blogs?take=2');
        dispatch({ type: GET_ALL_BLOGS_SUCCESS, payload: data });
    } catch (err) {
        if (err.response) dispatch({ type: GET_ALL_BLOGS_OPERATION_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: GET_ALL_BLOGS_OPERATION_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    } finally {
        dispatch({ type: GET_ALL_BLOGS_OPERATION_FINISH });
    }
};
