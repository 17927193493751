import React, { useState } from 'react';
import { Button, Divider, Header, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import TextEditor from '../../Generals/TextEditor';

const TextSection = ({ data, saveSection, deleteSection, cloneSection, moveSectionDown, moveSectionUp, sectionIndex }) => {
    const { t } = useTranslation();
    const [textEn, setTextEn] = useState(data?.textEn || "");
    const [textAr, setTextAr] = useState(data?.textAr || "");

    return (
        <Segment basic>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <Header icon="font" content={`${t("Section")} #${data.order}`} />
                <div>
                    <Button icon='save' positive onClick={() => saveSection(data.sectionId, { ...data, textAr, textEn })} />
                    <Button icon='copy' positive onClick={() => cloneSection({ ...data, textAr, textEn })} />
                    <Button icon='trash' negative onClick={() => deleteSection(data.sectionId)} />
                    <Button title={t("Up")} size='small' icon='arrow up' color='blue' onClick={() => moveSectionUp(sectionIndex)} />
                    <Button title={t("Down")} size='small' icon='arrow down' color='blue' onClick={() => moveSectionDown(sectionIndex)} />
                </div>
            </div>
            <Divider />
            <Segment>
                <Header>{t("EnglishText")}</Header>
                <TextEditor data={{ value: textEn, setValue: setTextEn }} />
                <Header>{t("ArabicText")}</Header>
                <TextEditor isArabic data={{ value: textAr, setValue: setTextAr }} />
            </Segment>
        </Segment>
    );
};

export default TextSection;