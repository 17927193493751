import { Modal, Image, Header, Button, Transition, Segment } from 'semantic-ui-react';
import { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import request from '../../api';
import { Styles } from '../../res';
import placeholderImage from '../../res/images/course.jpg';
import { useLocation } from 'react-router-dom';
import '../../res/css/popup-styles.css'
import { Media } from '../../Media';

const { solidBtn } = Styles;

function PopupAd({isLanding}) {
    const [showModal, setShowModal] = useState(false);
    const [popupData, setPopupData] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    
    useEffect(() => {
        const noAdParamValue = queryParams.get('noAd');
    
        if (noAdParamValue !== 1) {
            request.get('/public/popup-news').then(res => {
                if (res.data)
                    setShowModal(true);
                setPopupData(res.data);
            });
        }
        //eslint-disable-next-line
    }, []);

    if (isLanding)
        return (
         <Media greaterThan='tablet'>
            <Transition visible={showModal} animation='fade' duration={500}>
                <Modal 
                    className={`popModal ${isLanding ? 'popup-landing' : ''}`}
                    open={showModal}
                    dimmer='blurring'
                    closeIcon={true}
                    closeOnDimmerClick={false}
                    centered
                    onClose={() => setShowModal(false)}>
                    {showModal && (
                        <Modal.Content style={{padding: '0'}} >
                            <Segment basic className='pop-style'>
                                <Segment basic>
                                    <Image loading="lazy" src={popupData.imgUrl || placeholderImage} />
                                </Segment>
                                <Segment basic style={{textAlign: 'center', width: '100%', height: '100%'}}>
                                    <Header>{popupData.title}</Header>
                                    <Modal.Description>
                                        {popupData.content}
                                    </Modal.Description>
                                    <Button 
                                        as='a' 
                                        target="_blank" 
                                        href={popupData.extUrl + '?noAd=1'} 
                                        style={{...solidBtn, marginTop: '10px' }}
                                        content={popupData.button_text} 
                                        onClick={() => setShowModal(false)}
                                    />
                                </Segment>
                            </Segment>
                        </Modal.Content>
                    )}
                </Modal>
            </Transition>
        </Media>
    );

    return (
        <Transition visible={showModal} animation='fade' duration={500}>
                <Modal 
                    className={`popModal ${isLanding ? 'popup-landing' : ''}`}
                    open={showModal}
                    dimmer='blurring'
                    closeIcon={true}
                    closeOnDimmerClick={false}
                    centered
                    onClose={() => setShowModal(false)}>
                    {showModal && (
                        <Modal.Content style={{padding: '0'}} >
                            <Segment basic className='pop-style'>
                                <Segment basic>
                                    <Image loading="lazy" src={popupData.imgUrl || placeholderImage} />
                                </Segment>
                                <Segment basic style={{textAlign: 'center', width: '100%', height: '100%'}}>
                                    <Header>{popupData.title}</Header>
                                    <Modal.Description>
                                        {popupData.content}
                                    </Modal.Description>
                                    <Button 
                                        as='a' 
                                        target="_blank" 
                                        href={popupData.extUrl + '?noAd=1'} 
                                        style={{...solidBtn, marginTop: '10px' }}
                                        content={popupData.button_text} 
                                        onClick={() => setShowModal(false)}
                                    />
                                </Segment>
                            </Segment>
                        </Modal.Content>
                    )}
                </Modal>
            </Transition>
    );
}

export default withTranslation()(PopupAd);