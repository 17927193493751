import i18n from 'i18next';
import {
    USER_GET_ALL_SUBSCRIPTIONS_START,
    USER_GET_ALL_SUBSCRIPTIONS_FAIL,
    USER_GET_ALL_SUBSCRIPTIONS_FINISH,
    USER_GET_ALL_SUBSCRIPTIONS
} from '../types';
import request from '../../api';

export const userGetAllSubscriptions = () => async dispatch => {
    dispatch({ type: USER_GET_ALL_SUBSCRIPTIONS_START });
    try {
        const { data: { data } } = await request.get('/student/subscriptions');
        dispatch({ type: USER_GET_ALL_SUBSCRIPTIONS, payload: data });
    } catch (err) {
        if (err.response) dispatch({ type: USER_GET_ALL_SUBSCRIPTIONS_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: USER_GET_ALL_SUBSCRIPTIONS_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    } finally {
        dispatch({ type: USER_GET_ALL_SUBSCRIPTIONS_FINISH });
    }
};
