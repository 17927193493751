import React from 'react'
import { Segment } from 'semantic-ui-react';
import { HtmlEditor, TableContextMenu, TableResizing } from 'devextreme-react/html-editor';

const DescriptionCard = ({ text }) => {
    if (!text) return null;
    return (
        <Segment basic>
            <HtmlEditor 
                value={text} 
                readOnly 
                style={{ border: 'none', padding: 0, margin: 0 }} 
            >
                <TableContextMenu enabled={true} />
                <TableResizing enabled={true} />
            </HtmlEditor>
        </Segment>
    );
};

export default DescriptionCard;