import i18n from 'i18next';
import {
    COUNTRIES_OPERATION_FAIL,
    COUNTRIES_OPERATION_FINISH,
    COUNTRIES_OPERATION_START,
    COUNTRIES_SUCCESS
} from '../types';
import request from '../../api';

export const getAllCountries = () => async dispatch => {
    dispatch({ type: COUNTRIES_OPERATION_START });
    try {
        const { data } = await request.get('/countries/all');
        dispatch({ type: COUNTRIES_SUCCESS, payload: data.countries });
    } catch (err) {
        if (err.response) dispatch({ type: COUNTRIES_OPERATION_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: COUNTRIES_OPERATION_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    } finally {
        dispatch({ type: COUNTRIES_OPERATION_FINISH });
    }
};
