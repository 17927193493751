import React, { createRef, useEffect } from 'react';
import Carousel from 'react-elastic-carousel';
import { useTranslation } from 'react-i18next';
import { Button, Segment } from 'semantic-ui-react';
import { Consts, Styles } from '../../../../res';

const { carouselExamItemButton } = Styles;

const QuestionsNav = ({ questions, isViewOnly, onSelectQuestion, Q, Qindex, showNavList }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    useEffect(() => {
        onSelectQuestion(isViewOnly ? questions[0] : questions.find(q => !q.answerText && !q.answerOptionId) || questions[questions.length - 1]);
        //eslint-disable-next-line
    }, []);


    if (!Q) return null;

    const carouselRef = createRef();

    const selectColor = (q) => {
        // if (q.questionId === Q.questionId) return '#8CC63F';
        if (q.questionTypeId === Consts.QUESTIONS_TYPES.MCQS ? q.answerOptionId : q.answerText) return '#4c4c4c';
        return '#8CC63F';
    };

    if (!showNavList) return null;
    return (
        <Segment basic style={{ minWidth: 110 }}>
            <Button
                icon='caret up'
                style={{ ...carouselExamItemButton, backgroundColor: '#8CC63F' }}
                disabled={Qindex === 0}
                onClick={() => {
                    carouselRef.current.slidePrev();
                    onSelectQuestion(questions[Qindex - 1]);
                }} />
            <Carousel
                ref={carouselRef}
                verticalMode
                focusOnSelect
                isRTL={isArabic ? true : false}
                itemPadding={[10, 20]}
                transitionMs={2000}
                showArrows={false}
                itemsToShow={6}
                itemsToScroll={1}
                pagination={false}
                onResize={() => window.ExamNav = carouselRef.current}
            // breakPoints={[
            //     { width: 1, itemsToShow: 2, itemsToScroll: 2, pagination: false },
            //     { width: 550, itemsToShow: 5, itemsToScroll: 5, pagination: false },
            //     { width: 850, itemsToShow: 10, itemsToScroll: 10, pagination: false },
            //     { width: 1150, itemsToShow: 15, itemsToScroll: 15, pagination: false },
            //     { width: 1450, itemsToShow: 15, itemsToScroll: 15, pagination: false },
            //     { width: 1750, itemsToShow: 15, itemsToScroll: 15, pagination: false }
            // ]}
            // renderArrow={({ type, onClick, isEdge }) => <Icon style={{ alignSelf: 'center', cursor: 'pointer' }} size='big' onClick={onClick} disabled={isEdge} name={type === consts.PREV ? 'chevron left' : 'chevron right'} color='green' />}>
            >
                {questions.map((q, index) => <Button
                    key={index}
                    content={q?.qFactIndex !== undefined ? `${t("E")}.${q.qFactIndex + 1}` : q?.qAterFactIndex !== undefined ? q.qAterFactIndex : index + 1}
                    icon={q.isFlagged ? 'flag' : null}
                    style={{ ...carouselExamItemButton, backgroundColor: selectColor(q), clipPath: Qindex === index ? isArabic ? 'polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%)' : 'polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%)' : null }}
                    onClick={() => onSelectQuestion(q)}
                />)}
            </Carousel>
            <Button
                icon='caret down'
                style={{ ...carouselExamItemButton, backgroundColor: '#8CC63F' }}
                disabled={Qindex === questions.length - 1}
                onClick={() => {
                    carouselRef.current.slideNext();
                    onSelectQuestion(questions[Qindex + 1]);
                }} />
        </Segment>
    );
};


export default QuestionsNav;