import React, { useEffect, useState } from 'react';
import { Card, Segment, Statistic } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import request from '../../api';
import { ErrorSegment } from '..';
import { Styles } from '../../res';
import { Media } from '../../Media';

const { f_18_700_text, f_12_400_text } = Styles;

const StatsList = ({ t }) => {
    const [stats, setStats] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const getStats = async () => {
            setLoading(true);
            try {
                const s1 = request.get('/student/stats/courses/count');
                const s2 = request.get('/student/stats/students/count');
                const s3 = request.get('/student/stats/certificates/count');
                const s4 = request.get('/student/stats/success-stories/count');
                const [
                    { data: { data: courses } },
                    { data: { data: students } },
                    { data: { data: certsCount } },
                    { data: { data: successStories } },
                ] = await Promise.all([s1, s2, s3, s4]);
                setStats([
                    { color: 'green', icon: 'certificate', name: t('Certifications'), count: certsCount },
                    { color: 'red', icon: 'users', name: t('Courses'), count: courses },
                    { color: 'blue', icon: 'student', name: t('Students'), count: students },
                    { color: 'orange', icon: 'universal access', name: t('SuccessStories'), count: successStories }
                ]);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        getStats();

        return () => {
            setStats([]);
            setLoading(false);
        };
        // eslint-disable-next-line
    }, []);

    if (loading) return <Segment basic padded loading={loading} />;
    if (error) return <ErrorSegment />;

    return (
        <>
            <Media greaterThanOrEqual='tablet'>
                <Segment basic style={{ padding: 0 }}>
                    <Card.Group stackable itemsPerRow='4'>
                        {
                            stats.map((s, indx) =>
                                <Card key={indx} style={{ boxShadow: 'none', backgroundColor: 'transparent' }} >
                                    <Card.Content style={{ padding: 0 }}>
                                        <Statistic size='small'>
                                            <Statistic.Value style={f_18_700_text}>
                                                {s.count}
                                            </Statistic.Value>
                                            <Statistic.Label style={{ ...f_12_400_text, textAlign: 'left' }}>{s.name}</Statistic.Label>
                                        </Statistic>
                                    </Card.Content>
                                </Card>)
                        }
                    </Card.Group>
                </Segment>
            </Media>

            <Media lessThan='tablet'>
                <Segment textAlign='center' basic style={{ padding: 0 }}>
                    <Card.Group itemsPerRow='2'>
                        {
                            stats.map((s, indx) =>
                                <Card key={indx} style={{ boxShadow: 'none', backgroundColor: 'transparent' }} >
                                    <Card.Content style={{ padding: 0 }}>
                                        <Statistic size='small'>
                                            <Statistic.Value style={f_18_700_text}>
                                                {s.count || 200}
                                            </Statistic.Value>
                                            <Statistic.Label style={{ ...f_12_400_text, textAlign: 'left' }}>{s.name}</Statistic.Label>
                                        </Statistic>
                                    </Card.Content>
                                </Card>)
                        }
                    </Card.Group>
                </Segment>
            </Media>
        </>
    );
};

export default withTranslation()(StatsList);