import i18n from 'i18next';
import { toast } from 'react-toastify';
import {
    RESET_PASSWORD_OPERATION_FAIL,
    RESET_PASSWORD_OPERATION_FINISH,
    RESET_PASSWORD_OPERATION_START,
    RESET_PASSWORD_SUCCESS,
} from '../types';
import { badReq, noConnection } from '../../helpers/errors';
import request from '../../api';
import history from '../../history';
import PATHS from '../../Routers/PATHS';

export const resetPassword = (resetCode, newPassword) => async dispatch => {
    dispatch({ type: RESET_PASSWORD_OPERATION_START });
    try {
        await request.post('/auth/account/resetpassword', { resetCode, newPassword });
        dispatch({ type: RESET_PASSWORD_SUCCESS });
        history.push(PATHS.LANDING_SIGNIN.URL);
    } catch (err) {
        if (err.response.status === 400) {
            return toast.error(i18n.t('InvalidResetCode'), { position: toast.POSITION.BOTTOM_CENTER });
        }
        dispatch({ type: RESET_PASSWORD_OPERATION_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        if (err.response) return badReq();
        else noConnection();
    } finally {
        dispatch({ type: RESET_PASSWORD_OPERATION_FINISH });
    }
};