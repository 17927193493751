import React, { useState } from 'react';
import { Button, Checkbox, Divider, Header, Icon, Popup, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { Styles, Theme } from '../../../../../res';

const { f_14_400_text } = Styles;

const TaskCell = ({ data, onAnswerTask, userTaskAnswer, isViewOnly, isSubmited }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";
    const [selectedOption, setSelectedOption] = useState(userTaskAnswer?.selectedOption || null);

    return (
        <Segment basic style={{ backgroundColor: Theme.colors.CPA_NAVY, padding: 5 }}>
            <Header style={{ ...f_14_400_text, color: Theme.colors.WHITE, margin: 5 }}>{t('SelectOptionBelow')}</Header>
            <div style={{ backgroundColor: Theme.colors.WHITE, padding: 5 }}>
                {
                    data.options.map((op, i) => <div
                        key={i}
                        style={{ display: 'flex', alignItems: 'center', gap: 10, marginBottom: 10 }}
                        onClick={() => {
                            if (!isViewOnly || !isSubmited) {
                                setSelectedOption(op.__KEY__);
                            }
                        }}
                    >
                        <Checkbox
                            radio
                            disabled={isViewOnly}
                            checked={selectedOption === op.__KEY__}
                        />

                        <div>
                            <Header style={f_14_400_text}>{op.type === "REPLACE" ? (isArabic ? op.nameAr : op.nameEn) : t(op.type)}</Header>
                        </div>
                    </div>)
                }
            </div>
            <Divider style={{ margin: 0 }} />
            {(!isViewOnly || !isSubmited) && <div style={{ backgroundColor: Theme.colors.WHITE, display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', padding: 5 }}>
                <Button onClick={() => setSelectedOption(null)} size='mini' style={{ borderRadius: 0, backgroundColor: Theme.colors.CPA_NAVY, color: Theme.colors.WHITE }}>{t('Reset').toUpperCase()}</Button>
                <div style={{ marginLeft: isArabic ? undefined : '5vw', marginRight: isArabic ? '5vw' : undefined }}>
                    <Button onClick={() => document.getElementById('clicktohide').click()} size='mini' style={{ textDecoration: 'underline', borderRadius: 0, backgroundColor: Theme.colors.WHITE, color: Theme.colors.PRIMARY_50 }}>{t('Cancel').toUpperCase()}</Button>
                    <Button
                        onClick={() => {
                            if (!isViewOnly || !isSubmited) {
                                onAnswerTask({ taskId: data.taskId, selectedOption });
                                document.getElementById('clicktohide').click();
                            }
                        }}
                        size='mini'
                        style={{ borderRadius: 0, backgroundColor: Theme.colors.CPA_ORANGE, color: Theme.colors.WHITE }}>
                        {t('Accept').toUpperCase()}
                    </Button>
                </div>
            </div>}
        </Segment>
    );
};

const ParagraphQuestionTemplate = ({ sectionId, textEn, textAr, tasks, userAnswer, onUserAnswerSection, isViewOnly, isSubmited }) => {
    const { i18n } = useTranslation();
    const isArabic = i18n.language === "ar";
    const [tasksAnswers, setTasksAnswers] = useState(userAnswer?.tasksAnswers || []);

    const onAnswerTask = (taskAnswer) => {
        const taskIndex = tasksAnswers.findIndex(s => s.taskId === taskAnswer.taskId);
        if (taskIndex !== -1) {
            const temp = [...tasksAnswers];
            temp.splice(taskIndex, 1, taskAnswer);
            setTasksAnswers(temp);
            onUserAnswerSection({ sectionId, tasksAnswers: temp });
        } else {
            setTasksAnswers([...tasksAnswers, taskAnswer]);
            onUserAnswerSection({ sectionId, tasksAnswers: [...tasksAnswers, taskAnswer] });
        }
    };

    return (
        <Segment id="clicktohide">
            {parse((isArabic ? textAr : textEn).replaceAll(/@@{(.*?)}/g, (text) => `<span type='custom' text='${text}'></span>`), {
                replace: domNode => {
                    if (domNode.attribs && domNode.attribs?.type === 'custom') {
                        const data = tasks.find(t => t.text === domNode.attribs?.text);
                        const userAnswer = tasksAnswers.find(t => t.taskId === data?.taskId);
                        let userAnswerText;
                        let isDeleteOption;
                        if (userAnswer) {
                            const selectedOption = data.options.find(op => op.__KEY__ === userAnswer?.selectedOption);
                            if (selectedOption) {
                                userAnswerText = isArabic ? selectedOption?.nameAr : selectedOption?.nameEn;
                                isDeleteOption = selectedOption.type === 'DELETE';
                            }

                        }
                        return <>
                            <span style={isDeleteOption
                                ? { textDecoration: 'underline', textDecorationSkipInk: 'none', marginInline: 5, textUnderlineOffset: '-35%' }
                                : (userAnswer?.selectedOption ? { textDecoration: 'underline', marginInline: 5, fontWeight: 'bold' } : { textDecoration: 'underline dashed', marginInline: 5 })}
                            >
                                {userAnswerText ? userAnswerText : data?.text.slice(3, data?.text.length - 1)}
                            </span>
                            <Popup
                                on='click'
                                flowing
                                position={isArabic ? "left center" : 'right center'}
                                trigger={
                                    <Icon size='large' name={userAnswer?.selectedOption ? "check square" : "browser"} style={{ color: "#043a4e", cursor: 'pointer' }} />
                                }
                            >
                                <TaskCell data={data} isViewOnly={isViewOnly} isSubmited={isSubmited} onAnswerTask={onAnswerTask} userTaskAnswer={userAnswer} />
                            </Popup>
                        </>;
                    }
                }
            })}
        </Segment>
    );
};


export default ParagraphQuestionTemplate;