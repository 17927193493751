import React, { useEffect } from 'react';
import { FileUploader } from 'devextreme-react/file-uploader';
import { Grid, GridColumn, Icon } from 'semantic-ui-react';
import { BASE_URL } from '../../api';

const BookUploader = ({ data, Url }) => {
    const uploadUrl = Url ? `${BASE_URL}${Url}` : `${BASE_URL}/book/upload`;
    const allowedFileExtensions = ['.pdf', '.docx', '.doc', '.pptx', '.ppt', '.xlsx', '.xls'];


    useEffect(() => {
        let open = XMLHttpRequest.prototype.open;
        XMLHttpRequest.prototype.open = function () {
            open.apply(this, arguments);
            this.withCredentials = true;
        };
    }, []);

    const onUploaded = (e) => {
        data.setValue(JSON.parse(e.request.response).bookUrl);
    };

    return (
        <div>
            <Grid stackable columns={2} verticalAlign='middle'>
                <GridColumn width='13'>
                    <FileUploader
                        id="file-uploader"
                        multiple={false}
                        accept="application/pdf,.pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.docx,application/msword,.doc,application/vnd.openxmlformats-officedocument.presentationml.presentation,.pptx,application/vnd.ms-powerpoint,.ppt,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xlsx,application/vnd.ms-excel,.xls"
                        allowedFileExtensions={allowedFileExtensions}
                        uploadMode="instantly"
                        showFileList
                        uploadUrl={uploadUrl}
                        visible
                        allowCanceling
                        name='book'
                        activeStateEnabled
                        onUploaded={onUploaded}
                    // onUploadStarted={() => setLoading(true)}
                    // onUploadAborted={() => setLoading(false)}
                    // onUploadError={() => setLoading(false)}
                    />
                </GridColumn>

                <GridColumn width='3'>
                    {
                        <a title={data.value} href={data.value} target='_blank' rel='noreferrer'><Icon size='big' name='file alternate' link /></a>
                    }
                </GridColumn>
            </Grid>
        </div>
    );
};



export default BookUploader;