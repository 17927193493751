import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Segment, Card } from 'semantic-ui-react';
import i18next from 'i18next';
import { ErrorSegment, InstructorCard } from '..';
import request from '../../api';

const InstructorsList = ({ id, isCert }) => {
    const [instructors, setInstructors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const getInstructorsList = async () => {
            setLoading(true);
            try {
                const { data: { data } } = await request(`/public/instructors?${isCert ? 'certificateId' : 'courseId'}=${id}`);
                setInstructors(data || []);
            } catch (error) {
                setError(error);
            }
            finally {
                setLoading(false);
            }
        };
        getInstructorsList();

    }, [id, setInstructors, setLoading, setError, isCert]);

    if (error) return <ErrorSegment />;
    if (!instructors.length) return <Segment basic textAlign='center' loading={loading}>
        <ErrorSegment icon='wait' text={i18next.t('ComingSoon')} />
    </Segment>;

    return (
        <Segment basic loading={loading}>
            <Card.Group stackable itemsPerRow='1'>
                {
                    _.orderBy(instructors, ['instructorOrder']).map(i => <InstructorCard key={i.id} instructor={i} />)
                }
            </Card.Group>
        </Segment >
    );
};

export default InstructorsList;
