import React from 'react';
import styled from 'styled-components';
import i18next from 'i18next';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5px;
  &: first-child {
    margin-left: 0;
  }
`;

const Title = styled.span`
  font-size: 12px;
  margin-bottom: 5px;
`;

const DigitContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;
`;

const SingleDigit = styled.span`
  position: relative;
  display: flex;
  flex: 0 1 25%;
  font-size: 15px;
  background-color: black;
  border-radius: 5px;
  padding: 5px 6px;
  color: white;
  &:first-child {
    margin-left: 2px;
    margin-right:2px;
  }
  // &:after {
  //   position: absolute;
  //   left: 0px;
  //   right: 0px;
  //   top: 50%;
  //   bottom: 50%;
  //   content: "";
  //   width: '100%';
  //   height: 2px;
  //   background-color: #232323;
  //   opacity: 0.4;
  // }
`;

export default ({ value, title, isLastHour }) => {
  const isArabic = i18next.language === 'ar';
  let leftDigit = value >= 10 ? value.toString()[0] : '0';
  let rightDigit = value >= 10 ? value.toString()[1] : value.toString();

  return (
    <Container>
      <Title>{title}</Title>
      <DigitContainer>
        <SingleDigit style={{ backgroundColor: isLastHour ? 'red' : 'black' }}>
          {isArabic ? rightDigit : leftDigit}
        </SingleDigit>
        <SingleDigit style={{ backgroundColor: isLastHour ? 'red' : 'black' }}>
          {isArabic ? leftDigit : rightDigit}
        </SingleDigit>
      </DigitContainer>
    </Container>
  );
}