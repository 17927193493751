import LandingLayout from './LandingLayout';
import AdminLayout from './AdminLayout';
import StudentLayout from './StudentLayout';
import { Spinner } from '../components';

const Layouts = (props) => {
    const { user } = props;

    const renderLayout = () => {
        if (user && user.isAdmin) return <AdminLayout {...props} />;
        else if (user && !user.isAdmin) return <StudentLayout {...props} />;
        else return <LandingLayout {...props} />;
    };

    if (user === null) return <Spinner />;
    return renderLayout();
};

export default Layouts;