import React from 'react';
import { Consts } from '../../../../res';
import HeaderCma from './HeaderCma';
import HeaderSocpa from './HeaderSocpa';
import HeaderCia from './HeaderCia';
import HeaderCpa from './HeaderCpa';
import HeaderDifr from './HeaderDifr';

const Header = (props) => {
    const renderHeader = () => {
        switch (props.theme) {
            case Consts.EXAM_THEMES.SOCPA:
                return <HeaderSocpa {...props} />;
            case Consts.EXAM_THEMES.CMA:
                return <HeaderCma {...props} />;
            case Consts.EXAM_THEMES.CIA:
                return <HeaderCia {...props} />;
            case Consts.EXAM_THEMES.CPA:
                return <HeaderCpa {...props} />;
            case Consts.EXAM_THEMES.DIFR:
                return <HeaderDifr {...props} />;
            default:
                return <HeaderSocpa {...props} />;
        }
    };

    return (
        <div>
            {renderHeader()}
        </div>
    );
};

export default Header;