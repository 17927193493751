import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Image, Label, Segment } from 'semantic-ui-react';
import { TextBlockViewer } from './Blocks/TextBlock';
import { VideoBlockViewer } from './Blocks/VideoBlock';
import { GalleryBlockViewer } from './Blocks/GalleryBlock';
import useJsonSectionParser from '../QuestionBuilder/useJsonSectionParser';
import { Consts, Styles, Theme } from '../../res';
import Separator from '../Operantinals/Separator';

const { f_24_700_text, f_12_400_text } = Styles;

const BlockViewer = ({ value, meta }) => {
    const { i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const data = useJsonSectionParser(value, { blocks: [], settings: {} });

    const renderBlock = (block, key) => {
        switch (block.type) {
            case Consts.BLOCK_EDITOR_BLOCKS_TYPES.TEXT_BLOCK: return <TextBlockViewer key={key} data={block} />;
            case Consts.BLOCK_EDITOR_BLOCKS_TYPES.VIDEO_BLOCK: return <div key={key} className='tw-md:px-4'><VideoBlockViewer data={block} /> </div>;
            case Consts.BLOCK_EDITOR_BLOCKS_TYPES.GALLERY_BLOCK: return <div key={key} className='tw-px-4'><GalleryBlockViewer data={block} /></div>;
            default: return null;
        }
    };

    return (
        <Segment basic>
            <Segment basic style={{ marginBottom: 0,paddingInline:30 }}>
                <Header style={{ ...f_24_700_text, color: Theme.colors.PRIMARY_50, margin: 0, marginBottom: 5 }}>{isArabic ? (meta.titleAr || meta.title) : (meta.titleEn || meta.title)}</Header>
                <Header style={{ ...f_12_400_text, margin: 0, marginBottom: 5 }}>{new Date(meta.creatAt).toLocaleDateString()}</Header>
                <div style={{ marginBottom: 5 }}>
                    {
                        meta?.categories?.map((c, i) => <Label size='tiny' key={i} color='green' content={isArabic ? (c.nameAr || c.name) : (c.nameEn || c.name)} />)
                    }
                </div>
                <div>
                    {
                        meta?.tags?.map((t, i) => <Label size='tiny' key={i} content={isArabic ? (t.textAr || t.text) : (t.textEn || t.text)} />)
                    }
                </div>
                {data?.settings?.showMainImage && meta?.mainImgUrl && <Image loading="lazy" src={meta.mainImgUrl} rounded fluid style={{ marginTop: 15, aspectRatio: '16/9' }} />}
            </Segment>

            <Segment basic style={{ display: 'flex', flexDirection: 'column', gap: 15, marginTop: 0, paddingTop: 0 }}>
                {
                    data.blocks.map((block, i) => renderBlock(block, i))
                }
            </Segment>
            <Separator />
        </Segment>
    );
};

export default BlockViewer;