import {
    PROFILE_INFO_CHANGED,
    PROFILE_OPERATION_START,
    PROFILE_OPERATION_FAIL,
    PROFILE_OPERATION_FINISH,
    PROFILE_SUCCESS
} from '../../actions/types';

const INITIAL_STATE = {
    error: '',
    loading: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PROFILE_INFO_CHANGED:
            return { ...state, [action.payload.props]: action.payload.value };
        case PROFILE_OPERATION_START:
            return { ...state, loading: true };
        case PROFILE_OPERATION_FINISH:
            return { ...state, loading: false };
        case PROFILE_OPERATION_FAIL:
            return { ...state, error: { [action.payload.props]: action.payload.value } };
        case PROFILE_SUCCESS:
            return { ...INITIAL_STATE };
        default:
            return state;
    }
};
