import React, { useState } from 'react';
import { Segment, Grid, GridColumn, Container, Header, Image, Modal, Icon } from 'semantic-ui-react';
import { HtmlEditor, TableContextMenu, TableResizing } from 'devextreme-react/html-editor';
import { useTranslation } from 'react-i18next';
import McqsQuestionTemplate from '../Templates/McqsQuestionTemplate';
import McqsQuestionMockTemplate from '../Templates/McqsQuestionMockTemplate';
import { ErrorSegment, ReportForm } from '../../..';
import { Consts, Styles } from '../../../../res';
import iconImage from '../../../../res/images/logo.svg';
import { replaceFontFamily } from '../../../../helpers/general';

const { noExtraSpaces } = Styles;

const QuestionPage = (props) => {
    const { Q, onAnswerQuestion, loading, error, isMockExam, isViewOnly, user, theme, onHideSomeAnswers, onExcludeOption } = props;

    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const [showReportIssue, setShowReportIssue] = useState(false);

    if (loading || !Q) return <Segment basic style={{ height: '40vh', ...noExtraSpaces }} loading={loading} />;
    if (error) return <ErrorSegment />;

    const renderMcqsTemplate = () => {
        if (Q.questionTypeId === Consts.QUESTIONS_TYPES.MCQS) {
            if (isMockExam)
                return <McqsQuestionMockTemplate Q={Q} onAnswerQuestion={onAnswerQuestion} theme={theme} isViewOnly={isViewOnly} onExcludeOption={onExcludeOption} />;
            else return <McqsQuestionTemplate Q={Q} onAnswerQuestion={onAnswerQuestion} theme={theme} isViewOnly={isViewOnly} onHideSomeAnswers={onHideSomeAnswers} onExcludeOption={onExcludeOption} />;
        }
        return null;

    };

    return (
        <Segment basic style={noExtraSpaces}>
            <div style={{ width: '100%', position: 'relative' }} fluid>

                <div style={{overflow: 'auto', height: '100%', paddingBottom: '60px'}} className='question-body'>
                    <Grid columns='equal' style={noExtraSpaces}>
                        <GridColumn>
                            <div className="widget-container" style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                <HtmlEditor value={replaceFontFamily(Q.questionText.trim())} readOnly style={{ border: 'none', width: '97%' }} >
                                    <TableContextMenu enabled />
                                    <TableResizing enabled />
                                </HtmlEditor>
                                {!isMockExam && <Icon size='large' title={t('ReportProblem')} name='exclamation triangle' color='red' link onClick={() => setShowReportIssue(true)} />}
                            </div>
                            {renderMcqsTemplate()}
                        </GridColumn>
                    </Grid>
                </div>

                <div className='studentExamWatermark' style={{ top: '40%', left: '40%' }}>
                    <Image loading="lazy" size='medium' src={iconImage} />
                </div>


                <Header
                    as='h1'
                    content={`${user.firstName} ${user.lastName} [${user.email}] ${user.contactNumber}`}
                    className='studentExamWatermark'
                />
            
            </div>
            <Modal
                onClose={() => setShowReportIssue(false)}
                open={showReportIssue}
                content={<ReportForm reportOn={Consts.REPORTS_TYPES.QUESTION} id={Q.questionId} reportLink={Q} showModal={setShowReportIssue} />}
                closeIcon
                header={t('ReportProblem')}
                size='small'
                centered
                closeOnDimmerClick={false}
            />
        </Segment>
    );
};

export default QuestionPage;