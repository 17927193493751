import React from 'react';
import { Grid, GridColumn, Header, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Styles } from '../../res';
import courseImage from '../../res/images/course.jpg';

const { f_16_700_text, f_14_400_text } = Styles;

const BlogCard = ({ data }) => {
    const { title, creatAt, mainImgUrl, id } = data;

    return (
        <div>
            <Link to={`/blogs/${id}`}>
                <Grid columns={2} stackable verticalAlign='middle'>
                    <GridColumn width={10}>
                        <Image loading="lazy"
                            src={mainImgUrl || courseImage}
                            style={{ borderRadius: 8 }}
                        />
                    </GridColumn>

                    <GridColumn width={6}>
                        <Header style={{ ...f_14_400_text, margin: 0 }}>{new Date(creatAt).toLocaleDateString()}</Header>
                        <Header style={{ ...f_16_700_text }}>{title}</Header>
                    </GridColumn>
                </Grid>
            </Link>
        </div>
    );
};

export default BlogCard;