import React, { useState } from 'react';
import { Button, Checkbox, Divider, Header, Icon, Input, Popup, Segment, Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import numbro from 'numbro';
import { HtmlEditor } from 'devextreme-react/html-editor';
import { Styles, Theme } from '../../../../../res';

const { f_14_400_text, f_10_700_text } = Styles;

const prepareCells = (rCount, cCount, cells) => {
    let c = [];
    for (let i = 0; i < rCount; i++) {
        const row = [];
        for (let j = 0; j < cCount; j++) {
            row.push(cells[i * cCount + j]);
        }
        c.push(row);
    }

    return c;
};

const TaskCell = ({ data, onAnswerTask, userTaskAnswer, isViewOnly, isSubmited }) => {
    const { i18n, t } = useTranslation();
    const isArabic = i18n.language === "ar";
    const [value, setValue] = useState(userTaskAnswer?.value || "");
    const [selectedOption, setSelectedOption] = useState(userTaskAnswer?.selectedOption || null);


    if (data.cellType === "NORMAL_QUESTION") return (
        <Segment basic style={{ backgroundColor: Theme.colors.CPA_NAVY, padding: 5 }}>
            <div style={{ backgroundColor: Theme.colors.WHITE, display: 'flex', flexDirection: 'row' }}>
                <Button icon="paste" basic style={{ borderRadius: 0 }} onClick={() => navigator.clipboard.writeText(value)} />
                <Input disabled={isViewOnly} value={value} style={{ borderRadius: 0 }} placeholder={t("Answer")} onChange={(_, { value }) => {
                    if (!isViewOnly || !isSubmited) setValue(value);
                }} />
            </div>

            <div style={{ backgroundColor: Theme.colors.GREY_30, padding: 5 }}>
                <p style={f_10_700_text}>{t('FormattedResponse').toUpperCase()}</p>
                <p style={{ ...f_14_400_text, margin: 0 }}>{numbro(Number(value)).format({ thousandSeparated: true, mantissa: 2 })}</p>
            </div>

            {(!isViewOnly || !isSubmited) && <div style={{ backgroundColor: Theme.colors.WHITE, display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', padding: 5 }}>
                <Button onClick={() => setValue("")} size='mini' style={{ borderRadius: 0, backgroundColor: Theme.colors.CPA_NAVY, color: Theme.colors.WHITE }}>{t('Reset').toUpperCase()}</Button>
                <div style={{ marginLeft: isArabic ? undefined : '5vw', marginRight: isArabic ? '5vw' : undefined }}>
                    <Button onClick={() => document.getElementById('clicktohide').click()} size='mini' style={{ textDecoration: 'underline', borderRadius: 0, backgroundColor: Theme.colors.WHITE, color: Theme.colors.PRIMARY_50 }}>{t('Cancel').toUpperCase()}</Button>
                    <Button
                        onClick={() => {
                            if (!isViewOnly || !isSubmited) {
                                onAnswerTask({ taskId: data.cellId, value });
                                document.getElementById('clicktohide').click();
                            }
                        }}
                        size='mini'
                        style={{ borderRadius: 0, backgroundColor: Theme.colors.CPA_ORANGE, color: Theme.colors.WHITE }}>
                        {t('Accept').toUpperCase()}
                    </Button>
                </div>
            </div>}
        </Segment>
    );
    else return (
        <Segment basic style={{ backgroundColor: Theme.colors.CPA_NAVY, padding: 5 }}>
            <Header style={{ ...f_14_400_text, color: Theme.colors.WHITE, margin: 5 }}>{t('SelectOptionBelow')}</Header>
            <div style={{ backgroundColor: Theme.colors.WHITE, padding: 5 }}>
                {
                    data.options.map((op, i) => <div
                        key={i}
                        style={{ display: 'flex', alignItems: 'center', gap: 10, marginBottom: 10 }}
                        onClick={() => {
                            if (!isViewOnly || !isSubmited) {
                                setSelectedOption(op.__KEY__);
                            }
                        }}
                    >
                        <Checkbox
                            radio
                            disabled={isViewOnly}
                            checked={selectedOption === op.__KEY__}
                        />

                        <div>
                            <Header style={f_14_400_text}>{isArabic ? op.textAr : op.textEn}</Header>
                        </div>
                    </div>)
                }
            </div>
            <Divider style={{ margin: 0 }} />
            {(!isViewOnly || !isSubmited) && <div style={{ backgroundColor: Theme.colors.WHITE, display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', padding: 5 }}>
                <Button onClick={() => setSelectedOption(null)} size='mini' style={{ borderRadius: 0, backgroundColor: Theme.colors.CPA_NAVY, color: Theme.colors.WHITE }}>{t('Reset').toUpperCase()}</Button>
                <div style={{ marginLeft: isArabic ? undefined : '5vw', marginRight: isArabic ? '5vw' : undefined }}>
                    <Button onClick={() => document.getElementById('clicktohide').click()} size='mini' style={{ textDecoration: 'underline', borderRadius: 0, backgroundColor: Theme.colors.WHITE, color: Theme.colors.PRIMARY_50 }}>{t('Cancel').toUpperCase()}</Button>
                    <Button
                        onClick={() => {
                            if (!isViewOnly || !isSubmited) {
                                onAnswerTask({ taskId: data.cellId, selectedOption });
                                document.getElementById('clicktohide').click();
                            }
                        }}
                        size='mini'
                        style={{ borderRadius: 0, backgroundColor: Theme.colors.CPA_ORANGE, color: Theme.colors.WHITE }}>
                        {t('Accept').toUpperCase()}
                    </Button>
                </div>
            </div>}
        </Segment>
    );
};

const TableQuestionTemplate = ({ rowsCount, colsCount, cells, sectionId, userAnswer, onUserAnswerSection, isViewOnly, isSubmited }) => {
    const { i18n } = useTranslation();
    const isArabic = i18n.language === "ar";
    const rCount = parseInt(rowsCount);
    const cCount = parseInt(colsCount);
    const table = prepareCells(rCount, cCount, cells);
    const [tasksAnswers, setTasksAnswers] = useState(userAnswer?.tasksAnswers || []);

    const onAnswerTask = (taskAnswer) => {
        const taskIndex = tasksAnswers.findIndex(s => s.taskId === taskAnswer.taskId);
        if (taskIndex !== -1) {
            const temp = [...tasksAnswers];
            temp.splice(taskIndex, 1, taskAnswer);
            setTasksAnswers(temp);
            onUserAnswerSection({ sectionId, tasksAnswers: temp });
        } else {
            setTasksAnswers([...tasksAnswers, taskAnswer]);
            onUserAnswerSection({ sectionId, tasksAnswers: [...tasksAnswers, taskAnswer] });
        }
    };

    return (
        <Segment basic id="clicktohide">
            <Table basic='very' celled unstackable collapsing verticalAlign='middle' textAlign='center' style={{ borderCollapse: 'collapse' }}>

                <Table.Body>
                    {
                        table.map((row, i) => <Table.Row key={i}>
                            {
                                row.map((col, j) => {
                                    if (!col) return null;
                                    if (col.colspan === 0 || col.rowspan === 0) return null;
                                    const userAnswer = tasksAnswers.find(t => t.taskId === col.cellId);
                                    let userAnswerText;
                                    let isCorrect;
                                    if (userAnswer) {
                                        if (col?.cellType === "NORMAL_QUESTION") {
                                            userAnswerText = userAnswer?.value;
                                            isCorrect = userAnswer?.value === col?.correctAnswer;
                                        }
                                        else {
                                            const selectedOption = col.options.find(op => op.__KEY__ === userAnswer?.selectedOption);
                                            if (selectedOption) {
                                                userAnswerText = isArabic ? selectedOption?.textAr : selectedOption?.textEn;
                                                isCorrect = selectedOption?.correctAnswer;
                                            }
                                        }
                                    }
                                    return (
                                        <Table.Cell rowSpan={col?.rowspan || 1} colSpan={col?.colspan || 1} key={j}>
                                            <div style={{ backgroundColor: ((isSubmited || isViewOnly) && col?.cellType !== 'NORMAL_TEXT') ? (isCorrect ? "#CDFFB8" : "#FFB5A9") : undefined, display: 'flex', flexDirection: "row", alignItems: "center", justifyContent: col.cellType !== "NORMAL_TEXT" ? 'space-between' : 'center', gap: 50 }}>
                                                <HtmlEditor value={userAnswerText ? userAnswerText : isArabic ? col.textAr : col.textEn} readOnly style={{ border: 'none', boxShadow: 'none', backgroundColor: 'transparent' }} />
                                                {col.cellType !== "NORMAL_TEXT" && <Popup
                                                    style={{ padding: 0 }}
                                                    on='click'
                                                    flowing
                                                    basic
                                                    position={isArabic ? "left center" : 'right center'}
                                                    trigger={
                                                        col.cellType === "MCQ_QUESTION" ? <Icon size='large' name="browser" style={{ cursor: 'pointer', color: Theme.colors.CPA_NAVY, alignSelf: 'center' }} />
                                                            : <span style={{ backgroundColor: Theme.colors.CPA_NAVY, cursor: 'pointer', fontWeight: 'bold', color: "white", padding: 5, borderRadius: 2, fontSize: 10 }}>123</span>

                                                    }
                                                >
                                                    <TaskCell data={col} isViewOnly={isViewOnly} isSubmited={isSubmited} onAnswerTask={onAnswerTask} userTaskAnswer={userAnswer} />
                                                </Popup>

                                                }
                                            </div>
                                        </Table.Cell>
                                    );
                                })
                            }
                        </Table.Row>)
                    }
                </Table.Body>

            </Table>
        </Segment>
    );
};


export default TableQuestionTemplate;