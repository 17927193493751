import { Theme } from './Theme';

export const Styles = {
    blockquoteUp: {
        position: 'absolute',
        fontSize: 150,
        color: '#21BA45',
        fontFamily: 'Georgia',
        opacity: 0.5,
        left: 0,
    },
    blockquoteDown: {
        position: 'absolute',
        fontSize: 150,
        right: 0,
        bottom: -30,
        color: '#21BA45',
        fontFamily: 'Georgia',
        opacity: 0.3
    },
    background: {
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        margin: 0,
        padding: 0,
        paddingTop: 50,
        paddingBottom: 50
    },
    noExtraSpaces: {
        margin: 0,
        padding: 0
    },
    carouselExamItemButton: {
        minWidth: 60,
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '.8rem',
        color: 'white'
    },
    f_24_700_text: {
        fontFamily: 'cairo',
        fontSize: '1.75rem',
        fontWeight: '700',
    },
    f_24_400_text: {
        fontFamily: 'cairo',
        fontSize: '1.75rem',
        fontWeight: '400',
    },
    f_20_700_text: {
        fontFamily: 'cairo',
        fontSize: '1.5rem',
        fontWeight: '700',
    },
    f_20_400_text: {
        fontFamily: 'cairo',
        fontSize: '1.5rem',
        fontWeight: '400',
    },
    f_18_700_text: {
        fontFamily: 'cairo',
        fontSize: '1.25rem',
        fontWeight: '700',
    },
    f_18_400_text: {
        fontFamily: 'cairo',
        fontSize: '1.25rem',
        fontWeight: '400',
    },
    f_16_700_text: {
        fontFamily: 'cairo',
        fontSize: '1.125rem',
        fontWeight: '700',
    },
    f_16_400_text: {
        fontFamily: 'cairo',
        fontSize: '1.125rem',
        fontWeight: '400',
    },
    f_14_700_text: {
        fontFamily: 'cairo',
        fontSize: '1rem',
        fontWeight: '700',
    },
    f_14_400_text: {
        fontFamily: 'cairo',
        fontSize: '1rem',
        fontWeight: '400',
    },
    f_12_700_text: {
        fontFamily: 'cairo',
        fontSize: '0.875rem',
        fontWeight: '700',
    },
    f_12_400_text: {
        fontFamily: 'cairo',
        fontSize: '0.875rem',
        fontWeight: '400',
    },
    f_10_700_text: {
        fontFamily: 'cairo',
        fontSize: '0.75rem',
        fontWeight: '700',
    },
    f_10_400_text: {
        fontFamily: 'cairo',
        fontSize: '0.75rem',
        fontWeight: '400',
    },
    f_40_700_text: {
        fontFamily: 'cairo',
        fontSize: '2.3rem',
        fontWeight: '700',
    },
    solidBtn: {
        backgroundColor: Theme.colors.PRIMARY_50,
        color: 'white',
    },
    basicBtn: {
        boxShadow: `0 0 0 1px ${Theme.colors.PRIMARY_50}`,
        color: Theme.colors.PRIMARY_50,
        backgroundColor: 'transparent',
    },
    container: {
        width: '73vw'
    },
    userSegmentStyle: {
        backgroundColor: Theme.colors.WHITE,
        borderRadius: 8
    }

};