import React, { useEffect, useState } from 'react';
import { Segment, Modal, Icon } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import Draggable from 'react-draggable'; // The default
import request from '../../../api';
import { replaceFontFamily } from '../../../helpers/general';

const QuestionTranslationModal = ({ lang, question, examRoundId, show, setShow }) => {
    const [questionHeader, setQuestionHeader] = useState('');
    const [questionBody, setQuestionBody] = useState('');

    useEffect(() => {
        setQuestionHeader('');
        setQuestionBody('');
        if (show && question) {
            request.get(`/student/round/exam/questions/${examRoundId}?questionId=${question.questionId}&lang=${lang}`)
                .then(d => {
                    request.get(`/student/question/${question.questionId}/options?lang=${lang}`)
                        .then(
                            qd => {
                                const alphArray = lang === 'ar' ? ['أ', 'ب', 'جـ', 'د', 'هـ', 'و', 'ز', 'حـ', 'ط', 'ي', 'ك', 'ل', 'م', 'ن', 'س'] : ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O'];
                                let body = '';
                                let options = qd.data.data;
                                let index = 0;

                                for (const element of question.options) {
                                    let item = options.find(x => x.id === element.id);
                                    body += `<div style="direction:${lang === "ar" ? "rtl" : "ltr"};" class="tw-my-6" "><span>${alphArray[index++]}.</span> ${item.contents[0].content}</div>`;
                                }

                                setQuestionHeader(d.data.data[0].questionText?.replaceAll('<p>', `<p style="direction:${lang === "ar" ? "rtl" : "ltr"};">`));
                                setQuestionBody(body);
                                document.body.classList.remove('dimmable');
                                document.body.classList.remove('dimmed');
                            }
                        );

                });
        }
        //eslint-disable-next-line
    }, [show]);

    return (
        <Draggable>
            <Modal
                style={{ textAlign: lang === 'en' ? 'left' : 'right', margin: '5px' }}
                open={show}
                content={
                    <Segment basic dangerouslySetInnerHTML={{ __html: questionBody }}></Segment>
                }
                onClose={() => setShow(false)}
                closeIcon={<Icon name='close' color='black' />}
                dimmer={{ style: { backgroundColor: 'transparent' } }}
                size='small'
                centered
                header={<div dangerouslySetInnerHTML={{ __html: replaceFontFamily(questionHeader?.trim()) }} style={{ maxHeight: '40vh', overflow: 'auto', padding: 10, direction: lang === 'ar' ? 'rtl' : 'ltr' }} />}
                closeOnDimmerClick={false}
            />
        </Draggable >
    );
};

export default withTranslation()(QuestionTranslationModal);