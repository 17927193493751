import { combineReducers } from 'redux';

import SigninReducer from './landing section/SigninReducer';
import SignupReducer from './landing section/SignupReducer';
import ForgotPasswordReducer from './landing section/ForgotPasswordReducer';
import ResetPasswordReducer from './landing section/ResetPasswordReducer';
import ActicationCodeReducer from './landing section/ActivationReducer';
import ContactUsReducer from './landing section/ContactUsReducer';
import CountriesReducer from './landing section/CountriesReducer';
import ProfileReducer from './landing section/ProfileReducer';
import NewsReducer from './landing section/NewsReducer';
import BlogsReducer from './landing section/BlogsReducer';
import NotificationsReducer from './landing section/NotificationsReducer';

// Admin
import UploadsReducer from './admin section/UploadsReducer';
import ExplanationsListReducer from './admin section/ExplanationsListReducer';



// Landing
import ConfigsReducer from './landing section/ConfigsReducer';
import LandingCertificationsReducer from './landing section/CertificationsReducer';
import LandingCoursesReducer from './landing section/CoursesReducer';
import LandingStuffReducer from './landing section/StuffReducer';

// User 

import UserExamReducer from './user section/ExamReducer';
import UserCoursesReducer from './user section/CoursesReducer';
import UserSubscriptionsReducer from './user section/SubscriptionsReducer';
import UserExamsLogReducer from './user section/ExamsLogReducer';

export default combineReducers({
    configs: ConfigsReducer,
    auth: SigninReducer,
    signup: SignupReducer,
    forgot: ForgotPasswordReducer,
    resetPassword: ResetPasswordReducer,
    activation: ActicationCodeReducer,
    contactus: ContactUsReducer,
    countries: CountriesReducer,
    profile: ProfileReducer,
    notifications: NotificationsReducer,

    landingNews: NewsReducer,
    landingCourses: LandingCoursesReducer,
    landingCerts: LandingCertificationsReducer,
    landingStuff: LandingStuffReducer,
    landingBlogs: BlogsReducer,


    adminUploads: UploadsReducer,
    adminExplanationsList: ExplanationsListReducer,


    userExam: UserExamReducer,
    userCourses: UserCoursesReducer,
    userSubscriptions: UserSubscriptionsReducer,
    userExamsLog: UserExamsLogReducer,


});