import i18n from 'i18next';
import {
    PROFILE_INFO_CHANGED,
    PROFILE_OPERATION_START,
    PROFILE_OPERATION_FAIL,
    PROFILE_OPERATION_FINISH,
    PROFILE_SUCCESS,
} from '../types';
import request from '../../api';
import { signinSuccess } from './SigninActions';
import { badReq, noConnection } from '../../helpers/errors';

export const profileInfoChanged = ({ props, value }) => {
    return { type: PROFILE_INFO_CHANGED, payload: { props, value } };
};

export const changeProfile = ({ firstName, lastName, gender, country, mobile, email, imageId }) => async (dispatch, getState) => {
    const newProfile = { firstName, lastName, gender, email, coverImgUrl: imageId, countryId: country, contactNumber: mobile };
    const checkOldMail = getState().auth.user.email === email;
    if (checkOldMail) delete newProfile.email;
    const checkOldImg = getState().auth.user.coverImgUrl === imageId;
    if (checkOldImg) delete newProfile.coverImgUrl;

    dispatch({ type: PROFILE_OPERATION_START });
    try {
        await request.post('/user/profile/update', newProfile);
        const { auth: { user } } = getState();
        dispatch({ type: PROFILE_SUCCESS });
        signinSuccess(dispatch, { ...user, ...newProfile }, true);
    } catch (err) {
        if (err.response.status === 409) {
            return dispatch({ type: PROFILE_OPERATION_FAIL, payload: { props: 'email', value: i18n.t('ErrorEmailExist') } });
        }
        if (err.response) badReq();
        else noConnection();
    } finally {
        dispatch({ type: PROFILE_OPERATION_FINISH });
    }
};
