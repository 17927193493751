import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Header, Segment } from 'semantic-ui-react';
import QuestionSection from './Sections';
import useJsonSectionParser from './useJsonSectionParser';
import { Consts } from '../../res';
import { swapArrayLocs } from '../../helpers/general';

const QuestionBuilder = ({ data: { value, setValue } }) => {
    const { t } = useTranslation();
    const [data, setData] = useState(useJsonSectionParser(value) || []);

    const addNewSection = (sectionType) => setData([...data, { sectionId: `${_.uniqueId('section')}_${new Date().getTime()}`, type: sectionType }]);
    const deleteSection = (sectionId) => {
        const temp = data.filter(s => s.sectionId !== sectionId);
        setData(temp);
        setValue(JSON.stringify(temp));
    };
    const saveSection = (sectionId, sectionData) => {
        const temp = [...data];
        temp.splice(data.findIndex(s => s.sectionId === sectionId), 1, sectionData);
        setData(temp);
        setValue(JSON.stringify(temp));
    };
    const cloneSection = (sectionData) => {
        const temp = [...data];
        const newSectionId = `${_.uniqueId('section')}_${new Date().getTime()}`;
        temp.splice(temp.length, 0, { ...sectionData, sectionId: newSectionId });
        setData(temp);
        setValue(JSON.stringify(temp));
    };
    const deleteAll = () => {
        setData([]);
        setValue(JSON.stringify([]));
    };

    const moveSectionUp = (index) => {
        if (index === 0) return;
        const newData = swapArrayLocs(data, index, index - 1);
        setData(newData);
        setValue(JSON.stringify(newData));
    };

    const moveSectionDown = (index) => {
        if (index === data.length - 1) return;
        const newData = swapArrayLocs(data, index, index + 1);
        setData(newData);
        setValue(JSON.stringify(newData));
    };

    return (
        <Segment>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <Header>{t("AddNewSection")}</Header>
                <div>
                    <Button title={t("TextSection")} size='small' icon='font' color='green' onClick={() => addNewSection(Consts.QUESTION_BUILDER_SECTIONS_TYPES.TEXT_SECTION)} />
                    <Button title={t("SelectorSection")} size='small' icon='list ul' color='green' onClick={() => addNewSection(Consts.QUESTION_BUILDER_SECTIONS_TYPES.SELECTOR_SECTION)} />
                    <Button title={t("ParagraphSection")} size='small' icon='align left' color='green' onClick={() => addNewSection(Consts.QUESTION_BUILDER_SECTIONS_TYPES.PARAGRAPH_SECTION)} />
                    <Button title={t("TableSection")} size='small' icon='th list' color='green' onClick={() => addNewSection(Consts.QUESTION_BUILDER_SECTIONS_TYPES.TABLE_SECTION)} />
                    <Button title={t("CheckBoxesSection")} size='small' icon='list ol' color='green' onClick={() => addNewSection(Consts.QUESTION_BUILDER_SECTIONS_TYPES.CHECKBOXES_SECTION)} />
                    <Button title={t("DeleteAll")} size='small' icon='trash' color='red' onClick={deleteAll} />
                </div>
            </div>

            <Divider />

            {
                data.map((section, i) => <Segment compact key={i}>
                    <QuestionSection
                        data={{ ...section, order: i + 1 }}
                        saveSection={saveSection}
                        deleteSection={deleteSection}
                        cloneSection={cloneSection}
                        moveSectionDown={moveSectionDown}
                        moveSectionUp={moveSectionUp}
                        sectionIndex={i}
                    />
                </Segment>
                )
            }

        </Segment>
    );
};

export default QuestionBuilder;