import i18next from 'i18next';

export default () => {
    document.addEventListener('contextmenu', event => event.preventDefault());

    // document.addEventListener("keydown", (zEvent) => {
    //     const keyStr = ["Control", "Meta"].includes(zEvent.key);
    //     if (keyStr) {
    //         document.getElementsByTagName('html')[0].remove();
    //         alert('OOPS, Blocked. I can always see you , you a little cheat :)');
    //     }

    // });

    window.onbeforeprint = () => {
        document.getElementsByTagName('html')[0].remove();
        alert(i18next.t('NoPrint'));
    };

};
