import React, { useState } from 'react';
import { Icon, Popup, Label, Menu } from 'semantic-ui-react';
import i18next from 'i18next';
import { useSelector, useDispatch } from 'react-redux';
import NotificationsList from '../Lists/NotificationsList';
import { setNotificationsSeen } from '../../actions';
import { Consts } from '../../res';

const MessagesButton = ({ user, showSidebar }) => {
    const dispatch = useDispatch();
    const isGoingExamRunning = useSelector(({ userExam }) => userExam.onGoingExam);
    const { notifications, notImportantSeen } = useSelector(({ notifications }) => notifications);
    const [isOpened, setIsOpened] = useState(false);
    const { ltr, rtl } = style(showSidebar);

    const onClose = () => {
        const seenNots = notifications.filter(n => n.type === Consts.NOTIFICATIONS_TYPES.IMPORTANT && !n.readAt).map(n => n.notificationId);
        if (seenNots.length > 0) dispatch(setNotificationsSeen(seenNots));
        setIsOpened(false);
    };

    if ((isGoingExamRunning && !isGoingExamRunning?.isViewOnly) || !user) return null;
    return (
        <div style={i18next.language === 'en' ? ltr : rtl}>
            <Popup
                on='click'
                size='huge'
                flowing
                open={isOpened || notImportantSeen > 0}
                onClose={onClose}
                trigger={
                    <Menu.Item onClick={() => setIsOpened(true)}>
                        <Icon link name='envelope outline' color='red' size='large' inverted circular />
                        {notImportantSeen > 0 && <Label style={{ position: 'absolute', left: '2vw' }} color='red' basic circular size='tiny'>
                            {notImportantSeen}
                        </Label>}
                    </Menu.Item>
                }
            >
                <NotificationsList type={Consts.NOTIFICATIONS_TYPES.IMPORTANT} items={notifications.filter(n => n.type === Consts.NOTIFICATIONS_TYPES.IMPORTANT)} />
            </Popup>
        </div>
    );
};

const style = (showSidebar) => ({
    rtl: {
        position: 'fixed',
        bottom: '5vh',
        zIndex: 999999,
        cursor: 'pointer',
        left: showSidebar ? '12vw' : '2vw'
    },
    ltr: {
        position: 'fixed',
        bottom: '5vh',
        zIndex: 999999,
        cursor: 'pointer',
        right: showSidebar ? '12vw' : '2vw'
    }
});
export default MessagesButton;