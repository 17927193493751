import React from 'react';
import { Segment } from 'semantic-ui-react';
import i18next from 'i18next';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const CircularProgressBar = ({ value, text, min, max, color, strokeWidth, moreStyle }) => {
    const isArabic = i18next.language === 'ar';

    const arabicStyle = {
        right: '50%',
        transform: 'translateX(50%)'
    };

    const englishStyle = {
        left: '50%',
        transform: 'translateX(-50%)'
    };

    const style = isArabic ? arabicStyle : englishStyle;

    return (
        <Segment basic
            textAlign='center'
            style={{
                width: 200,
                height: 200,
                ...style,
                ...moreStyle,
                maxHeight: 200,
                maxWidth: 200
            }}
        >
            <CircularProgressbar
                styles={color && buildStyles({
                    textColor: color,
                    pathColor: color,
                })}
                strokeWidth={strokeWidth || 8}
                value={value}
                text={text}
                minValue={min || 0}
                maxValue={max || 100}
            />
        </Segment>
    );
};

export default CircularProgressBar;