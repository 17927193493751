import React from 'react';
import { Grid, GridColumn, Header, Image, Segment, Button, Progress } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Styles } from '../../res';
import courseImage from '../../res/images/course.jpg';
import PATHS from '../../Routers/PATHS';
import history from '../../history';

const { f_16_700_text, solidBtn, f_14_400_text } = Styles;

const StudyCard = ({ onChangeCourse, course, getCourseDetails }) => {
    const { t } = useTranslation();

    return (
        <Segment
            basic
            style={{
                background: 'linear-gradient(0deg, #F3F4FB, #F3F4FB), #F3F4FB',
                borderRadius: 8,
            }}
        >
            <Grid columns={3} stackable verticalAlign='middle'>
                <GridColumn width={4}>
                    <Image loading="lazy"
                        src={course.coverImgUrl || courseImage}
                        style={{ borderRadius: 8 }}
                    />
                </GridColumn>

                <GridColumn width={4}>
                    <Header style={{ ...f_16_700_text }}>{course.name}</Header>
                    <Header style={{ ...f_14_400_text, margin: 0 }}>{course.certificateName}</Header>
                    <Header style={{ ...f_14_400_text, margin: 0 }}>{`${t('Units')}: ${course.unitsCnt}`}</Header>
                    <Header style={{ ...f_14_400_text, margin: 0 }}>{`${t('Topics')}: ${course.topicsCnt}`}</Header>
                </GridColumn>

                <GridColumn width={8} textAlign='right'>
                    <Progress progress percent={parseInt(course.courseProgress)} size='small' color='blue' />
                    {/* <Divider hidden />
                    <Divider hidden /> */}
                    <Button
                        style={solidBtn}
                        onClick={() => {
                            onChangeCourse(course);
                            getCourseDetails(course.id);
                            history.push(PATHS.USER_COURSE_DETAILS.URL);
                        }}
                    >{t('ContinueStudying').toUpperCase()}
                    </Button>
                </GridColumn>
            </Grid>
        </Segment>
    );
};

export default StudyCard;