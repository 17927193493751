import { store } from '../store';
const { getState } = store;

export default (resource) => {
    const user = getState().auth.user;
    const userPermissions = user.ac || {};

    return userPermissions[resource];

};

export const checkPermission = ([res, perm]) => {
    const user = getState().auth.user;
    const userPermissions = user.ac || {};

    return userPermissions[res]?.[perm]?.granted;
};