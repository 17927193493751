
const useJsonSectionParser = (jsonData, defaultOutput) => {
    try {
        const data = JSON.parse(jsonData);
        if (data) return data;
        if (defaultOutput) return defaultOutput;
        return [];
    } catch (error) {
        if (defaultOutput) return defaultOutput;
        return [];
    }
};

export default useJsonSectionParser;