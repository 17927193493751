import React, { useState } from 'react';
import { Card, Image, Icon } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { htmlToText } from 'html-to-text';
import stringShorten from '../../helpers/stringShorten';
import { Theme, Styles } from '../../res';
import history from '../../history';
import certImage from '../../res/images/course.jpg';
import PATHS from '../../Routers/PATHS';

const { f_10_700_text, f_10_400_text, f_12_700_text } = Styles;

const CertificateCard = (props) => {
    const { t, id, name, code, subscribersCount, description, coverImgUrl, short } = props;
    const [selected, setSelected] = useState(false);

    return (
        <Card
            onMouseEnter={() => setSelected(true)}
            onMouseLeave={() => setSelected(false)}
            onClick={() => history.push(`${PATHS.LANDING_CERTIFICATIONS.URL}/${id}?packages`)}
            style={{ transform: 'none', height: short ? '33vh' : '40vh', margin: 10, padding: 8, borderRadius: 8, boxShadow: selected ? "0px 8px 20px rgba(59, 45, 132, 0.64)" : 'none', backgroundColor: selected ? Theme.colors.PRIMARY_50 : Theme.colors.WHITE }}
        >

            {(!selected || short) && <Image loading="lazy"
                src={coverImgUrl || certImage}
                style={{ display: 'flex', height: 100, width: null }}
                fluid
            />}

            <Card.Content style={{ borderTop: 'none', paddingBottom: 5 }}>

                <Card.Header style={{ ...f_12_700_text, color: selected ? Theme.colors.WHITE : Theme.colors.BLACK }}>
                    <div>{selected ? name : code}</div>
                    <div>{name}</div>
                </Card.Header>

                {!short && <Card.Meta style={{ ...f_10_700_text, marginTop: 5, color: selected ? Theme.colors.WHITE : Theme.colors.BLACK }}>
                    {`${subscribersCount} ${t('Subscriber')}`}
                </Card.Meta>}

                {!short && <Card.Description style={{ ...f_10_400_text, color: selected ? Theme.colors.WHITE : Theme.colors.GREY_70 }}>
                    {selected ? stringShorten(htmlToText(description, { wordwrap: null }), 250) : stringShorten(htmlToText(description, { wordwrap: null }), 100)}
                </Card.Description>}
            </Card.Content>

            {!short && <Card.Content extra style={{ ...f_10_700_text, borderTop: 'none', color: selected ? Theme.colors.WHITE : Theme.colors.PRIMARY_50 }} >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>
                    <div style={{ cursor: 'pointer' }} onClick={() => history.push(`${PATHS.LANDING_CERTIFICATIONS.URL}/${id}`)}>
                        {t('ReadDetails')}
                        <Icon name='chevron right' />
                    </div>

                    <div style={{ cursor: 'pointer' }} onClick={() => history.push(`${PATHS.LANDING_CERTIFICATIONS.URL}/${id}?packages`)}>
                        {t('SubscribeNow')}
                        <Icon name='chevron right' />
                    </div>
                </div>
            </Card.Content>}
        </Card>

    );
};

export default withTranslation()(CertificateCard);
