import React, { useEffect, useState, createRef } from 'react';
import { Segment, Icon } from 'semantic-ui-react';
import ReactElasticCarousel, { consts } from 'react-elastic-carousel';
import i18next from 'i18next';
import { ErrorSegment, SuccessStoryCard } from '..';
import request from '../../api';
import { Theme } from '../../res';
import bgImage from '../../res/images/walls/bg3.svg';

let resetTimeout;

const SuccessStoriesList = ({ id, isCert, all }) => {
    const carouselRef = createRef();
    const [stories, setStories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const getStoriesList = async () => {
            setLoading(true);
            try {
                const { data: { data } } = all ? await request('/public/success_stories') : await request(`/public/success_stories?${isCert ? 'certificateId' : 'courseId'}=${id}`);
                setStories(data || []);
            } catch (error) {
                setError(error);
            }
            finally {
                setLoading(false);
            }
        };
        getStoriesList();

        return () => clearTimeout(resetTimeout);
        // eslint-disable-next-line
    }, []);


    const renderArrow = ({ type, onClick, isEdge }) => <Icon style={{ alignSelf: 'center', cursor: 'pointer', color: Theme.colors.SECONDARY_50 }} size='big' onClick={onClick} disabled={isEdge} name={type === consts.PREV ? 'chevron left' : 'chevron right'} />;
    // const renderPagination = ({ pages, activePage, onClick }) => {
    //     return (
    //         <div style={{ display: 'flex', justifyContent: 'center', gap: 5, padding: 10, marginTop: '10vh', flexWrap: 'wrap' }}>
    //             {pages.map(page => {
    //                 const isActivePage = activePage === page;
    //                 return (
    //                     <div
    //                         key={page}
    //                         onClick={() => onClick(page)}
    //                         style={{ cursor: 'pointer', height: 10, width: 10, borderRadius: '50%', backgroundColor: isActivePage ? Theme.colors.SECONDARY_50 : Theme.colors.GREY_70 }}
    //                     />
    //                 );
    //             })}
    //         </div>
    //     );
    // };

    if (error) return <ErrorSegment />;
    if (!stories.length) return <Segment basic textAlign='center' loading={loading}><ErrorSegment icon='wait' text={i18next.t('ComingSoon')} /></Segment>;

    return (
        <Segment basic textAlign='center' loading={loading}>

            <div style={{
                background: `url(${bgImage})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                minHeight: '70vh',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex'
            }}>

                <ReactElasticCarousel
                    ref={carouselRef}
                    focusOnSelect
                    isRTL={i18next.language === 'en' ? false : true}
                    itemsToShow={1}
                    enableAutoPlay
                    autoPlaySpeed={5000}
                    transitionMs={2000}
                    renderArrow={renderArrow}
                    // renderPagination={renderPagination}
                    pagination={false}
                    onNextEnd={() => {
                        const { activePage, pages } = carouselRef.current.state;
                        clearTimeout(resetTimeout);
                        if (activePage + 1 === pages.length) {
                            resetTimeout = setTimeout(() => {
                                carouselRef?.current?.goTo(0);
                            }, 3000);
                        }
                    }}
                >
                    {
                        stories.map(s => <SuccessStoryCard key={s.id} name={s.name} quote={s.content} img={s.imgUrl} />)
                    }
                </ReactElasticCarousel>
            </div>

        </Segment>
    );
};

export default SuccessStoriesList;
