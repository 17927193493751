import i18next from 'i18next';
import cookies from 'react-cookies';

import {
    CONFIGS_INFO_CHANGED
} from '../../actions/types';

const cookiesLang = cookies.load('languagePref');


const INITIAL_STATE = {
    inAuthLayout: false,
    language: cookiesLang || i18next.language,
    languageOptions: [
        { key: '1', text: i18next.t('Arabic'), value: 'ar' },
        { key: '2', text: i18next.t('English'), value: 'en', }
    ],

};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CONFIGS_INFO_CHANGED:
            return { ...state, [action.payload.props]: action.payload.value };
        default:
            return state;
    }
};
