import React from 'react';
import { Image, Header } from 'semantic-ui-react';
import { Styles, Theme } from '../../res';
import placeholderImage from '../../res/images/course.jpg';
import styled from 'styled-components';

const { f_16_700_text, f_12_400_text } = Styles;

const ContainerW = styled.div`
  padding: 10px;
  background-color: ${Theme.colors.PRIMARY_2};
  border-radius: 8px;
  margin: 5px;
  display: flex;

  /* Media Query for Mobile Responsiveness */
  @media (max-width: 768px) { // Adjust breakpoint (768px) as needed 
    flex-wrap: wrap; 
  }
`;

const NewsCard = ({ data }) => {
    const { title, content, imgUrl, extUrl } = data;

    return (
        <a href={extUrl || undefined} target='_blank' rel="noreferrer" >
            <ContainerW>
                <div style={{borderRadius: '10px', minHeight: '20vh', maxHeight: '200px', paddingLeft: '10px', paddingRight: '10px' }}>
                    <Image loading="lazy" fluid src={imgUrl || placeholderImage} 
                        style={{ borderRadius: '8px', objectFit: 'cover'}} />
                </div>
        
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Header style={f_16_700_text}>{title}</Header>
                    <Header.Subheader style={f_12_400_text}>{content}</Header.Subheader>
                </div>
            </ContainerW>
        </a>
    );
};

export default NewsCard;