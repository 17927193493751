import __ from 'lodash';
import React, { useState, useRef } from 'react';
import { Button, Divider, Header, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import DataGrid, { Column, Editing, Form } from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';


const CheckBoxesSection = ({ data, saveSection, deleteSection, cloneSection, moveSectionDown, moveSectionUp, sectionIndex }) => {
    const { t } = useTranslation();
    const gridRef = useRef();
    const [checks] = useState(data?.checks || []);

    return (
        <Segment basic>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <Header icon="list ol" content={`${t("Section")} #${data.order}`} />
                <div>
                    <Button icon='save' positive onClick={() => saveSection(data.sectionId, { ...data, checks: gridRef.current._instance.getDataSource()._items })} />
                    <Button icon='copy' positive onClick={() => cloneSection({ ...data, checks: gridRef.current._instance.getDataSource()._items.map(i => ({ ...i, __KEY__: `${__.uniqueId('check')}_${new Date().getTime()}` })) })} />
                    <Button icon='trash' negative onClick={() => deleteSection(data.sectionId)} />
                    <Button title={t("Up")} size='small' icon='arrow up' color='blue' onClick={() => moveSectionUp(sectionIndex)} />
                    <Button title={t("Down")} size='small' icon='arrow down' color='blue' onClick={() => moveSectionDown(sectionIndex)} />

                </div>
            </div>
            <Divider />

            <DataGrid
                ref={gridRef}
                dataSource={checks}
                showBorders
                columnHidingEnabled
                wordWrapEnabled
                columnAutoWidth
            >
                <Editing
                    mode='row'
                    useIcons
                    allowAdding
                    allowUpdating
                    allowDeleting
                >
                    <Form>
                        <Item itemType="group" colCount={2} colSpan={2}>
                            <Item isRequired dataField="nameEn" />
                            <Item isRequired dataField="nameAr" />
                            <Item dataField="correctAnswer" />
                        </Item>
                    </Form>
                </Editing>

                <Column dataField="nameEn" caption={t('EnglishName')} />
                <Column dataField="nameAr" caption={t('ArabicName')} />
                <Column
                    caption={t('Correct')}
                    dataType="boolean"
                    dataField='correctAnswer'
                />
            </DataGrid>
        </Segment>
    );
};

export default CheckBoxesSection;