import {
    USER_GET_ALL_OLD_EXAMS_START,
    USER_GET_ALL_OLD_EXAMS_FAIL,
    USER_GET_ALL_OLD_EXAMS_FINISH,
    USER_GET_ALL_OLD_EXAMS,
} from '../../actions/types';

const INITIAL_STATE = {
    examsLog: [],
    error: '',
    loading: false,
};


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case USER_GET_ALL_OLD_EXAMS_START:
            return { ...state, loading: true };
        case USER_GET_ALL_OLD_EXAMS_FINISH:
            return { ...state, loading: false };
        case USER_GET_ALL_OLD_EXAMS_FAIL:
            return { ...state, error: { [action.payload.props]: action.payload.value } };
        case USER_GET_ALL_OLD_EXAMS:
            return { ...state, examsLog: action.payload };
        default:
            return state;
    }
};

