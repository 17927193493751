import React from 'react';
import { ErrorSegment, Spinner } from '../../components';
import useMultiDataStore from '../../Data Stores/admin section/hooks/useMultiDataStore';

export default (ChildComponent, DS) => {

    const RequireFetchData = (props) => {

        const [fetchData, fetchStatus] = useMultiDataStore(DS);

        if (fetchStatus.loading) return <Spinner />;
        if (fetchStatus.error) return <ErrorSegment />;

        return <ChildComponent {...props} {...fetchData} fetchStatus={fetchStatus} />;
    };


    return RequireFetchData;
};