import i18next from 'i18next';
import {
    ADMIN_GET_ALL_TOPICS,
    ADMIN_GET_ALL_TOPICS_OPERATION_FAIL,
    ADMIN_GET_ALL_TOPICS_OPERATION_FINISH,
    ADMIN_GET_ALL_TOPICS_OPERATION_START,
    ADMIN_GET_BOOK_DETAILS_OPERATION_START,
    ADMIN_GET_BOOK_DETAILS_OPERATION_FINISH,
    ADMIN_GET_BOOK_DETAILS_OPERATION_FAIL,
    ADMIN_GET_BOOK_DETAILS,
    ADMIN_GET_VIDEO_DETAILS_OPERATION_START,
    ADMIN_GET_VIDEO_DETAILS_OPERATION_FINISH,
    ADMIN_GET_VIDEO_DETAILS_OPERATION_FAIL,
    ADMIN_GET_VIDEO_DETAILS,
    ADMIN_DELETE_BOOK_DETAILS,
} from '../../actions/types';

const INITIAL_STATE = {
    topics: [],
    error: '',
    loading: false,

    videos: [],
    videoError: '',
    videoLoading: false,

    bookDetails: null,
    bookError: '',
    bookLoading: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADMIN_GET_ALL_TOPICS_OPERATION_START:
            return { ...state, loading: true };
        case ADMIN_GET_ALL_TOPICS_OPERATION_FINISH:
            return { ...state, loading: false };
        case ADMIN_GET_ALL_TOPICS_OPERATION_FAIL:
            return { ...state, error: { [action.payload.props]: action.payload.value } };
        case ADMIN_GET_ALL_TOPICS:
            return { ...state, topics: prepareData(action.payload) };
        case ADMIN_GET_VIDEO_DETAILS_OPERATION_START:
            return { ...state, videoLoading: true };
        case ADMIN_GET_VIDEO_DETAILS_OPERATION_FINISH:
            return { ...state, videoLoading: false };
        case ADMIN_GET_VIDEO_DETAILS_OPERATION_FAIL:
            return { ...state, videoError: { [action.payload.props]: action.payload.value } };
        case ADMIN_GET_VIDEO_DETAILS:
            return { ...state, videos: action.payload };
        case ADMIN_GET_BOOK_DETAILS_OPERATION_START:
            return { ...state, bookLoading: true };
        case ADMIN_GET_BOOK_DETAILS_OPERATION_FINISH:
            return { ...state, bookLoading: false };
        case ADMIN_GET_BOOK_DETAILS_OPERATION_FAIL:
            return { ...state, bookError: { [action.payload.props]: action.payload.value } };
        case ADMIN_GET_BOOK_DETAILS:
            return { ...state, bookDetails: action.payload };
        case ADMIN_DELETE_BOOK_DETAILS:
            return { ...state, bookDetails: null };

        default:
            return state;
    }
};

const prepareData = (topics) => {
    return topics.map(t => ({
        key: t.id,
        value: t.id,
        text: `${t.courseName} / ${t.unitName} / ${i18next.language === 'en' ? t.nameEn : t.nameAr} (${t.rank})`
    }));
};