import React, { useState } from 'react';
import { Card, Image, Label } from 'semantic-ui-react';
import { Theme } from '../../res';
import avatarImage from '../../res/images/avatar.svg';
import { Styles } from '../../res';


const { f_16_700_text, f_12_700_text } = Styles;

const TeamCard = ({ member }) => {
    const [selected, setSelected] = useState(false);

    return (
        <Card
            className='animationCardScale'
            style={{ boxShadow: 'none', borderRadius: 8, padding: 10, backgroundColor: selected ? Theme.colors.PRIMARY_50 : Theme.colors.WHITE }}
            onMouseEnter={() => setSelected(true)}
            onMouseLeave={() => setSelected(false)}
        >

            <Image loading="lazy" src={member.imgUrl || avatarImage} rounded fluid />
            <Card.Content style={{ border: 'none', paddingLeft: 0, paddingRight: 0 }} textAlign='left'>
                <Card.Header style={{ ...f_16_700_text, color: selected ? Theme.colors.WHITE : Theme.colors.BLACK }}>{member.fullName}</Card.Header>
                <Label.Group>
                    {
                        member.specialty.split(",").map((sp, indx) => <Label key={indx} style={{ ...f_12_700_text, backgroundColor: Theme.colors.PRIMARY_05, color: Theme.colors.PRIMARY_50 }}>{sp}</Label>)
                    }
                </Label.Group>
            </Card.Content>
        </Card>
    );
};

export default TeamCard;