import React from 'react';
import { Card, Header, Segment } from 'semantic-ui-react';
import Avatar from 'react-avatar';
import { Styles, Theme } from '../../res';

import avatarImage from '../../res/images/avatar.svg';
import crownImage from '../../res/images/crown.svg';


const { f_20_700_text, f_14_400_text } = Styles;

const HonorCard = ({ data }) => {
    const { name, successAspect, imgUrl } = data;

    return (
        <Segment as={Card} basic textAlign='center' style={{ backgroundColor: 'transparent', borderRadius: 0, boxShadow: 'none', border: 'none' }}>
            <div style={{ position: 'relative', zIndex: 10 }}>
                <Avatar src={crownImage} size={120} round style={{ position: 'absolute', left: '50%', top: '50%', zIndex: -1, transform: 'translate(-49%,-48%)' }} />
                <Avatar src={imgUrl || avatarImage} size={77} round style={{ backgroundColor: Theme.colors.WHITE, zIndex: 1 }} />
            </div>
            <Header style={{ ...f_20_700_text, color: Theme.colors.GOLD, marginTop: '4vh', marginBottom: '1vh' }}>{name}</Header>
            <Header style={{ ...f_14_400_text, marginTop: 0 }}>{successAspect}</Header>
        </Segment>
    );
};

export default HonorCard;