import React from 'react';
import { Container, Grid, GridColumn, Header, Segment } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import Stopwatch from '../../Timers/Stopwatch';
import Countdown from '../../Timers/Countdown';
import { Styles } from '../../../../res';
import { Separator } from '../../..';
import request from '../../../../api';

const { noExtraSpaces } = Styles;

const ExamHeader = (props) => {
    const { t, course, user,
        Q, Qindex, questions,
        onGoingExam, userOnFinishExam, isMockExam, theme } = props;


    const onPauseTimer = (isPause) => {
        const examRoundId = onGoingExam.examRoundId;

        request.post('/student/round/exam/pause', { examRoundId, isPause });
    };

    const renderTimer = () => {

        if (onGoingExam.isViewOnly)
            return <Header as='h2' color='red' textAlign='center'>{t('ViewOnly')}</Header>;

        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                {isMockExam ?
                    <Countdown
                        expiryTimestamp={new Date().getTime() + (new Date(onGoingExam.finishDateTime) - new Date())}
                        onExpire={() => userOnFinishExam(onGoingExam)}
                        theme={theme}
                    />
                    : <Stopwatch
                        offsetTimestamp={new Date().getTime() + (new Date().getTime()
                            - new Date(onGoingExam.startDateTime).getTime())
                            - (onGoingExam.totalPauseSeconds * 1000)
                            - (onGoingExam.lastPauseTime ? (new Date().getTime() - new Date(onGoingExam.lastPauseTime).getTime()) : 0)
                        }
                        theme={theme}
                        onPauseChanged={onPauseTimer}
                        isAutoStart={!onGoingExam.lastPauseTime}
                    />}
                <Separator h='1vh' />
            </div>
        );
    };

    const renderPrimaryHeader = () => {
        return (
            <Segment basic style={{ margin: 0, padding: 5, backgroundColor: '#dcdddd' }} inverted >
                <Container style={{ width: '95vw' }}>
                    <Grid columns='equal' verticalAlign='middle' style={noExtraSpaces} stackable>

                        <GridColumn style={noExtraSpaces}>
                            <Header as='h4' style={noExtraSpaces}>{course.name}</Header>
                            <Header as='h5' style={noExtraSpaces}>{`${t('Name')}: ${user.firstName} ${user.lastName} [${user.email}]`}</Header>
                        </GridColumn>

                        <GridColumn style={noExtraSpaces} textAlign='right'>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                {renderTimer()}
                            </div>
                            <Header as='h5' style={noExtraSpaces}>{`${t('Question')}: ${Qindex + 1} / ${questions.length}`}</Header>
                        </GridColumn>

                    </Grid>
                </Container>
            </Segment>
        );
    };

    if (!Q) return null;

    return (
        <Segment basic style={noExtraSpaces}>
            {renderPrimaryHeader()}
        </Segment>
    );
};

export default withTranslation()(ExamHeader);