import React, { useState } from 'react';
import { Accordion, Grid, GridColumn, GridRow, Header, Menu, Modal, Segment } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import cookies from 'react-cookies';
import { VideoPlayer } from '../../components';
import request from '../../api';
import history from '../../history';
import { Consts, Theme, Styles } from '../../res';
import { resolveExam } from '../../helpers/examHelpers';

let lastUnitSelectedId;
const { noExtraSpaces, f_16_700_text, f_14_700_text, userSegmentStyle } = Styles;

const CourseTree = ({ course, t, courseStructReload }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [currentUnit, setCurrentUnit] = useState((lastUnitSelectedId && course?.[lastUnitSelectedId]) ? course[lastUnitSelectedId] : course[0]);
    const [watchVideo, setWatchVideo] = useState(null);
    const isArabic = i18next.language === 'ar';

    const handleClick = (e, titleProps) => {
        const { index } = titleProps;
        const newIndex = activeIndex === index ? -1 : index;
        setActiveIndex(newIndex);
    };

    const handleCurrentUnit = (index) => {
        lastUnitSelectedId = index;
        setCurrentUnit(course[index]);
        setActiveIndex(0);
    };

    const onBookOpen = async (topicId, bookId) => {
        try {
            await request.post('/student/round/lecture/start', { bookId, topicId });
            onCourseProgress();
        } catch (err) {
            return err;
        }
    };

    const onCourseProgress = (withoutLoad) => {
        const selectedCourseCookie = cookies.load('coursePref');
        if (selectedCourseCookie)
            courseStructReload(parseInt(selectedCourseCookie), withoutLoad);
    };

    const handleWatchVideo = (topic, video) => {
        setWatchVideo({ ...video, topicId: topic.id, topic });
    };

    const renderUnitSelector = () => {
        return (
            <Segment basic style={{ ...userSegmentStyle, margin: 0, }}>
                <Menu vertical secondary fluid style={{ overflow: 'auto', maxHeight: '75vh' }}>
                    {
                        course.map((u, indx) => {
                            return <Menu.Item
                                key={u.id}
                                style={{ width: '95%', textAlign: isArabic ? 'right' : 'left', backgroundColor: currentUnit.id === u.id ? Theme.colors.PRIMARY_50 : Theme.colors.WHITE, color: currentUnit.id === u.id ? Theme.colors.WHITE : Theme.colors.BLACK }}
                                content={`${u.serialNo}- ${u.name}`}
                                onClick={() => handleCurrentUnit(indx)}
                                icon="chevron right"
                            />;
                        })
                    }
                </Menu>
            </Segment>
        );
    };

    const renderUnitsBar = () => {
        return (
            <div style={{ margin: '-2px 0px 10px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex' }}>
                    <Header style={f_16_700_text}>{isArabic ? `(${currentUnit.serialNo || "0"}) ${currentUnit.name}` : `${currentUnit.name} (${currentUnit.serialNo || "0"})`}</Header>
                </div>
                <div style={{ display: 'flex' }}>
                    <Header style={f_16_700_text}>{`${t('UnitComplition')} ${(currentUnit.userUnitProgressPercent)?.toFixed(2)}%`}</Header>
                </div>
            </div>
        );
    };

    const renderTopicAccordion = () => {
        return (
            <Segment basic style={noExtraSpaces}>
                <Accordion as={Menu} vertical fluid style={{ backgroundColor: "transparent", border: 'none', boxShadow: 'none' }} >
                    {
                        currentUnit.topics.filter(t => !(!t?.isExam && t?.books.length === 0 && t?.videos.length === 0 && t?.bankEssayQCount === 0 && t?.bankMcQCount === 0 && t?.bankCompositeQCount === 0 && t?.bankAFilesQCount === 0)).map((t, index) => <Menu.Item as={Segment} key={index} style={{ margin: 5, backgroundColor: Theme.colors.WHITE, borderRadius: 16, border: 'none', boxShadow: 'none' }}>
                            <Accordion.Title
                                active={activeIndex === index}
                                content={
                                    <Grid stackable columns='2' style={{ margin: 0, padding: 0 }}>
                                        <GridColumn mobile='16' computer='10' style={{ padding: 0 }} >
                                            <Header style={f_14_700_text} content={t.isExam ? t.name : isArabic ? `${t.serialNo || "0"}- ${t.name}` : `${t.serialNo || "0"}- ${t.name}`} />
                                        </GridColumn>
                                        <GridColumn mobile='16' computer='6' style={{ padding: 0 }} textAlign='right'>
                                            {t.isExam ? null : <Header style={f_14_700_text} content={`(${parseFloat(t.userTopicProgressPercent?.toFixed(2))}%)`} />}
                                        </GridColumn>
                                    </Grid>
                                }
                                index={index}
                                onClick={handleClick}
                            />
                            <Accordion.Content active={activeIndex === index} content={renderTopicDetails(t)} />
                        </Menu.Item>
                        )}
                </Accordion>
            </Segment>
        );
    };

    const renderTopicDetails = (topic) => {

        if (topic.isExam) {
            const isParentExam = topic?.childs?.length > 0;
            const attemptsCnt = topic?.attemptsCnt || 0;
            const userAttemptsCnt = topic?.childs?.at(topic.childs.length - 1)?.userAttemptsCnt || 0;
            const allowStartExam = userAttemptsCnt < attemptsCnt;
            return (
                <Segment basic>
                    <Grid columns='2' verticalAlign='middle' stackable>
                        <GridColumn width='14' style={noExtraSpaces}>
                            <Header
                                style={{ ...f_14_700_text, color: Theme.colors.PRIMARY_50, cursor: 'pointer' }}
                                content={t('FreqExam')}
                                subheader={`${topic?.childs?.length || 1} ${t('Part')} - (${t("AttemptsCount")}: ${userAttemptsCnt} / ${attemptsCnt})`}
                                icon='edit outline'
                                disabled={!allowStartExam}
                                onClick={() => allowStartExam && history.push(`/user/exam/${isParentExam ? topic.childs[0].id : topic.id}`)}
                            />
                        </GridColumn>
                        <GridColumn width='2' textAlign='right' style={noExtraSpaces}>
                            <Header style={{ ...f_14_700_text, color: Theme.colors.PRIMARY_50, margin: 0 }} content={`(${isParentExam ? (topic.childs.reduce((acc, e) => acc + (e?.userExamProgressPercent || 0), 0) / topic.childs.length).toFixed(2) : topic.userExamProgressPercent}%)`} />
                        </GridColumn>
                    </Grid>
                </Segment>
            );
        }

        const mcqsExam = resolveExam(Consts.EXAM_TYPES.TEST_MCQS, topic.id);
        const essayExam = resolveExam(Consts.EXAM_TYPES.TEST_ESSAY, topic.id);
        const tbsExam = resolveExam(Consts.EXAM_TYPES.TEST_TBS, topic.id);
        const fileExam = resolveExam(Consts.EXAM_TYPES.TEST_FILE, topic.id);
        const mcqsPrecentage = mcqsExam ? parseInt(mcqsExam.userExamProgressPercent) : 0;
        const essayPrecentage = essayExam ? parseInt(essayExam.userExamProgressPercent) : 0;
        const tbsPrecentage = tbsExam ? parseInt(tbsExam.userExamProgressPercent) : 0;
        const filePrecentage = fileExam ? parseInt(fileExam.userExamProgressPercent) : 0;

        return (
            <>
                {topic.videos.length ?
                    <>
                        <Segment basic>
                            <Grid columns='2' verticalAlign='middle' stackable>
                                {
                                    topic.videos.map(v => {
                                        const videoPercentage = v ? parseInt(v.userVideoProgressPercent) : 0;
                                        return <GridRow key={v.videoId}>
                                            <GridColumn width='14' style={noExtraSpaces}>
                                                <Header
                                                    style={{ ...f_14_700_text, color: Theme.colors.PRIMARY_50, cursor: 'pointer' }}
                                                    content={v.videoName || t('Video')}
                                                    icon='video'
                                                    disabled={!v}
                                                    onClick={() => handleWatchVideo(topic, v)}
                                                />
                                            </GridColumn>
                                            <GridColumn width='2' textAlign='right' style={noExtraSpaces}>
                                                <Header style={{ ...f_14_700_text, color: Theme.colors.PRIMARY_50, margin: 0 }} content={`(${videoPercentage}%)`} />
                                            </GridColumn>
                                        </GridRow>;
                                    })
                                }

                            </Grid>
                        </Segment>

                    </> : null}

                {topic.books.length ?
                    <>
                        <Segment basic>
                            <Grid columns='2' verticalAlign='middle' stackable>
                                {
                                    topic.books.map(b => {
                                        const bookPercentage = b ? parseInt(b.userBookProgressPercent) : 0;
                                        return <GridRow key={b.bookId}>
                                            <GridColumn width='14' style={noExtraSpaces}>
                                                <a href={b && b.url} rel='noreferrer' target='_blank'>
                                                    <Header
                                                        style={{ ...f_14_700_text, color: Theme.colors.PRIMARY_50, cursor: 'pointer' }}
                                                        content={b.name || t('Book')}
                                                        icon={b?.type === 'PDF' ? 'file pdf' : b?.type === "MS_WORD" ? "file word" : b?.type === "MS_EXCEL" ? "file excel" : b?.type === "MS_POWERPOINT" ? "file powerpoint" : "book"}
                                                        color={b?.type === 'PDF' ? 'red' : b?.type === "MS_WORD" ? "blue" : b?.type === "MS_EXCEL" ? "green" : b?.type === "MS_POWERPOINT" ? "orange" : "blue"}
                                                        disabled={!b}
                                                        onClick={() => onBookOpen(topic.id, b.bookId)}
                                                    />
                                                </a>
                                            </GridColumn>
                                            <GridColumn width='2' textAlign='right' style={noExtraSpaces}>
                                                <Header style={{ ...f_14_700_text, color: Theme.colors.PRIMARY_50, margin: 0 }} content={`(${bookPercentage}%)`} />
                                            </GridColumn>
                                        </GridRow>;
                                    })
                                }

                            </Grid>
                        </Segment>
                    </> : null}

                {topic.bankMcQCount && mcqsExam ?
                    <>
                        <Segment basic>
                            <Grid columns='2' verticalAlign='middle' stackable>
                                <GridColumn width='14' style={noExtraSpaces}>
                                    <Header
                                        style={{ ...f_14_700_text, color: Theme.colors.PRIMARY_50, cursor: 'pointer' }}
                                        content={t('MCQS')}
                                        subheader={`${topic.bankMcQCount} ${t('Questions')}`}
                                        icon='tasks'
                                        disabled={!topic.bankMcQCount}
                                        onClick={() => history.push(`/user/exam/${topic.id}/${Consts.EXAM_TYPES.TEST_MCQS}/${topic.bankMcQCount}`)}
                                    />
                                </GridColumn>
                                <GridColumn width='2' textAlign='right' style={noExtraSpaces}>
                                    <Header style={{ ...f_14_700_text, color: Theme.colors.PRIMARY_50, margin: 0 }} content={`(${mcqsPrecentage}%)`} />
                                </GridColumn>
                            </Grid>
                        </Segment>

                    </> : null}

                {topic.bankEssayQCount && essayExam ?
                    <>
                        <Segment basic>
                            <Grid columns='2' verticalAlign='middle' stackable>
                                <GridColumn width='14' style={noExtraSpaces}>
                                    <Header
                                        style={{ ...f_14_700_text, color: Theme.colors.PRIMARY_50, cursor: 'pointer' }}
                                        content={t('Essay')}
                                        subheader={`${topic.bankEssayQCount} ${t('Questions')}`}
                                        icon='edit outline'
                                        disabled={!topic.bankEssayQCount}
                                        onClick={() => history.push(`/user/exam/${topic.id}/${Consts.EXAM_TYPES.TEST_ESSAY}/${topic.bankEssayQCount}`)}
                                    />
                                </GridColumn>
                                <GridColumn width='2' textAlign='right' style={noExtraSpaces}>
                                    <Header style={{ ...f_14_700_text, color: Theme.colors.PRIMARY_50, margin: 0 }} content={`(${essayPrecentage}%)`} />
                                </GridColumn>
                            </Grid>
                        </Segment>
                    </> : null}

                {topic.bankCompositeQCount && tbsExam ?
                    <>
                        <Segment basic>
                            <Grid columns='2' verticalAlign='middle' stackable>
                                <GridColumn width='14' style={noExtraSpaces}>
                                    <Header
                                        style={{ ...f_14_700_text, color: Theme.colors.PRIMARY_50, cursor: 'pointer' }}
                                        content={t('TaskBasedSimulations')}
                                        subheader={`${topic.bankCompositeQCount} ${t('Tbs')}`}
                                        icon='sort amount down'
                                        disabled={!topic.bankCompositeQCount}
                                        onClick={() => history.push(`/user/exam/${topic.id}/${Consts.EXAM_TYPES.TEST_TBS}/${topic.bankCompositeQCount}`)}
                                    />
                                </GridColumn>
                                <GridColumn width='2' textAlign='right' style={noExtraSpaces}>
                                    <Header style={{ ...f_14_700_text, color: Theme.colors.PRIMARY_50, margin: 0 }} content={`(${tbsPrecentage}%)`} />
                                </GridColumn>
                            </Grid>
                        </Segment>
                    </> : null}

                {topic.bankAFilesQCount && fileExam ?
                    <>
                        <Segment basic>
                            <Grid columns='2' verticalAlign='middle' stackable>
                                <GridColumn width='14' style={noExtraSpaces}>
                                    <Header
                                        style={{ ...f_14_700_text, color: Theme.colors.PRIMARY_50, cursor: 'pointer' }}
                                        content={t('FileBasedSimulation')}
                                        subheader={`${topic.bankAFilesQCount} ${t('File')}`}
                                        icon='file excel outline'
                                        disabled={!topic.bankAFilesQCount}
                                        onClick={() => history.push(`/user/exam/${topic.id}/${Consts.EXAM_TYPES.TEST_FILE}/${topic.bankAFilesQCount}`)}
                                    />
                                </GridColumn>
                                <GridColumn width='2' textAlign='right' style={noExtraSpaces}>
                                    <Header style={{ ...f_14_700_text, color: Theme.colors.PRIMARY_50, margin: 0 }} content={`(${filePrecentage}%)`} />
                                </GridColumn>
                            </Grid>
                        </Segment>
                    </> : null}
            </>
        );
    };

    return (
        <>
            <Segment basic style={{ background: 'linear-gradient(0deg, #F3F4FB, #F3F4FB), #F3F4FB', borderRadius: 8 }}>
                <Grid columns={2} stackable stretched verticalAlign='top'>
                    <GridColumn width={6}>
                        <Header style={{ ...f_16_700_text, padding: 0 }}>{t('SelectUnit')}</Header>
                        {renderUnitSelector()}
                    </GridColumn>

                    <GridColumn width={10}>
                        {renderUnitsBar()}
                        {renderTopicAccordion()}
                    </GridColumn>
                </Grid>
            </Segment>

            <Modal
                onClose={() => setWatchVideo(null)}
                open={!!watchVideo}
                content={watchVideo && <VideoPlayer kind={Consts.VIDEOS_KINDS.TOPIC} videoDetails={watchVideo} topicId={watchVideo.topicId} onCourseProgress={onCourseProgress} onClose={() => setWatchVideo(null)} />}
                basic
                size='large'
                centered
                closeOnDimmerClick={false}
            />
        </>
    );
};

export default withTranslation()(CourseTree);