import React, { useEffect, createRef } from 'react';
import { Segment, Icon } from 'semantic-ui-react';
import i18next from 'i18next';
import ReactElasticCarousel, { consts } from 'react-elastic-carousel';
import { connect } from 'react-redux';
import { NewsCard } from '..';
import { Theme } from '../../res';
import { getAllNews } from '../../actions';

let resetTimeout;

const NewsList = (props) => {
    const { news, loading, error, getAllNews } = props;
    const carouselRef = createRef();

    useEffect(() => {
        if (!news.length)
            getAllNews();
        // eslint-disable-next-line
    }, []);


    const renderArrow = ({ type, onClick, isEdge }) => <Icon style={{ alignSelf: 'center', cursor: 'pointer', color: Theme.colors.SECONDARY_50 }} size='big' onClick={onClick} disabled={isEdge} name={type === consts.PREV ? 'chevron left' : 'chevron right'} />;
    const renderPagination = ({ pages, activePage, onClick }) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', gap: 5, marginTop: '2vh' }}>
                {pages.map(page => {
                    const isActivePage = activePage === page;
                    return (
                        <div
                            key={page}
                            onClick={() => onClick(page)}
                            style={{ cursor: 'pointer', height: 8, width: isActivePage ? 30 : 8, borderRadius: isActivePage ? 16 : '50%', backgroundColor: isActivePage ? Theme.colors.SECONDARY_50 : Theme.colors.WHITE }}
                        />
                    );
                })}
            </div>
        );
    };


    if (loading) return <Segment basic loading={loading} />;
    if (error) return null;

    return (
        <Segment basic>
            <ReactElasticCarousel
                ref={carouselRef}
                focusOnSelect
                isRTL={i18next.language === 'en' ? false : true}
                itemsToShow={1}
                enableAutoPlay
                autoPlaySpeed={5000}
                transitionMs={2000}
                renderArrow={renderArrow}
                renderPagination={renderPagination}
                style={{ borderRadius: 10 }}
                onNextEnd={() => {
                    const { activePage, pages } = carouselRef.current.state;
                    clearTimeout(resetTimeout);
                    if (activePage + 1 === pages.length) {
                        resetTimeout = setTimeout(() => {
                            carouselRef?.current?.goTo(0);
                        }, 3000);
                    }
                }}
            >
                {
                    news.map(n => <div key={n.id} style={{ borderRadius: 15 }}>
                        <NewsCard data={n} />
                    </div>)
                }
            </ReactElasticCarousel>
        </Segment>
    );
};

const mapStateToProps = ({ landingNews }) => {
    const { news, error, loading } = landingNews;
    return { news, error, loading };
};

export default connect(mapStateToProps, { getAllNews })(NewsList);