import React from 'react';
import { Button, Header, Container, Segment } from 'semantic-ui-react';
import Avatar from 'react-avatar';
import { withTranslation } from 'react-i18next';
import { HtmlEditor } from 'devextreme-react/html-editor';
import Separator from '../../../Operantinals/Separator';
import { Styles, Theme } from '../../../../res';
import alertImage from '../../../../res/images/alert.svg';

const { solidBtn, f_20_700_text, f_16_400_text, userSegmentStyle } = Styles;

const ResultCia = ({ t, onMoveToEssay, onGoingExam }) => {
    return (
        <Segment basic textAlign='center'>
            <Container style={{ ...userSegmentStyle, width: '30vw', padding: 20 }}>
                {Boolean(onGoingExam?.transitionTxt)
                    ? <div className='tw-my-5'>
                        <HtmlEditor value={onGoingExam?.transitionTxt} readOnly style={{ border: 'none', boxShadow: 'none' }} />
                    </div>
                    : <>
                        <Avatar size={100} round src={alertImage} />
                        <Header style={{ ...f_20_700_text, color: Theme.colors.PRIMARY_50 }} >{t('MoveToNextSectionText1')}</Header>
                        <Header style={f_16_400_text}>{t('MoveToNextSectionText3')}</Header>
                    </>}
                <Separator />
                <Button
                    fluid
                    content={t('ContinueMove').toUpperCase()}
                    style={solidBtn}
                    // loading={examStartingLoading}
                    onClick={onMoveToEssay}
                />
            </Container>
        </Segment>
    );
};

export default withTranslation()(ResultCia);