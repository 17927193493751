import React, { Suspense, useState, useEffect } from 'react';
import { Container, Icon, Modal } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { Sidebar, useProSidebar } from 'react-pro-sidebar';
import { useTranslation } from 'react-i18next';
import { Spinner, StudentSideBar, MessagesButton, PopupAd, ProfileForm } from '../components';
import { Theme } from '../res';
import StudentStack from '../Routers/StudentStack';

const StudentLayout = ({ user }) => {
    const { collapseSidebar, collapsed, toggleSidebar, broken, toggled } = useProSidebar();
    const isGoingExamRunning = useSelector(({ userExam }) => userExam.onGoingExam);
    const { i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const opened = ((broken && toggled) || (!broken && !collapsed));
    const [isCompleteProfileRequired, setIsCompleteProfileRequired] = useState(!user.contactNumber || !user.countryId);

    const openCloseSideBar = (status) => {
        if (broken) return toggleSidebar(status);
        if (!broken) return collapseSidebar();
    };

    useEffect(() => {
        const element1 = document.getElementById('gtagscirpt1');
        if (element1) {
            element1.remove();
        }

        const element2 = document.getElementById('gtagscirpt2');
        if (element2) {
            element2.remove();
        }

        // Create the script element
        var script = document.createElement('script');
        script.async = true;
        script.src = 'https://www.googletagmanager.com/gtag/js?id=G-PBDHKG6NYY';
        script.setAttribute("id", "gtagscirpt1");

        // Append the script element to the document head
        document.head.appendChild(script);

        // Create the inline script element
        var inlineScript = document.createElement('script');

        inlineScript.setAttribute("id", "gtagscirpt2");
        inlineScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-PBDHKG6NYY');
        `;

        // Append the inline script element to the document head
        document.head.appendChild(inlineScript);
    });

    return (
        <div style={{ display: 'flex', height: isGoingExamRunning ? '100%' : '100vh' }}>
            {
                isCompleteProfileRequired ?
                    <Modal
                        //onClose={() => setShowProfile(false)}
                        closeIcon={null}
                        open={isCompleteProfileRequired}
                        content={<ProfileForm isCompleteProfile={isCompleteProfileRequired} onClose={() => setIsCompleteProfileRequired(false)} />}
                        size='tiny'
                        centered
                        style={{ borderRadius: 16 }}
                    /> :
                    <PopupAd />
            }
            <MessagesButton user={user} showSidebar={null} />
            {!isGoingExamRunning && <Sidebar collapsedWidth="auto" breakPoint="md" rtl={isArabic}>
                <StudentSideBar user={user} opened={opened} openCloseSideBar={openCloseSideBar} />
            </Sidebar>}
            <main style={{ position: 'relative', display: "flex", flex: 'auto', backgroundColor: isGoingExamRunning ? Theme.colors.WHITE : Theme.colors.GREY_10, height: '100%', width: '100%' }}>
                {!isGoingExamRunning && <Icon link size='small' circular name={opened ? 'angle double left' : 'angle double right'} onClick={() => openCloseSideBar(!toggled)} style={{ backgroundColor: Theme.colors.WHITE, color: Theme.colors.BLACK, position: 'absolute', zIndex: 999, right: isArabic ? '-1%' : undefined, left: isArabic ? undefined : '-1%', top: '2%' }} />}
                <div style={{ overflow: 'auto', width: '100%', padding: 0 }}>
                    <Suspense fallback={<Spinner />}>
                        <div >
                            <StudentStack />
                        </div>
                    </Suspense>
                </div>
            </main>
        </div >
    );
};

export default StudentLayout;