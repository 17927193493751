import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Segment, Dropdown, Input } from 'semantic-ui-react';


const SelectorQuestionTemplate = ({ sectionId, options, userAnswer, onUserAnswerSection, isViewOnly, isSubmited }) => {
    const { i18n, t } = useTranslation();
    const isArabic = i18n.language === "ar";
    const [selectedOption, setSelectedOption] = useState(userAnswer?.selectedOption?.__KEY__ || null);
    const [fields, setFields] = useState(userAnswer?.fields || []);

    const onSelectOption = (value) => {
        const fCount = options.find(op => op.__KEY__ === value)?.fieldsCount || 0;
        const newFields = Array(fCount).fill("");
        setSelectedOption(value);
        setFields(newFields);
        if (!isViewOnly || !isSubmited)
            onUserAnswerSection({
                sectionId,
                selectedOption: options.find(op => op.__KEY__ === value),
                fields: newFields
            });
    };

    return (
        <Segment basic style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10, flexWrap: 'wrap' }}>
            <Dropdown
                options={options.map((op, indx) => ({ key: indx, text: isArabic ? op.nameAr : op.nameEn, value: op.__KEY__ }))}
                selection
                placeholder={t('Choose')}
                value={selectedOption}
                onChange={(e, { value }) => onSelectOption(value)}
                compact
                disabled={isViewOnly}
            />
            {
                fields.map((f, i) => <Input
                    key={i}
                    value={f}
                    placeholder="123.."
                    style={{ width: '10%' }}
                    disabled={isViewOnly}
                    onChange={(e, { value }) => {
                        const temp = [...fields];
                        temp.splice(i, 1, value);
                        setFields(temp);
                        if (!isViewOnly || !isSubmited)
                            onUserAnswerSection({
                                sectionId,
                                selectedOption: options.find(op => op.__KEY__ === selectedOption),
                                fields: temp
                            });
                    }}
                />)
            }
        </Segment>
    );
};


export default SelectorQuestionTemplate;