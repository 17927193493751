import React, { useState } from 'react';
import { Checkbox, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';


const CheckBoxQuestionTemplate = ({ sectionId, checks, userAnswer, onUserAnswerSection, isViewOnly, isSubmited }) => {
    const { i18n } = useTranslation();
    const isArabic = i18n.language === "ar";
    const [selectedOptions, setSelectedOption] = useState(userAnswer?.selectedOptions || []);

    const toggleCheck = (option) => {
        const temp = [...selectedOptions];
        if (temp.find(e => e === option.__KEY__)) {
            setSelectedOption(temp.filter(c => c !== option.__KEY__));
        } else setSelectedOption([...temp, option.__KEY__]);

        onUserAnswerSection({ sectionId, selectedOptions: temp });
    };

    return (
        <Segment basic>
            {
                checks.map((c, i) => <div key={i}>
                    <Checkbox
                        label={{ children: isArabic ? c.nameAr : c.nameEn }}
                        checked={selectedOptions.find(e => e === c.__KEY__)}
                        onChange={(e) => (!isViewOnly || !isSubmited) && toggleCheck(c)}
                        disabled={isViewOnly || isSubmited}
                    />
                </div>)
            }
        </Segment>
    );
};


export default CheckBoxQuestionTemplate;