import React, { useState } from 'react';
import { Dropdown, Form, Icon, Message, Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { profileInfoChanged, changeProfile } from '../../actions';
import { ProfileImageUploader, Separator } from '../../components';
import { Styles } from '../../res';

import avatarImage from '../../res/images/avatar.svg';

const { solidBtn, noExtraSpaces } = Styles;

const ProfileForm = ({ onClose, t, user, loading, error, options, profileInfoChanged, changeProfile, isCompleteProfile = false }) => {
    const [email, /*setEmail*/] = useState(user.email);
    const [firstName, setFirstName] = useState(user.firstName);
    const [lastName, setLastName] = useState(user.lastName);
    const [country, setCountry] = useState(user.countryId);
    const [mobile, setMobile] = useState(user.contactNumber);
    const [imageId, setImageId] = useState(user.coverImgUrl);
    const [gender, setGender] = useState(user.gender);

    const genderOptions = [
        { key: 'm', text: t('Male'), value: 'Male' },
        { key: 'f', text: t('Female'), value: 'Female' },
    ];

    const setError = (value) => profileInfoChanged({ props: 'error', value });

    const validate = () => {
        if (!firstName) {
            setError({ ...error, firstName: t('Required') });
            return false;
        }
        if (!lastName) {
            setError({ ...error, lastName: t('Required') });
            return false;
        }
        if (!gender) {
            setError({ ...error, gender: t('Required') });
            return false;
        }
        if (!country) {
            setError({ ...error, country: t('Required') });
            return false;
        }
        if (!mobile) {
            setError({ ...error, mobile: t('Required') });
            return false;
        }
        const validPhone = /[0-9]{8,}$/;
        if (!validPhone.test(mobile)) {
            setError({ ...error, mobile: t('MobileError') });
            return false;
        }
        if (!email) {
            setError({ ...error, email: t('Required') });
            return false;
        }
        const validEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!validEmail.test(email)) {
            setError({ ...error, email: t('EmailError') });
            return false;
        }
        setError('');
        return true;
    };
    const onSavePressed = () => {
        if (validate()) {
            changeProfile({ firstName, lastName, gender, email, mobile, country, imageId });
            if (onClose) onClose();
        }
    };
    const renderErrorMsg = () => {
        if (!error) return;
        return <Message negative icon='x' header={t('Error')} content={error} />;
    };
    if (!user || !options.length) return <Segment basic loading placeholder />;
    return (
        <Segment basic clearing>
            {
                !isCompleteProfile &&
                <Segment basic textAlign='right' style={noExtraSpaces}>
                    <Icon link size='large' name='times' onClick={onClose} />
                </Segment>
            }
            {renderErrorMsg()}
            <Form loading={loading}>
                <ProfileImageUploader isShort Url='/user/profile/img/upload' data={{ value: user.coverImgUrl || avatarImage, setValue: setImageId }} />

                <Form.Input
                    fluid
                    type='text'
                    label={t('FirstName')}
                    placeholder={t('John')}
                    required
                    maxLength={20}
                    size='big'
                    value={firstName}
                    onChange={(e, { value }) => setFirstName(value)}
                    onFocus={() => setError('')}
                    error={error.firstName}
                    disabled={!isCompleteProfile}
                />
                <Form.Input
                    fluid
                    type='text'
                    label={t('LastName')}
                    placeholder={t('Doe')}
                    required
                    maxLength={20}
                    value={lastName}
                    size='big'
                    onChange={(e, { value }) => setLastName(value)}
                    onFocus={() => setError('')}
                    error={error.lastName}
                    disabled={!isCompleteProfile}
                />

                <Form.Field
                    fluid
                    label={t('Gender')}
                    control={Dropdown}
                    options={genderOptions}
                    placeholder={t('Gender')}
                    required
                    value={gender}
                    onChange={(e, { value }) => setGender(value)}
                    onFocus={() => setError('')}
                    error={error.gender}
                    size='big'
                    selection
                    search
                />

                <Form.Field
                    fluid
                    required
                    label={t('Country')}
                    options={options}
                    control={Dropdown}
                    onChange={(e, { value }) => setCountry(value)}
                    noResultsMessage={t('NotFound')}
                    placeholder={t('Country')}
                    search
                    selection
                    disabled={!isCompleteProfile}
                    defaultValue={country ? options.find(c => c.value === country).value : null}
                    error={error.country}
                    onFocus={() => setError('')}
                    size='big'
                />
                <Form.Group>
                    <Form.Input
                        width='4'
                        label={t('Code')}
                        value={country ? options.find(c => c.value === country).code : null}
                        required
                        disabled={!isCompleteProfile}
                        size='big'
                    />
                    <Form.Input
                        width='12'
                        label={t('Mobile')}
                        value={mobile}
                        required
                        disabled={!isCompleteProfile}
                        onChange={(e, { value }) => setMobile(value)}
                        type='phone'
                        placeholder='xxx-xxx-xxx'
                        maxLength={15}
                        size='big'
                        onFocus={() => setError('')}
                        error={error.mobile}
                    />
                </Form.Group>

                {/* <Form.Input
                    fluid
                    type='email'
                    label={t('Email')}
                    placeholder='example@ex.com'
                    required
                    size='big'
                    maxLength={50}
                    value={email}
                    onChange={(e, { value }) => setEmail(value.toLowerCase())}
                    onFocus={() => setError('')}
                    error={error.email}
                    disabled
                /> */}
                <Separator h='3vh' />
                <Form.Button floated='right' onClick={onSavePressed} style={{ ...solidBtn }}>{t('Save').toUpperCase()}</Form.Button>
            </Form>
        </Segment >
    );
};

const mapStateToProps = ({ auth, countries, profile }) => {
    const { user } = auth;
    const { countriesData: options } = countries;
    const { error, loading } = profile;
    return { user, options, error, loading };
};

export default withTranslation()(connect(mapStateToProps, { profileInfoChanged, changeProfile })(ProfileForm));