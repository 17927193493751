import React from 'react';
import PageSocpa from './PageSocpa';
import PageCma from './PageCma';
import PageCia from './PageCia';
import PageCpa from './PageCpa';
import PageDifr from './PageDifr';
import { Consts } from '../../../../res';

const Page = (props) => {

    const renderPage = () => {
        switch (props.theme) {
            case Consts.EXAM_THEMES.SOCPA:
                return <PageSocpa {...props} />;
            case Consts.EXAM_THEMES.CMA:
                return <PageCma {...props} />;
            case Consts.EXAM_THEMES.CIA:
                return <PageCia {...props} />;
            case Consts.EXAM_THEMES.CPA:
                return <PageCpa {...props} />;
            case Consts.EXAM_THEMES.DIFR:
                return <PageDifr {...props} />;
            default:
                return <PageSocpa {...props} />;
        }
    };

    return renderPage();
};

export default Page;