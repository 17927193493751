import React, { useState } from 'react';
import { Segment, Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ErrorSegment } from '../../components';
import { Consts } from '../../res';
import request from '../../api';

const ReportForm = ({ reportOn, id, reportLink, showModal }) => {
    const { t } = useTranslation();
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const { selectedCourse } = useSelector(state => state.userCourses);

    const generateLink = () => {
        const { videoKind, questionTypeId, questionText, factTitle, topic, questionRank } = reportLink;
        switch (videoKind) {
            case Consts.VIDEOS_KINDS.EXPLANATION:
                return `${"Explanation"} || ${selectedCourse.name} || ${questionTypeId} || ${factTitle ? factTitle + " || " : ""}${questionText} || (#${questionRank})`;
            case Consts.VIDEOS_KINDS.TOPIC:
                return `${"Topic"} || ${selectedCourse} || ${topic.name} (${topic.rank}) || (#${id})`;
            default:
                return `${questionTypeId} || ${questionRank} || ${factTitle ? factTitle + " || " : ""}${questionText}`;
        }

    };

    const postIssue = async () => {
        if (!title || !content) return;
        setLoading(true);
        try {
            const data = { reportOn, content, title, link: generateLink() };
            if (reportOn === Consts.REPORTS_TYPES.VIDEO) 
                data['videoId'] = id;
            else 
                data['questionId'] = id;

            if (reportLink.questionId)
                data['questionId'] = reportLink.questionId;

            if (reportLink.questionRank)
                data['questionRank'] = reportLink.questionRank;
            
            await request.post('/student/issue_report', data);
            showModal(false);
            toast.info(t('ThanksForReportingIssue'), { position: toast.POSITION.BOTTOM_CENTER });
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    if (error) return <ErrorSegment />;

    return (
        <Segment basic>
            <Form>
                <Form.Input
                    fluid
                    label={t('Title')}
                    required
                    value={title}
                    onChange={(e, { value }) => setTitle(value)}
                />
                <Form.TextArea
                    label={t('Description')}
                    required
                    value={content}
                    onChange={(e, { value }) => setContent(value)}
                />
                <Form.Button positive fluid loading={loading} onClick={postIssue}>{t('Send')}</Form.Button>
            </Form>
        </Segment>
    );
};

export default ReportForm;