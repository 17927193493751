import React from 'react';
import ResultSocpa from './ResultSocpa';
import ResultCma from './ResultCma';
import ResultCia from './ResultCia';
import ResultCpa from './ResultCpa';
import { Consts } from '../../../../res';
import ResultDefault from './ResultDefault';


const MockResultTemplate = (props) => {
    const renderResult = () => {
        if (props.onGoingExam.examType === Consts.EXAM_TYPES.FREQ && !props.onGoingExam.isMock)
            return <ResultDefault {...props} />;
        switch (props.onGoingExam.theme) {
            case Consts.EXAM_THEMES.SOCPA:
                return <ResultSocpa {...props} />;
            case Consts.EXAM_THEMES.CMA:
                return <ResultCma {...props} />;
            case Consts.EXAM_THEMES.CIA:
                return <ResultCia {...props} />;
            case Consts.EXAM_THEMES.CPA:
                return <ResultCpa {...props} />;

            default:
                return <ResultDefault {...props} />;
        }
    };
    return (
        <div>
            {renderResult()}
        </div>

    );
};

export default MockResultTemplate;
