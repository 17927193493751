import React from 'react';
import { Header, Segment } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

const EssayResult = ({ t }) => {
    return (
        <Segment basic>
            <Header as='h1' textAlign='center'>
                {t('YourExamHasFinished').toUpperCase()}
            </Header>
        </Segment>
    );
};

export default withTranslation()(EssayResult);