import React, { useEffect } from 'react';
import { Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import './calculator.css';
import { useDetectClickOutside } from 'react-detect-click-outside';
const basicMath = require('advanced-calculator');

const operators = ['+', '-', '*', '/', '%', '^', '√', ')', '('];
const numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

const Calculator = () => {
    const { t } = useTranslation();
    let eq = [];
    let res = 0;
    let history;
    let box = document.getElementById("box");
    let last_operation_history = document.getElementById("last_operation_history");

    const calcRef = useDetectClickOutside({
        onTriggered: () => {
            document.onkeydown = undefined;
            document.getElementById('calculator').style.backgroundColor
                = document.getElementById("box").style.backgroundColor
                = document.getElementById("last_operation_history").style.backgroundColor
                = "#666";
        },
        disableKeys: true
    });

    useEffect(() => {
        document.onkeydown = keyPressed;
        box = document.getElementById("box");
        last_operation_history = document.getElementById("last_operation_history");

        return () => {
            document.onkeydown = undefined;
        };

        //eslint-disable-next-line
    }, []);

    // function to capture keydown events
    function keyPressed(e) {
        e.preventDefault();
        if (e.key === "Delete") return deleteLastItem();
        if (e.key === "Enter") return calc();
        if (e.key === "^") return pow();
        if (e.key === "%") return percent();
        if (e.key === "." || e.key === ",") return addNumber('.');
        if (operators.slice(0, 4).includes(e.key)) return addOp(e.key);
        if (numbers.includes(e.key)) return addNumber(e.key);
    }

    const addNumber = (num) => {
        eq = [...eq, `${num}`];
        box.innerText = eq?.join('');
    };

    const addOp = (op) => {
        if (eq.length === 0) return;
        if (operators.includes(op) && operators.includes(eq[eq.length - 1])) deleteLastItem();
        eq = [...eq, op];
        box.innerText = eq.join('');
    };

    const deleteLastItem = () => {
        eq = eq.slice(0, eq.length - 1);
        box.innerText = eq.join('');
    };

    const lastNum = () => {
        let lastN = [];
        for (let i = eq.length - 1; i >= 0; i--) {
            if (!operators.includes(eq[i])) {
                lastN = [...lastN, eq[i]];
            } else return { num: lastN.reverse().join(''), indx: i + 1 };
        }
        return { num: lastN.reverse().join(''), indx: 0 };
    };

    const clear = () => {
        eq = [];
        box.innerText = 0;
    };

    const resign = () => {
        const lastNumObj = lastNum();
        eq = eq.slice(0, lastNumObj.indx);
        addNumber(`(-${lastNumObj.num})`);
    };

    const inverse = () => {
        const lastNumObj = lastNum();
        eq = eq.slice(0, lastNumObj.indx);
        addNumber(`(1/${lastNumObj.num})`);
    };

    const pow = () => {
        const lastNumObj = lastNum();
        eq = eq.slice(0, lastNumObj.indx);
        addNumber(`(${lastNumObj.num}^2)`);
    };

    const sqrt = () => {
        const lastNumObj = lastNum();
        eq = eq.slice(0, lastNumObj.indx);
        addNumber(`(${lastNumObj.num}^0.5)`);
    };

    const percent = () => {
        const lastNumObj = lastNum();
        eq = eq.slice(0, lastNumObj.indx);
        addNumber(`(${lastNumObj.num}/100)`);
    };

    const calc = () => {
        try {
            if (operators.slice(0, 4).includes(eq[eq.length - 1])) eq.pop();
            res = Math.floor(basicMath.evaluate(`${eq.join('')}`) * 100000) / 100000;
            history = (`${eq.join('')}`);
            clear();
            addNumber(res);
            last_operation_history.innerText = history;
        } catch (error) {
            clear();
        }

    };

    return (
        <div id="calculator" ref={calcRef} onClick={() => {
            document.onkeydown = keyPressed;
            document.getElementById('calculator').style.backgroundColor
                = document.getElementById("box").style.backgroundColor
                = document.getElementById("last_operation_history").style.backgroundColor
                = "#FAF9F6";
        }}>
            <div
                className='tw-flex tw-py-1 tw-px-2 tw-rounded tw-cursor-pointer hover:tw-bg-[#999] tw-bg-[#666] tw-text-white tw-flex-row tw-gap-2 tw-items-center'
                onClick={() => {
                    box = document.getElementById("box");
                    navigator.clipboard.writeText(box.innerText);
                }}>

                <div>
                    <Icon color='white' name='copy outline' />
                </div>
                <div>
                    <p>{t("Copy")}</p>
                </div>
            </div>

            <p id="last_operation_history"></p>
            <p id="box" className="box">0</p>
            <table id="table">
                <tbody>
                    <tr>
                        <td><button onClick={() => percent()}>%</button></td>
                        <td><button onClick={() => clear('')}>CE</button></td>
                        <td><button onClick={() => deleteLastItem()}>C</button></td>
                        <td><button id="backspace_btn" onClick={() => deleteLastItem()}></button></td>
                    </tr>
                    <tr>
                        <td><button onClick={() => inverse()}>¹∕ₓ</button></td>
                        <td><button onClick={() => pow()}>𝑥²</button></td>
                        <td><button onClick={() => sqrt()}>√𝑥</button></td>
                        <td><button id="plusOp" value="+" className="operator" onClick={() => addOp('+')}>+</button></td>
                    </tr>
                    <tr>
                        <td><button onClick={() => addNumber(7)}>7</button></td>
                        <td><button onClick={() => addNumber(8)}>8</button></td>
                        <td><button onClick={() => addNumber(9)}>9</button></td>
                        <td><button id="subOp" value="-" className="operator" onClick={() => addOp('-')}>-</button></td>
                    </tr>
                    <tr>
                        <td><button onClick={() => addNumber(4)}>4</button></td>
                        <td><button onClick={() => addNumber(5)}>5</button></td>
                        <td><button onClick={() => addNumber(6)}>6</button></td>
                        <td><button id="multiOp" value="*" className="operator" onClick={() => addOp('*')}>×</button></td>
                    </tr>
                    <tr>
                        <td><button onClick={() => addNumber(1)}>1</button></td>
                        <td><button onClick={() => addNumber(2)}>2</button></td>
                        <td><button onClick={() => addNumber(3)}>3</button></td>
                        <td><button id="divOp" value="/" className="operator" onClick={() => addOp('/')}>÷</button></td>
                    </tr>
                    <tr>
                        <td><button onClick={() => resign()}>±</button></td>
                        <td><button onClick={() => addNumber(0)}>0</button></td>
                        <td><button id="dot" value="." onClick={() => addNumber('.')}>.</button></td>
                        <td colSpan="4"><button value="=" className="operator" id="equal_sign" onClick={() => calc()}>=</button></td>
                    </tr>
                </tbody>
            </table>
        </div>
    );



};

export default Calculator;