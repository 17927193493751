import {
    LANDING_CERTIFICATIONS_INFO_CHANGED,
    LANDING_CERTIFICATIONS_OPERATION_START,
    LANDING_CERTIFICATIONS_OPERATION_FINISH,
    LANDING_CERTIFICATIONS_OPERATION_FAIL,
    LANDING_GET_ALL_CERTIFICATIONS,
    LANDING_CLEAR_CERTIFICATIONS_DATA
} from '../../actions/types';

const INITIAL_STATE = {
    certs: [],
    error: '',
    loading: false,
};


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LANDING_CERTIFICATIONS_INFO_CHANGED:
            return { ...state, [action.payload.props]: action.payload.value };
        case LANDING_CERTIFICATIONS_OPERATION_START:
            return { ...state, loading: true };
        case LANDING_CERTIFICATIONS_OPERATION_FINISH:
            return { ...state, loading: false };
        case LANDING_CERTIFICATIONS_OPERATION_FAIL:
            return { ...state, error: { [action.payload.props]: action.payload.value } };
        case LANDING_GET_ALL_CERTIFICATIONS:
            return { ...state, certs: action.payload };
        case LANDING_CLEAR_CERTIFICATIONS_DATA:
            return { ...INITIAL_STATE };
        default:
            return state;
    }
};

