import React from 'react';
import TextSection from './TextSection';
import SelectorSection from './SelectorSection';
import ParagraphSection from './ParagraphSection';
import TableSection from './TableSections';
import CheckBoxexSection from './CheckboxesSections';

import { Consts } from '../../../res';

const QuestionSection = (props) => {

    switch (props.data.type) {
        case Consts.QUESTION_BUILDER_SECTIONS_TYPES.TEXT_SECTION:
            return <TextSection  {...props} />;

        case Consts.QUESTION_BUILDER_SECTIONS_TYPES.SELECTOR_SECTION:
            return <SelectorSection {...props} />;

        case Consts.QUESTION_BUILDER_SECTIONS_TYPES.PARAGRAPH_SECTION:
            return <ParagraphSection{...props} />;

        case Consts.QUESTION_BUILDER_SECTIONS_TYPES.TABLE_SECTION:
            return <TableSection {...props} />;

        case Consts.QUESTION_BUILDER_SECTIONS_TYPES.CHECKBOXES_SECTION:
            return <CheckBoxexSection {...props} />;
        default:
            return null;
    }
};

export default QuestionSection;