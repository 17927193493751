import i18n from 'i18next';
import {
    SET_NOTIFICATIONS_SEEN,
    GET_NOTIFICATION_TOKEN,
    GET_ALL_NOTIFICATIONS_OPERATION_START,
    GET_ALL_NOTIFICATIONS_OPERATION_FAIL,
    GET_ALL_NOTIFICATIONS_OPERATION_FINISH,
    GET_ALL_NOTIFICATIONS_SUCCESS
} from '../types';
import request from '../../api';

export const setNotificationsSeen = (ids) => {
    return { type: SET_NOTIFICATIONS_SEEN, payload: ids };
};

export const saveNotificationToken = (token) => {
    return { type: GET_NOTIFICATION_TOKEN, payload: token };
};

export const getAllNotifications = () => async dispatch => {
    dispatch({ type: GET_ALL_NOTIFICATIONS_OPERATION_START });
    try {
        const { data: { data } } = await request.get('/user/notifications');
        dispatch({ type: GET_ALL_NOTIFICATIONS_SUCCESS, payload: data });
    } catch (err) {
        if (err.response) dispatch({ type: GET_ALL_NOTIFICATIONS_OPERATION_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: GET_ALL_NOTIFICATIONS_OPERATION_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    } finally {
        dispatch({ type: GET_ALL_NOTIFICATIONS_OPERATION_FINISH });
    }
};
