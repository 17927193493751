import cookies from 'react-cookies';
import i18n from 'i18next';
import {
    USER_COURSES_INFO_CHANGED,
    USER_COURSES_OPERATION_START,
    USER_COURSES_OPERATION_FINISH,
    USER_COURSES_OPERATION_FAIL,
    USER_GET_ALL_COURSES,
    USER_SELECT_COURSE,
    USER_GET_COURSE_STRUCT,
    USER_GET_COURSE_STRUCT_START,
    USER_GET_COURSE_STRUCT_FAIL,
    USER_GET_COURSE_STRUCT_FINISH,
    USER_CLEAR_COURSES_DATA,
} from '../types';

import request from '../../api';

export const userCoursesInfoChange = ({ props, value }) => {
    return { type: USER_COURSES_INFO_CHANGED, payload: { props, value } };
};

export const userGetAllCourses = () => async dispatch => {
    dispatch({ type: USER_COURSES_OPERATION_START });
    try {
        const { data: { data } } = await request.get('/student/courses/subscribed');
        dispatch({ type: USER_GET_ALL_COURSES, payload: data });
        return data;
    } catch (err) {
        if (err.response) dispatch({ type: USER_COURSES_OPERATION_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: USER_COURSES_OPERATION_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    } finally {
        dispatch({ type: USER_COURSES_OPERATION_FINISH });
    }
};

export const userSelectCourse = (course) => {
    const selectedCourseCookie = cookies.load('coursePref');
    if (selectedCourseCookie) cookies.remove('coursePref');
    cookies.save('coursePref', course.id, {
        path: '/',
        expires: new Date().setDate(Date.now() + 1000 * 60 * 60 * 24 * 14),
        maxAge: 60 * 60 * 24 * 365
    });
    return { type: USER_SELECT_COURSE, payload: course };
};

export const userGetSelectedCourseStruct = (courseId, withoutLoad) => async dispatch => {
    !withoutLoad && dispatch({ type: USER_GET_COURSE_STRUCT_START });
    try {
        const promise1 = request.get(`/student/course/${courseId}/content`);
        const promise2 = request.get(`/student/exams/subscribed/course/${courseId}?mustParentChild=true`);
        const data = await Promise.all([promise1, promise2]);
        const courseStruct = data[0].data.data;
        const allowedExams = data[1].data.data;
        dispatch({ type: USER_GET_COURSE_STRUCT, payload: { courseStruct, allowedExams } });
    } catch (err) {
        if (err.response) dispatch({ type: USER_GET_COURSE_STRUCT_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: USER_GET_COURSE_STRUCT_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    } finally {
        dispatch({ type: USER_GET_COURSE_STRUCT_FINISH });
    }
};

export const userClearCoursesData = () => {
    return { type: USER_CLEAR_COURSES_DATA };
};



