import React from 'react';
import { Form, Radio } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

const SingleCol = ({ col, onChange, index, t, }) => {
    return (
        <Form.Group widths='equal'>
            <Form.Input
                fluid
                type='text'
                placeholder={t('EnglishColumnName')}
                required
                icon='columns'
                iconPosition='left'
                value={col.colNameEn}
                onChange={(e, { value }) => onChange({ colNameEn: value, colNameAr: col.colNameAr }, index)}
            />
            <Form.Input
                fluid
                type='text'
                placeholder={t('ArabicColumnName')}
                required
                icon='columns'
                iconPosition='left'
                value={col.colNameAr}
                onChange={(e, { value }) => onChange({ colNameEn: col.colNameEn, colNameAr: value }, index)}
            />
        </Form.Group>
    );
};


const ColumnsBatchEditor = ({ t, columns, setColumns }) => {

    const onChangeCol = (value, index) => {
        const newCols = [...columns];
        newCols[index].colNameEn = value.colNameEn;
        newCols[index].colNameAr = value.colNameAr;
        setColumns(newCols);
    };

    const reCreateColumns = (cols) => {
        if (cols === 1)
            return setColumns([{ colNameEn: 'Answer', colNameAr: 'الجواب' }]);

        let colsArr = [];
        for (let i = 0; i < cols; i++)
            colsArr = [...colsArr, { colNameEn: `Column ${i + 1}`, colNameAr: `عمود ${i + 1}` }];
        return setColumns(colsArr);
    };

    return (
        <>
            <Form.Group inline>
                <Form.Field
                    control={Radio}
                    label={'One Column'}
                    value={1}
                    checked={columns.length === 1}
                    onChange={(e, { value }) => reCreateColumns(value)}
                />
                <Form.Field
                    control={Radio}
                    label={'Two Columns'}
                    value={2}
                    checked={columns.length === 2}
                    onChange={(e, { value }) => reCreateColumns(value)}
                />
                <Form.Field
                    control={Radio}
                    label={'Three Columns'}
                    value={3}
                    checked={columns.length === 3}
                    onChange={(e, { value }) => reCreateColumns(value)}
                />
            </Form.Group>

            {
                columns.map((c, index) => <SingleCol
                    key={index}
                    col={c}
                    index={index}
                    onChange={onChangeCol}
                    t={t}
                />)
            }
        </>
    );
};

export default withTranslation()(ColumnsBatchEditor);
