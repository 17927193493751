import React, { useState } from 'react';
import { Container, Form, FormCheckbox, FormGroup, Icon, Segment, Table } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { Consts, Theme } from '../../../res';

const filterTypes = {
    UNATTEMPTED: "UNATTEMPTED",
    ATTEMPTED: "ATTEMPTED",
    FLAGGED: "FLAGGED"
};

const ExamSummary = ({ questions, onSelectQuestion, onFlagQuestion, summary, t, isMockExam, theme }) => {

    const [filters, setFilters] = useState([]);

    const printQuestionState = (q) => {
        if (!q.answerText && !q.answerOptionId && !q.answerJson) return t('NotAnsweredQuestion');
        if (q.answerOptionId && q.correctAnswer && !isMockExam && q.correctAnswer.correctAnswerOptionId === q.answerOptionId)
            return t('CorrectAnswer');
        else if (q.answerOptionId && q.correctAnswer && !isMockExam && q.correctAnswer.correctAnswerOptionId !== q.answerOptionId)
            return t('WrongAnswer');
        else if (q.answerOptionId && !q.correctAnswer && !isMockExam)
            return t('ClickToLoadQuestion');
        return t('Answered');
    };

    const handleChange = (value) => {
        if (filters.includes(value)) return setFilters(prev => prev.filter(f => f !== value));
        return setFilters(prev => [...prev, value]);
    };

    return (
        <Segment basic style={{overflowY: 'scroll', maxHeight: '70vh'}}>
            <Container style={{ width: '80vw' }}>

                <Form>
                    <FormGroup inline>
                        <label>{t("FilterBy")}</label>
                        <FormCheckbox
                            label={t("Unattempted")}
                            value={filterTypes.UNATTEMPTED}
                            checked={filters.includes(filterTypes.UNATTEMPTED)}
                            onChange={() => handleChange(filterTypes.UNATTEMPTED)}
                        />
                        <FormCheckbox
                            label={t("Attempted")}
                            value={filterTypes.ATTEMPTED}
                            checked={filters.includes(filterTypes.ATTEMPTED)}
                            onChange={() => handleChange(filterTypes.ATTEMPTED)}
                        />
                        <FormCheckbox
                            label={t("Flagged")}
                            value={filterTypes.FLAGGED}
                            checked={filters.includes(filterTypes.FLAGGED)}
                            onChange={() => handleChange(filterTypes.FLAGGED)}
                        />
                        {filters.length > 0 && <label className='tw-cursor-pointer' onClick={() => setFilters([])}>{t("Clear")}</label>}
                    </FormGroup>
                </Form>

                <Table celled unstackable compact>
                    <Table.Body>
                        {
                            questions.filter(q => !(q?.showFactOnly)).map((q, indx) => {
                                const isFlagged = q.isFlagged;
                                const isAttempted = q?.answerOptionId || q?.answerText || q?.answerJson;
                                const isUnAttempted = !isAttempted;
                                const isWrongAnswerd = q?.answerOptionId && q?.correctAnswer && !isMockExam && q.correctAnswer.correctAnswerOptionId !== q.answerOptionId;

                                if (
                                    filters.length === 0
                                    || (filters.includes(filterTypes.FLAGGED) && isFlagged)
                                    || (filters.includes(filterTypes.ATTEMPTED) && isAttempted)
                                    || (filters.includes(filterTypes.UNATTEMPTED) && isUnAttempted)
                                )
                                    return <Table.Row
                                        key={q.questionId}
                                        style={{ cursor: 'pointer', color: isWrongAnswerd ? Theme.colors.ERROR_50 : (isAttempted && Theme.colors.SECONDARY_50) }}
                                    >
                                        <Table.Cell collapsing>
                                            <Icon link name='flag' onClick={() => onFlagQuestion(q)} style={{ color: q.isFlagged ? theme === Consts.EXAM_THEMES.CMA ? '#8CC63F' : '#639FD2' : '#ddd' }} />
                                        </Table.Cell>

                                        <Table.Cell
                                            onClick={() => {
                                                onSelectQuestion(q);
                                                if (window.ExamNav)
                                                    window.ExamNav.goTo(Number(indx));
                                                summary.setShowSummary(false);
                                            }}
                                        >
                                            {`${t('Question')} ${indx + 1}`}
                                        </Table.Cell>

                                        <Table.Cell >
                                            {printQuestionState(q)}
                                        </Table.Cell>
                                    </Table.Row>;
                                else return null;
                            })
                        }
                    </Table.Body>
                </Table>
            </Container>
        </Segment>
    );
};

export default withTranslation()(ExamSummary);