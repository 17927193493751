import React from 'react';
import { withTranslation } from 'react-i18next';
import { HtmlEditor, TableContextMenu, TableResizing } from 'devextreme-react/html-editor';
import { Button, Divider, Header, List, Segment, Table, Image, Grid, GridColumn } from 'semantic-ui-react';
import { Consts } from '../../../../res';

const McqsQuestionTemplate = ({ Q, onAnswerQuestion, t, isViewOnly, onHideSomeAnswers, onExcludeOption }) => {
    const studentAnswer = Q.questionTypeId === Consts.QUESTIONS_TYPES.MCQS ? Q.answerOptionId : Q.answerText;
    const correctAnswerOptionId = Q.correctAnswer.correctAnswerOptionId;
    const showAnswers = !!studentAnswer || isViewOnly;
    const alphArray = [t('A'), t('B'), t('C'), t('D')];

    const renderStudentAnswer = () => {
        if (showAnswers) {
            const option = Q.options.find(o => studentAnswer === o.id);
            if (!option) return (
                <>
                    <Divider />
                    <Header as='h4' color='blue'>{t("YourAnswer")}</Header>
                    <Header as='h5' content={t('YouDidNotAnswer')} color='red' />
                </>
            );
            return (
                <>
                    <Divider />
                    <Header as='h4' color='blue'>{t("YourAnswer")}</Header>
                    <Table basic='very' style={{ border: 'none' }} compact collapsing unstackable>
                        <Table.Body>
                            <Table.Row >
                                <Table.Cell style={{ border: 'none' }} collapsing>
                                    {alphArray[Q.options.findIndex(o => studentAnswer === o.id)]}.
                                </Table.Cell>
                                {
                                    option.contents.map(col => <Table.Cell key={`${option.id}${col.columnId}`} style={{ border: 'none' }} collapsing>
                                        {col.content.trim()}
                                    </Table.Cell>
                                    )
                                }
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </>
            );
        }
    };

    const renderOptionExplanations = () => {
        if (showAnswers) return (
            <>
                <Divider />
                <Header as='h4' color='green'>{t('Explanations')}</Header>
                <List relaxed='very' verticalAlign='middle'>
                    {
                        Q.options.filter(op => isViewOnly ? op.id === correctAnswerOptionId : true).map((o, indx) => <List.Item
                            key={o.id}
                            style={{ color: o.id === correctAnswerOptionId ? 'green' : 'red' }}
                            content={
                                <Grid stackable verticalAlign='top' columns='2'>
                                    {!isViewOnly && <GridColumn width='1' >
                                        {`${alphArray[indx]}.`}
                                    </GridColumn>}

                                    <GridColumn width='11'>
                                        {o.explanation.trim() ?
                                            <HtmlEditor value={o.explanation.trim()} readOnly >
                                                <TableContextMenu enabled />
                                                <TableResizing enabled />
                                            </HtmlEditor>
                                            : (o.id === correctAnswerOptionId ? t('CorrectAnswer') : t('NoExplanation'))
                                        }
                                    </GridColumn>
                                </Grid>
                            }
                        />)
                    }
                </List>
                <Divider />
                {
                    Q.correctAnswer.explanations.imgs.map(i => <Image loading="lazy" key={i.url} src={i.url} size='large' rounded />)
                }
            </>
        );
    };

    const renderHint = () => {
        if (!showAnswers && !Q?.isHinted && Q?.options?.length > 2) return (
            <div>
                <Header as='h4' color='blue' content={t("NeedHintText")} />
                <Button icon='lightbulb' labelPosition='left' negative basic content={t("DeleteSomeAnswers")} onClick={() => onHideSomeAnswers(Q)} />
            </div>
        );
    };

    return (
        <Segment basic style={{ minHeight: '40vh', overflow: 'auto', fontSize: '14px' }}>
            <Table basic='very' unstackable definition collapsing>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell style={{ border: 'none' }} />
                        <Table.HeaderCell style={{ border: 'none' }} />
                        {
                            Q.options[0].contents.length > 1 &&
                            Q.options[0].contents.map(col => <Table.HeaderCell key={col.columnId} style={{ border: 'none' }}>{col.colName.trim()}</Table.HeaderCell>)
                        }
                    </Table.Row>
                </Table.Header>

                <Table.Body>

                    {
                        Q.options.map((o, indx) => <Table.Row
                            key={o.id}
                            onContextMenu={() => onExcludeOption(Q, o.id)}
                        // negative={showAnswers && o.id !== correctAnswerOptionId}
                        // positive={showAnswers && o.id === correctAnswerOptionId}
                        >
                            <Table.Cell
                                style={{ cursor: 'pointer', backgroundColor: 'white', border: 'none' }}
                                onClick={() => studentAnswer ? null : onAnswerQuestion({ ...Q, answerOptionId: o.id })}
                                collapsing
                            >
                                {/* <Checkbox
                                    checked={studentAnswer === o.id}
                                // onChange={() => onAnswerQuestion({ ...Q, isFlagged: false, answerOptionId: o.id })}
                                /> */}
                                <Button
                                    circular
                                    icon={studentAnswer || isViewOnly ? (correctAnswerOptionId === o.id ? 'check' : 'times') : 'circle outline'}
                                    style={{
                                        color: studentAnswer || isViewOnly ? 'white' : 'black',
                                        backgroundColor: studentAnswer || isViewOnly ? (correctAnswerOptionId === o.id ? 'green' : 'red') : 'transparent',
                                        border: studentAnswer === o.id ? 'solid 2px black' : 'none',
                                    }}
                                />
                            </Table.Cell>

                            <Table.Cell style={{ border: 'none', textDecoration: o.isExcluded && "line-through" }} collapsing>
                                {alphArray[indx]}.
                            </Table.Cell>

                            {
                                o.contents.map(col => <Table.Cell key={`${o.id}${col.columnId}`} style={{ border: 'none', textDecoration: o.isExcluded && "line-through" }} >
                                    {col.content.trim()}
                                </Table.Cell>
                                )
                            }
                        </Table.Row>)
                    }

                </Table.Body>
            </Table>

            {/* {renderHint()} */}
            {renderStudentAnswer()}
            {renderOptionExplanations()}

        </Segment>
    );
};

export default withTranslation()(McqsQuestionTemplate);