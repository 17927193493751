

export const checkNotificationLink = (not) => {
    const data = JSON.parse(not.dataJson);
    if (data) {
        const { event, certId } = data;
        switch (event) {
            case "NEW_CALENDAR_ADDED": return "/calendar";
            case "NEW_CERT_ADDED": return `/certifications/${certId}?packages`;
            case "NEW_COURSE_ADDED": return certId ? `/certifications/${certId}` : '/courses';
            case "NEW_TOPIC_ADDED": return "/user/dashboard";
            case "NEW_UNIT_ADDED": return "/user/dashboard";
            case "NEW_TOPIC_VIDEO_ADDED": return "/user/dashboard";
            case "USER_SUBSCRIBE_ABOUT_END": return "/user/subscriptions";
            case "USER_SUBSCRIBE": return "/user/subscriptions";
            case "USER_SUBSCRIBE_PAY": return "/user/subscriptions";
            default: return "#";
        }
    }

    return "#";
};