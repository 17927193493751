import React from 'react';
import { Button, Header, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Consts } from '../../../res';

const LastMockResult = ({ t, onGoingExam }) => {
    const { examId, examRoundId, examType } = onGoingExam;

    if (examType === Consts.EXAM_TYPES.FREQ) return (
        <Segment basic>
            <Header as='h1' textAlign='center'>
                {t('YourExamHasFinished').toUpperCase()}
            </Header>

            <Segment basic textAlign='center'>
                <Button as={Link} to={`/user/exams/correction/${examId}/${examRoundId}`} positive content={t("StartCorrection").toUpperCase()} />
            </Segment>
        </Segment>
    );

    return (
        <Segment basic>
            <Header as='h1' textAlign='center'>
                {t('YourExamHasFinished').toUpperCase()}
            </Header>
            <Header as='h3' textAlign='center'>
                {t('MockExamFinishText1')}
            </Header>
            <Header as='h3' textAlign='center'>
                {t('MockExamFinishText2')}
            </Header>
        </Segment>
    );
};

export default withTranslation()(LastMockResult);