import _ from 'lodash';
import React, { useState } from 'react';
import { Button, Grid, GridColumn, GridRow, Input, Segment } from 'semantic-ui-react';

const SocialMediaForm = ({ data }) => {
    const { facebook, whatsapp, instagram, twitter, linkedin, youtube } = data.value ? JSON.parse(data.value) : {};
    const [socials, setSocials] = useState({
        facebook: { name: 'facebook', icon: 'facebook', color: 'facebook', value: facebook || '' },
        whatsapp: { name: 'whatsapp', icon: 'whatsapp', color: 'green', value: whatsapp || '' },
        instagram: { name: 'instagram', icon: 'instagram', color: 'purple', value: instagram || '' },
        twitter: { name: 'twitter', icon: 'twitter', color: 'twitter', value: twitter || '' },
        linkedin: { name: 'linkedin', icon: 'linkedin', color: 'linkedin', value: linkedin || '' },
        youtube: { name: 'youtube', icon: 'youtube', color: 'youtube', value: youtube || '' },
    });

    const onSocialChange = (name, value) => {
        const newSocials = { ...socials, [name]: { ...socials[name], value } };
        setSocials(newSocials);
        if (data.setValue)
            data.setValue(JSON.stringify(_.mapValues(newSocials, (s) => s.value)));
    };

    return (
        <Segment basic>
            <Grid stackable textAlign='center' verticalAlign='middle' columns={2}>
                {
                    Object.values(socials).map(s => <GridRow key={s.name}>
                        <GridColumn width='2'>
                            <Button circular icon={s.icon} color={s.color} />
                        </GridColumn>
                        <GridColumn width='14'>
                            <Input fluid type='url' value={s.value} onChange={(e, { value }) => onSocialChange(s.name, value)} />
                        </GridColumn>
                    </GridRow>)
                }
            </Grid>
        </Segment>
    );
};

export default SocialMediaForm;