import {
    GET_ALL_BLOGS_OPERATION_START,
    GET_ALL_BLOGS_OPERATION_FAIL,
    GET_ALL_BLOGS_OPERATION_FINISH,
    GET_ALL_BLOGS_SUCCESS
} from '../../actions/types';

const INITIAL_STATE = {
    error: '',
    loading: false,
    blogs: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ALL_BLOGS_OPERATION_START:
            return { ...state, loading: true };
        case GET_ALL_BLOGS_OPERATION_FINISH:
            return { ...state, loading: false };
        case GET_ALL_BLOGS_OPERATION_FAIL:
            return { ...state, error: { [action.payload.props]: action.payload.value } };
        case GET_ALL_BLOGS_SUCCESS:
            return { ...state, blogs: action.payload };
        default:
            return state;
    }
};
