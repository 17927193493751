import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { ErrorSegment } from '../../components';
import PATHS from '../../Routers/PATHS';

export default (ChildComponent) => {

    const RequireStudent = (props) => {

        if (!props.user)
            return <Redirect to={PATHS.SIGNIN.URL} />;

        if (props.user.isAdmin)
            return <ErrorSegment text={props.t('NotAllowed')} icon='lock' />;

        return <ChildComponent {...props} />;

    };

    const mapStateToProps = ({ auth }) => {
        const { user } = auth;
        return { user };
    };

    return withTranslation()(connect(mapStateToProps, {})(RequireStudent));
};