import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ErrorSegment } from '../../components';
import { withTranslation } from 'react-i18next';
import PATHS from '../../Routers/PATHS';

export default (ChildComponent, requiredPermissions = [], requiredPermissionsOperator = 'AND') => {

    const RequireAuth = (props) => {

        if (!props.user) return <Redirect to={PATHS.SIGNIN.URL} />;

        const userPermissions = props.user.ac || {};

        let allow = false;

        if (requiredPermissionsOperator === "AND")
            allow = requiredPermissions.every(([res, perm]) => userPermissions[res]?.[perm]?.granted);

        else if (requiredPermissionsOperator === "OR")
            allow = requiredPermissions.some(([res, perm]) => userPermissions[res]?.[perm]?.granted);

        if (allow)
            return <ChildComponent {...props} PERMISSIONS={userPermissions[requiredPermissions[0][0]]} USER_PERMISSIONS={userPermissions} />;
        else
            return <ErrorSegment text={props.t('NotAllowed')} icon='lock' />;

    };

    const mapStateToProps = ({ auth }) => {
        const { user } = auth;
        return { user };
    };

    return withTranslation()(connect(mapStateToProps, {})(RequireAuth));
};