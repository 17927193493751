import i18n from 'i18next';
import {
    LANDING_CERTIFICATIONS_OPERATION_START,
    LANDING_CERTIFICATIONS_OPERATION_FINISH,
    LANDING_CERTIFICATIONS_OPERATION_FAIL,
    LANDING_GET_ALL_CERTIFICATIONS,
    LANDING_CLEAR_CERTIFICATIONS_DATA
} from '../types';

import request from '../../api';



export const landingGetAllCertifications = () => async dispatch => {
    dispatch({ type: LANDING_CERTIFICATIONS_OPERATION_START });
    try {
        const { data: { certificates } } = await request.get('/certificates/all');
        dispatch({ type: LANDING_GET_ALL_CERTIFICATIONS, payload: certificates });
    } catch (err) {
        if (err.response) dispatch({ type: LANDING_CERTIFICATIONS_OPERATION_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: LANDING_CERTIFICATIONS_OPERATION_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    } finally {
        dispatch({ type: LANDING_CERTIFICATIONS_OPERATION_FINISH });
    }
};

export const landingClearCertificationsData = () => {
    return { type: LANDING_CLEAR_CERTIFICATIONS_DATA };
};



