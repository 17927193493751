import React, { useState } from 'react';
import { Button, Card, Header, Image, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import ProfileImageUploader from '../../Uploaders/ProfileImageUploader';

const GalleryBlock = ({ data, saveBlock }) => {
    const { t } = useTranslation();
    const [imagesEn, setImagesEn] = useState(data?.imagesEn || []);
    const [imagesAr, setImagesAr] = useState(data?.imagesAr || []);

    const addEnImages = (img) => {
        setImagesEn([...imagesEn, img]);
        saveBlock(data.blockId, { ...data, imagesEn: [...imagesEn, img] });
    };

    const addArImages = (img) => {
        setImagesAr([...imagesAr, img]);
        saveBlock(data.blockId, { ...data, imagesAr: [...imagesAr, img] });
    };

    return (
        <Segment>
            <Header>{t("EnglishGallery")}</Header>
            <ProfileImageUploader data={{ value: null, setValue: addEnImages }} deleteFromServer={false} />
            <Card.Group stackable itemsPerRow={4}>
                {
                    imagesEn.map((img, i) => <Card key={i}
                        centered
                        image={<Image src={img} style={{ display: 'flex', height: 150, width: null }} />}
                        extra={<Button.Group >
                            <Button icon='trash' onClick={() => {
                                const newImages = imagesEn.filter(item => item !== img);
                                setImagesEn(newImages);
                                saveBlock(data.blockId, { ...data, imagesEn: newImages });
                            }} />
                        </Button.Group>}
                    />)}
            </Card.Group>

            <Header>{t("ArabicGallery")}</Header>
            <ProfileImageUploader data={{ value: null, setValue: addArImages }} deleteFromServer={false} />
            <Card.Group stackable itemsPerRow={4}>
                {
                    imagesAr.map((img, i) => <Card key={i}
                        centered
                        image={<Image src={img} style={{ display: 'flex', height: 150, width: null }} />}
                        extra={<Button.Group >
                            <Button icon='trash' onClick={() => {
                                const newImages = imagesEn.filter(item => item !== img);
                                setImagesAr(newImages);
                                saveBlock(data.blockId, { ...data, imagesAr: newImages });
                            }} />
                        </Button.Group>}
                    />)}
            </Card.Group>

        </Segment>
    );
};

export const GalleryBlockViewer = ({ data }) => {
    const { i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const images = isArabic ? (data?.imagesAr || data?.imagesEn) : data?.imagesEn;

    if (images.length === 1)
        return <Image src={images[0]} rounded />;

    return (
        <ImageGallery isRTL={isArabic} items={images.map(i => ({ original: i, thumbnail: i }))} showPlayButton={false} showFullscreenButton={false} />
    );
};

export default GalleryBlock;