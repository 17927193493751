import {
    POST_CONTACTUS_START,
    POST_CONTACTUS_FAIL,
    POST_CONTACTUS,
    CONTACTUS_INFO_CHANGED
} from '../types';
import { badReq, noConnection } from '../../helpers/errors';
import request from '../../api';


export const contactUsInfoChanged = ({ props, value }) => {
    return { type: CONTACTUS_INFO_CHANGED, payload: { props, value } };
};

export const postContactUs = (name, email, subject, message, recaptchToken, clear) => async dispatch => {
    dispatch({ type: POST_CONTACTUS_START });
    try {
        await request.post('/contactus/message/send', {
            senderName: name,
            senderEmail: email,
            subject,
            content: message,
            "g-recaptcha-response": recaptchToken
        });
        clear();
        dispatch({ type: POST_CONTACTUS });

    } catch (err) {
        dispatch({ type: POST_CONTACTUS_FAIL, payload: '' });
        if (err.response) return badReq();
        else noConnection();
    }
};