// CONFIGS TYPES
export const CONFIGS_INFO_CHANGED = 'CONFIGS_INFO_CHANGED';

// AUTH TYPES
export const SIGNIN_INFO_CHANGED = 'SIGNIN_INFO_CHANGED';
export const SIGNIN_START = 'SIGNIN_START';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_FAIL = 'SIGNIN_FAIL';
export const SIGNOUT = 'SIGNOUT';
export const SIGNIN_FINISH = 'SIGNIN_FINISH';
export const CHECK_AUTH = 'CHECK_AUTH';

export const SIGNUP_INFO_CHANGED = 'SIGNUP_INFO_CHANGED';
export const SIGNUP_OPERATION_START = 'SIGNUP_OPERATION_START';
export const SIGNUP_OPERATION_FAIL = 'SIGNUP_OPERATION_FAIL';
export const SIGNUP_OPERATION_FINISH = 'SIGNUP_OPERATION_FINISH';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';

export const PROFILE_INFO_CHANGED = 'PROFILE_INFO_CHANGED';
export const PROFILE_OPERATION_START = 'PROFILE_OPERATION_START';
export const PROFILE_OPERATION_FAIL = 'PROFILE_OPERATION_FAIL';
export const PROFILE_OPERATION_FINISH = 'PROFILE_OPERATION_FINISH';
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';

export const FORGOTPASSWORD_INFO_CHANGED = 'FORGOTPASSWORD_INFO_CHANGED';
export const POST_FORGOTPASSWORD_START = 'POST_FORGOTPASSWORD_START';
export const POST_FORGOTPASSWORD_FAIL = 'POST_FORGOTPASSWORD_FAIL';
export const POST_FORGOTPASSWORD = 'POST_FORGOTPASSWORD';

export const POST_ACTIVATION_CODE_START = 'POST_ACTIVATION_CODE_START';
export const POST_ACTIVATION_CODE_FAIL = 'POST_ACTIVATION_CODE_FAIL';
export const POST_ACTIVATION_CODE = 'POST_ACTIVATION_CODE';
export const RESEND_ACTIVATION_CODE_START = 'RESEND_ACTIVATION_CODE_START';
export const RESEND_ACTIVATION_CODE_FAIL = 'RESEND_ACTIVATION_CODE_FAIL';
export const RESEND_ACTIVATION_CODE = 'RESEND_ACTIVATION_CODE';
export const ACTIVATION_CODE_INFO_CHANGED = 'ACTIVATION_CODE_INFO_CHANGED';

export const CONTACTUS_INFO_CHANGED = 'CONTACTUS_INFO_CHANGED';
export const POST_CONTACTUS_START = 'POST_CONTACTUS_START';
export const POST_CONTACTUS_FAIL = 'POST_CONTACTUS_FAIL';
export const POST_CONTACTUS = 'POST_CONTACTUS';

export const COUNTRIES_INFO_CHANGED = 'COUNTRIES_INFO_CHANGED';
export const COUNTRIES_OPERATION_START = 'COUNTRIES_OPERATION_START';
export const COUNTRIES_OPERATION_FAIL = 'COUNTRIES_OPERATION_FAIL';
export const COUNTRIES_OPERATION_FINISH = 'COUNTRIES_OPERATION_FINISH';
export const COUNTRIES_SUCCESS = 'COUNTRIES_SUCCESS';

export const GET_ALL_NEWS_OPERATION_START = 'GET_ALL_NEWS_OPERATION_START';
export const GET_ALL_NEWS_OPERATION_FAIL = 'GET_ALL_NEWS_OPERATION_FAIL';
export const GET_ALL_NEWS_OPERATION_FINISH = 'GET_ALL_NEWS_OPERATION_FINISH';
export const GET_ALL_NEWS_SUCCESS = 'GET_ALL_NEWS_SUCCESS';

export const GET_ALL_BLOGS_OPERATION_START = 'GET_ALL_BLOGS_OPERATION_START';
export const GET_ALL_BLOGS_OPERATION_FAIL = 'GET_ALL_BLOGS_OPERATION_FAIL';
export const GET_ALL_BLOGS_OPERATION_FINISH = 'GET_ALL_BLOGS_OPERATION_FINISH';
export const GET_ALL_BLOGS_SUCCESS = 'GET_ALL_BLOGS_SUCCESS';

export const RESET_PASSWORD_OPERATION_START = 'RESET_PASSWORD_OPERATION_START';
export const RESET_PASSWORD_OPERATION_FAIL = 'RESET_PASSWORD_OPERATION_FAIL';
export const RESET_PASSWORD_OPERATION_FINISH = 'RESET_PASSWORD_OPERATION_FINISH';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';

export const SET_NOTIFICATIONS_SEEN = "SET_NOTIFICATIONS_SEEN";
export const GET_NOTIFICATION_TOKEN = "GET_NOTIFICATION_TOKEN";
export const GET_ALL_NOTIFICATIONS_OPERATION_START = 'GET_ALL_NOTIFICATIONS_OPERATION_START';
export const GET_ALL_NOTIFICATIONS_OPERATION_FAIL = 'GET_ALL_NOTIFICATIONS_OPERATION_FAIL';
export const GET_ALL_NOTIFICATIONS_OPERATION_FINISH = 'GET_ALL_NOTIFICATIONS_OPERATION_FINISH';
export const GET_ALL_NOTIFICATIONS_SUCCESS = 'GET_ALL_NOTIFICATIONS_SUCCESS';

// LANDING TYPES
export const LANDING_COURSES_INFO_CHANGED = 'LANDING_COURSES_INFO_CHANGED';
export const LANDING_COURSES_OPERATION_START = 'LANDING_COURSES_OPERATION_START';
export const LANDING_COURSES_OPERATION_FINISH = 'LANDING_COURSES_OPERATION_FINISH';
export const LANDING_COURSES_OPERATION_FAIL = 'LANDING_COURSES_OPERATION_FAIL';
export const LANDING_GET_ALL_COURSES = 'LANDING_GET_ALL_COURSES';
export const LANDING_GET_ALL_COURSES_SORTINGBY = 'LANDING_GET_ALL_COURSES_SORTINGBY';
export const LANDING_CLEAR_COURSES_DATA = 'LANDING_CLEAR_COURSES_DATA';

export const LANDING_CERTIFICATIONS_INFO_CHANGED = 'LANDING_CERTIFICATIONS_INFO_CHANGED';
export const LANDING_CERTIFICATIONS_OPERATION_START = 'LANDING_CERTIFICATIONS_OPERATION_START';
export const LANDING_CERTIFICATIONS_OPERATION_FINISH = 'LANDING_CERTIFICATIONS_OPERATION_FINISH';
export const LANDING_CERTIFICATIONS_OPERATION_FAIL = 'LANDING_CERTIFICATIONS_OPERATION_FAIL';
export const LANDING_GET_ALL_CERTIFICATIONS = 'LANDING_GET_ALL_CERTIFICATIONS';
export const LANDING_CLEAR_CERTIFICATIONS_DATA = 'LANDING_CLEAR_CERTIFICATIONS_DATA';

export const GET_ALL_STUFF_OPERATION_START = 'GET_ALL_STUFF_OPERATION_START';
export const GET_ALL_STUFF_OPERATION_FAIL = 'GET_ALL_STUFF_OPERATION_FAIL';
export const GET_ALL_STUFF_OPERATION_FINISH = 'GET_ALL_STUFF_OPERATION_FINISH';
export const GET_ALL_STUFF_SUCCESS = 'GET_ALL_STUFF_SUCCESS';


// ADMIN TYPES
export const ADMIN_GET_ALL_TOPICS = 'ADMIN_GET_ALL_TOPICS';
export const ADMIN_GET_ALL_TOPICS_OPERATION_START = 'ADMIN_GET_ALL_TOPICS_OPERATION_START';
export const ADMIN_GET_ALL_TOPICS_OPERATION_FAIL = 'ADMIN_GET_ALL_TOPICS_OPERATION_FAIL';
export const ADMIN_GET_ALL_TOPICS_OPERATION_FINISH = 'ADMIN_GET_ALL_TOPICS_OPERATION_FINISH';

export const ADMIN_GET_VIDEO_DETAILS_OPERATION_START = 'ADMIN_GET_VIDEO_DETAILS_OPERATION_START';
export const ADMIN_GET_VIDEO_DETAILS_OPERATION_FINISH = 'ADMIN_GET_VIDEO_DETAILS_OPERATION_FINISH';
export const ADMIN_GET_VIDEO_DETAILS_OPERATION_FAIL = 'ADMIN_GET_VIDEO_DETAILS_OPERATION_FAIL';
export const ADMIN_GET_VIDEO_DETAILS = 'ADMIN_GET_VIDEO_DETAILS';
export const ADMIN_POST_NEW_VIDEO_DETAILS = 'ADMIN_POST_NEW_VIDEO_DETAILS';
export const ADMIN_DELETE_VIDEO_DETAILS = 'ADMIN_DELETE_VIDEO_DETAILS';

export const ADMIN_GET_BOOK_DETAILS_OPERATION_START = 'ADMIN_GET_BOOK_DETAILS_OPERATION_START';
export const ADMIN_GET_BOOK_DETAILS_OPERATION_FINISH = 'ADMIN_GET_BOOK_DETAILS_OPERATION_FINISH';
export const ADMIN_GET_BOOK_DETAILS_OPERATION_FAIL = 'ADMIN_GET_BOOK_DETAILS_OPERATION_FAIL';
export const ADMIN_GET_BOOK_DETAILS = 'ADMIN_GET_BOOK_DETAILS';
export const ADMIN_POST_NEW_BOOK_DETAILS = 'ADMIN_POST_NEW_BOOK_DETAILS';
export const ADMIN_DELETE_BOOK_DETAILS = 'ADMIN_DELETE_BOOK_DETAILS';

export const ADMIN_GET_EXPLANATIONS_VIDEOS_OPERATION_START = 'ADMIN_GET_EXPLANATIONS_VIDEOS_OPERATION_START';
export const ADMIN_GET_EXPLANATIONS_VIDEOS_OPERATION_FINISH = 'ADMIN_GET_EXPLANATIONS_VIDEOS_OPERATION_FINISH';
export const ADMIN_GET_EXPLANATIONS_VIDEOS_OPERATION_FAIL = 'ADMIN_GET_EXPLANATIONS_VIDEOS_OPERATION_FAIL';
export const ADMIN_GET_EXPLANATIONS_VIDEOS = 'ADMIN_GET_EXPLANATIONS_VIDEOS';
export const ADMIN_GET_EXPLANATIONS_IMAGES_OPERATION_START = 'ADMIN_GET_EXPLANATIONS_IMAGES_OPERATION_START';
export const ADMIN_GET_EXPLANATIONS_IMAGES_OPERATION_FINISH = 'ADMIN_GET_EXPLANATIONS_IMAGES_OPERATION_FINISH';
export const ADMIN_GET_EXPLANATIONS_IMAGES_OPERATION_FAIL = 'ADMIN_GET_EXPLANATIONS_IMAGES_OPERATION_FAIL';
export const ADMIN_GET_EXPLANATIONS_IMAGES = 'ADMIN_GET_EXPLANATIONS_IMAGES';


// USER TYPES

export const USER_EXAM_INFO_CHANGED = 'USER_EXAM_INFO_CHANGED';
export const USER_GET_EXAM_QUESTIONS_START = 'USER_GET_EXAM_QUESTIONS_START';
export const USER_GET_EXAM_QUESTIONS_FAIL = 'USER_GET_EXAM_QUESTIONS_FAIL';
export const USER_GET_EXAM_QUESTIONS_FINISH = 'USER_GET_EXAM_QUESTIONS_FINISH';
export const USER_GET_EXAM_QUESTIONS = 'USER_GET_EXAM_QUESTIONS';
export const USER_GET_EXAM_QUESTION_OPTIONS_START = 'USER_GET_EXAM_QUESTION_OPTIONS_START';
export const USER_GET_EXAM_QUESTION_OPTIONS_FAIL = 'USER_GET_EXAM_QUESTION_OPTIONS_FAIL';
export const USER_GET_EXAM_QUESTION_OPTIONS_FINISH = 'USER_GET_EXAM_QUESTION_OPTIONS_FINISH';
export const USER_GET_EXAM_QUESTION_OPTIONS = 'USER_GET_EXAM_QUESTION_OPTIONS';
export const USER_EXAM_ANSWER_QUESTION = 'USER_EXAM_ANSWER_QUESTION';
export const USER_EXAM_FLAG_QUESTION = 'USER_EXAM_FLAG_QUESTION';
export const USER_EXAM_FAV_QUESTION = 'USER_EXAM_FAV_QUESTION';
export const USER_EXAM_SELECT_QUESTION = 'USER_EXAM_SELECT_QUESTION';
export const USER_EXAM_HIDE_SOME_ANSWERS = 'USER_EXAM_HIDE_SOME_ANSWERS';
export const USER_EXAM_EXCLUDE_OPTION = 'USER_EXAM_EXCLUDE_OPTION';
export const USER_EXAM_DELETE_ANSWER_QUESTION = 'USER_EXAM_DELETE_ANSWER_QUESTION';

export const USER_GET_ONGOING_EXAM_START = 'USER_GET_ONGOING_EXAM_START';
export const USER_GET_ONGOING_EXAM_FAIL = 'USER_GET_ONGOING_EXAM_FAIL';
export const USER_GET_ONGOING_EXAM_FINISH = 'USER_GET_ONGOING_EXAM_FINISH';
export const USER_GET_ONGOING_EXAM = 'USER_GET_ONGOING_EXAM';

export const USER_ON_EXAM_START = 'USER_ON_EXAM_START';
export const USER_ON_EXAM_FINISH = 'USER_ON_EXAM_FINISH';
export const USER_ON_EXAM_START_LOADING_START = 'USER_ON_EXAM_START_LOADING_START';
export const USER_ON_EXAM_START_LOADING_FINISH = 'USER_ON_EXAM_START_LOADING_FINISH';
export const USER_ON_EXAM_FINISH_LOADING_START = 'USER_ON_EXAM_FINISH_LOADING_START';
export const USER_ON_EXAM_FINISH_LOADING_FINISH = 'USER_ON_EXAM_FINISH_LOADING_FINISH';


export const USER_COURSES_INFO_CHANGED = 'USER_COURSES_INFO_CHANGED';
export const USER_COURSES_OPERATION_START = 'USER_COURSES_OPERATION_START';
export const USER_COURSES_OPERATION_FINISH = 'USER_COURSES_OPERATION_FINISH';
export const USER_COURSES_OPERATION_FAIL = 'USER_COURSES_OPERATION_FAIL';
export const USER_GET_ALL_COURSES = 'USER_GET_ALL_COURSES';
export const USER_CLEAR_COURSES_DATA = 'USER_CLEAR_COURSES_DATA';
export const USER_SELECT_COURSE = 'USER_SELECT_COURSE';
export const USER_GET_COURSE_STRUCT_START = 'USER_GET_COURSE_STRUCT_START';
export const USER_GET_COURSE_STRUCT_FAIL = 'USER_GET_COURSE_STRUCT_FAIL';
export const USER_GET_COURSE_STRUCT_FINISH = 'USER_GET_COURSE_STRUCT_FINISH';
export const USER_GET_COURSE_STRUCT = 'USER_GET_COURSE_STRUCT';

export const USER_GET_ALL_SUBSCRIPTIONS = 'USER_GET_ALL_SUBSCRIPTIONS';
export const USER_GET_ALL_SUBSCRIPTIONS_START = 'USER_GET_ALL_SUBSCRIPTIONS_START';
export const USER_GET_ALL_SUBSCRIPTIONS_FAIL = 'USER_GET_ALL_SUBSCRIPTIONS_FAIL';
export const USER_GET_ALL_SUBSCRIPTIONS_FINISH = 'USER_GET_ALL_SUBSCRIPTIONS_FINISH';

export const USER_GET_ALL_OLD_EXAMS_START = 'USER_GET_ALL_OLD_EXAMS_START';
export const USER_GET_ALL_OLD_EXAMS_FAIL = 'USER_GET_ALL_OLD_EXAMS_FAIL';
export const USER_GET_ALL_OLD_EXAMS_FINISH = 'USER_GET_ALL_OLD_EXAMS_FINISH';
export const USER_GET_ALL_OLD_EXAMS = 'USER_GET_ALL_OLD_EXAMS';