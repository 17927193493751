import React, { useState, useEffect } from 'react';
import { Button, Form, Segment } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import request from '../../api';
import { ErrorSegment } from '..';


const SingleCol = ({ col, onDelete, onChange, index, t, onNew, onUpdate }) => {
    return (
        <Form.Group>
            <Form.Input
                fluid
                type='text'
                placeholder={t('EnglishColumnName')}
                required
                icon='columns'
                iconPosition='left'
                value={col.colNameEn}
                onChange={(e, { value }) => onChange({ colNameEn: value.split('|')[0], colNameAr: col.colNameAr, id: col.id }, index)}
                width='7'
            />
            <Form.Input
                fluid
                type='text'
                placeholder={t('ArabicColumnName')}
                required
                icon='columns'
                iconPosition='left'
                value={col.colNameAr}
                onChange={(e, { value }) => onChange({ colNameEn: col.colNameEn, colNameAr: value.split('|')[0], id: col.id }, index)}
                width='7'
            />
            <Form.Field widths={2}>
                <Button.Group>
                    <Button icon='save' positive onClick={() => col.id ? onUpdate(col) : onNew(col)} />
                    <Button icon='trash' negative onClick={() => onDelete(col.id)} />
                </Button.Group>
            </Form.Field>
        </Form.Group>
    );
};


const ColumnsEditor = ({ t, questionId }) => {
    const [dataLoading, setDataLoading] = useState(false);
    const [error, setError] = useState('');
    const [columns, setColumns] = useState([]);


    const updateCol = async (col) => {
        setDataLoading(true);
        try {
            await request.post('/questions/column/update', { ...col, columnId: col.id });
            const { data: { data } } = await request.get(`/questions/columns/fullinfo/${questionId}`);
            setColumns(data.length ? data : []);
        } catch (err) {
            setError(err);
        } finally {
            setDataLoading(false);
        }
    };

    const addCol = async (col) => {
        if (!col.colNameAr || !col.colNameEn) return;
        setDataLoading(true);
        try {
            await request.post('/questions/column/new', { ...col, questionId });
            const { data: { data } } = await request.get(`/questions/columns/fullinfo/${questionId}`);
            setColumns(data || [{ colNameEn: t('EnglishAnswer'), colNameAr: t('ArabicAnswer') }])
        } catch (err) {
            setError(err);
        } finally {
            setDataLoading(false);
        }
    };

    const deleteCol = async (id) => {
        setDataLoading(true);
        try {
            await request.delete('/questions/column', { data: { columnId: id } });
            const { data: { data } } = await request.get(`/questions/columns/fullinfo/${questionId}`);
            setColumns(data || [{ colNameEn: t('EnglishAnswer'), colNameAr: t('ArabicAnswer') }])
        } catch (err) {
            setError(err);
        } finally {
            setDataLoading(false);
        }
    };


    useEffect(() => {

        const getColumns = async () => {
            setDataLoading(true);
            try {
                const { data: { data } } = await request.get(`/questions/columns/fullinfo/${questionId}`);
                setColumns(data || [{ colNameEn: t('EnglishAnswer'), colNameAr: t('ArabicAnswer') }])
            } catch (err) {
                setError(err);
            } finally {
                setDataLoading(false);
            }
        };

        getColumns();
    }, [questionId, t]);


    const newEmptyCol = () => {
        if (columns.length >= 7) return;
        setColumns([...columns, { colNameEn: '', colNameAr: '' }]);
    };

    const onChangeCol = (value, index) => {
        const newCols = [...columns];
        newCols[index].colNameEn = value.colNameEn;
        newCols[index].colNameAr = value.colNameAr;
        newCols[index].id = value.id;
        setColumns(newCols);
    };

    if (error) return <ErrorSegment />;

    return (
        <Segment basic loading={dataLoading}>
            <Form>
                <Form.Button positive icon='plus' labelPosition='left' onClick={newEmptyCol} content={t('Add')} />
                {columns.map((c, index) => <SingleCol
                    key={index}
                    col={c}
                    index={index}
                    onDelete={deleteCol}
                    onChange={onChangeCol}
                    onNew={addCol}
                    onUpdate={updateCol}
                    t={t}
                />)}
            </Form>
        </Segment>
    );
};

export default withTranslation()(ColumnsEditor);
