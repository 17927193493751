import React, { useState } from 'react';
import { Accordion, Grid, GridColumn, Header, Menu, Segment, Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Consts, Theme, Styles } from '../../res';
import { resolveExam } from '../../helpers/examHelpers';
import { CircularProgressBar } from '..';

const { f_16_700_text, f_14_400_text, f_14_700_text } = Styles;

const CoursePerformance = ({ course }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const courseProgress = (course.reduce((acc, u) => acc + u.userUnitProgressPercent, 0) / (course.length || 1)).toFixed(1);

    const handleAccordion = (e, titleProps) => {
        const { index } = titleProps;
        const newIndex = activeIndex === index ? -1 : index;
        setActiveIndex(newIndex);
    };

    const renderRow = ({ topic }, indx) => {
        const videoPercentage = topic.videos.length ? parseInt(topic.videos.reduce((acc, v) => acc + v.userVideoProgressPercent, 0) / topic.videos.length) : null;
        const bookPercentage = topic.books.length ? parseInt(topic.books.reduce((acc, b) => acc + b.userBookProgressPercent, 0) / topic.books.length) : null;
        const mcqsExam = topic.exams?.length ? topic.exams.find(e => e.type === Consts.EXAM_TYPES.TEST_MCQS) : resolveExam(Consts.EXAM_TYPES.TEST_MCQS, topic.id);
        const essayExam = topic.exams?.length ? topic.exams.find(e => e.type === Consts.EXAM_TYPES.TEST_ESSAY) : resolveExam(Consts.EXAM_TYPES.TEST_ESSAY, topic.id);
        const tbsExam = topic.exams?.length ? topic.exams.find(e => e.type === Consts.EXAM_TYPES.TEST_TBS) : resolveExam(Consts.EXAM_TYPES.TEST_TBS, topic.id);
        const fileExam = topic.exams?.length ? topic.exams.find(e => e.type === Consts.EXAM_TYPES.TEST_FILE) : resolveExam(Consts.EXAM_TYPES.TEST_FILE, topic.id);

        const TopicProgress = parseInt(topic.userTopicProgressPercent);

        return (
            <Table.Row key={indx}>
                <Table.Cell style={f_14_400_text}>{`${topic.name} (${topic.rank})`}</Table.Cell>
                <Table.Cell textAlign='center' style={f_14_400_text} content={videoPercentage !== null ? `${videoPercentage} %` : t('NA')} />
                <Table.Cell textAlign='center' style={f_14_400_text} content={bookPercentage !== null ? `${bookPercentage} %` : t('NA')} />
                <Table.Cell textAlign='center' style={f_14_400_text} content={mcqsExam ? `${parseInt(mcqsExam.userExamProgressPercent)} %` : t('NA')} />
                <Table.Cell textAlign='center' style={f_14_400_text} content={essayExam ? `${parseInt(essayExam.userExamProgressPercent)} %` : t('NA')} />
                <Table.Cell textAlign='center' style={f_14_400_text} content={tbsExam ? `${parseInt(tbsExam.userExamProgressPercent)} %` : t('NA')} />
                <Table.Cell textAlign='center' style={f_14_400_text} content={fileExam ? `${parseInt(fileExam.userExamProgressPercent)} %` : t('NA')} />
                <Table.Cell textAlign='center' style={f_14_400_text}>{`${TopicProgress} %`}</Table.Cell>
            </Table.Row>
        );
    };

    const renderExamRow = (exam, indx) => {
        return (
            <Table.Row key={indx}>
                <Table.Cell style={f_14_400_text}>{`${t("FreqExam")} - ${exam.name} (${t('Part')} - ${exam.orderNum})`}</Table.Cell>
                <Table.Cell />
                <Table.Cell />
                <Table.Cell />
                <Table.Cell />
                <Table.Cell />
                <Table.Cell />
                <Table.Cell textAlign='center' style={f_14_400_text}>{`${exam.userExamProgressPercent.toFixed(2)} %`}</Table.Cell>
            </Table.Row>
        );
    };


    return (
        <Segment basic >
            <CircularProgressBar value={courseProgress} text={`${courseProgress}%`} />
            {
                course.map((u, indx) => <Accordion key={indx} as={Menu} vertical fluid style={{ backgroundColor: "transparent", border: 'none', boxShadow: 'none' }} >
                    <Menu.Item as={Segment} style={{ margin: 5, backgroundColor: Theme.colors.PRIMARY_05, borderRadius: 16, border: 'none', boxShadow: 'none' }}>
                        <Accordion.Title
                            onClick={handleAccordion}
                            index={indx}
                            active={activeIndex === indx}
                            content={
                                <Grid stackable columns='2' style={{ margin: 0, padding: 0 }}>
                                    <GridColumn mobile='16' computer='10' style={{ padding: 0 }} >
                                        <Header style={f_14_700_text} content={isArabic ? `${u.rank}- ${u.name}` : `${u.rank}- ${u.name}`} />
                                    </GridColumn>
                                    <GridColumn mobile='16' computer='6' style={{ padding: 0 }} textAlign='right'>
                                        <Header style={f_14_700_text} content={`(${parseFloat(u.userUnitProgressPercent?.toFixed(2))}%)`} />
                                    </GridColumn>
                                </Grid>
                            }
                        />
                        <Accordion.Content active={activeIndex === indx}>
                            <Table selectable structured>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell style={{ ...f_16_700_text, color: Theme.colors.PRIMARY_50 }}>{t('Topic')}</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center' style={{ ...f_16_700_text, color: Theme.colors.PRIMARY_50 }}>{t('Videos')}</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center' style={{ ...f_16_700_text, color: Theme.colors.PRIMARY_50 }}>{t('Books')}</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center' style={{ ...f_16_700_text, color: Theme.colors.PRIMARY_50 }}>{t('MCQS')}</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center' style={{ ...f_16_700_text, color: Theme.colors.PRIMARY_50 }}>{t('Essay')}</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center' style={{ ...f_16_700_text, color: Theme.colors.PRIMARY_50 }}>{t('Tbs')}</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center' style={{ ...f_16_700_text, color: Theme.colors.PRIMARY_50 }}>{t('File')}</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center' style={{ ...f_16_700_text, color: Theme.colors.PRIMARY_50 }}>{t('Total')}</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {
                                        u.topics.map((t, indx) => t.isExam ? (t.type === Consts.EXAM_TYPES.FREQ ? renderExamRow(t, indx) : t.childs.map((c, idx) => renderExamRow(c, `${indx}-${idx}`))) : renderRow({ topic: t }, indx))
                                    }
                                </Table.Body>

                                <Table.Footer>
                                    <Table.Row>
                                        <Table.HeaderCell style={{ ...f_16_700_text, color: Theme.colors.PRIMARY_50 }}>{`${u.topics.length} ${t('Topic')}`}</Table.HeaderCell>
                                        <Table.HeaderCell />
                                        <Table.HeaderCell />
                                        <Table.HeaderCell />
                                        <Table.HeaderCell />
                                        <Table.HeaderCell />
                                        <Table.HeaderCell />
                                        <Table.HeaderCell textAlign='center' style={{ ...f_16_700_text, color: Theme.colors.PRIMARY_50 }}>{`(${parseFloat(u.userUnitProgressPercent?.toFixed(2))}%)`}</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Footer>
                            </Table>
                        </Accordion.Content>
                    </Menu.Item>
                </Accordion>)
            }
        </Segment>
    );
};

export default CoursePerformance;