import {
    POST_CONTACTUS_START,
    POST_CONTACTUS_FAIL,
    POST_CONTACTUS,
    CONTACTUS_INFO_CHANGED
} from '../../actions/types';

const INITIAL_STATE = {
    error: '',
    loading: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CONTACTUS_INFO_CHANGED:
            return { ...state, [action.payload.props]: action.payload.value };
        case POST_CONTACTUS_START:
            return { ...state, loading: true };
        case POST_CONTACTUS_FAIL:
            return { ...state, loading: false, error: { email: action.payload } };
        case POST_CONTACTUS:
            return { ...state, ...INITIAL_STATE };
        default:
            return state;
    }
};

