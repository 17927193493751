import i18n from 'i18next';
import {
    LANDING_COURSES_OPERATION_START,
    LANDING_COURSES_OPERATION_FINISH,
    LANDING_COURSES_OPERATION_FAIL,
    LANDING_GET_ALL_COURSES,
    LANDING_GET_ALL_COURSES_SORTINGBY,
    LANDING_CLEAR_COURSES_DATA
} from '../types';

import request from '../../api';



export const landingGetAllCourses = (skip = 0, loading = true, limit = 10) => async dispatch => {
    if (loading) {
        dispatch({ type: LANDING_COURSES_OPERATION_START });
        dispatch({ type: LANDING_GET_ALL_COURSES_SORTINGBY });
    }
    try {
        const { data: { courses, pager, allCount } } = await request.get('/courses/oneday');
        dispatch({ type: LANDING_GET_ALL_COURSES, payload: { courses, pager, allCount } });
    } catch (err) {
        if (err.response) dispatch({ type: LANDING_COURSES_OPERATION_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: LANDING_COURSES_OPERATION_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    } finally {
        if (loading) dispatch({ type: LANDING_COURSES_OPERATION_FINISH });
    }
};

export const landingClearCoursesData = () => {
    return { type: LANDING_CLEAR_COURSES_DATA };
};



