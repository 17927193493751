import React from 'react';
import { Button, Grid, GridColumn, Header, Image, List, Segment } from 'semantic-ui-react';
import { htmlToText } from 'html-to-text';
import { useTranslation } from 'react-i18next';
import { Theme, Styles } from '../../res';
import courseImage from '../../res/images/course.jpg';

const { solidBtn, f_16_700_text, f_14_400_text } = Styles;

const SubscriptionCard = ({ data, user, onFeaturesOpen }) => {
    const { certificateId, certificateCode, courseName, subscriptionDate, expireAt,
        subscriptionTypeId, subscriptionPackageName, subscriptionPackageImgUrl, subscriptionPackageId,
        actualCostUSD, isSubscriptionValid, paymentStatus, paymentId, subscriptionPackageDescription, validitySeconds } = data;
    const { t } = useTranslation();
    const willEndSoon = expireAt && new Date(expireAt) >= new Date() && new Date(expireAt).getTime() - new Date().getTime() <= (1000 * 60 * 60 * 24 * 15);
    const isPaid = paymentStatus === "Completed";

    return (
        <Segment basic style={{ background: 'linear-gradient(0deg, #F3F4FB, #F3F4FB), #F3F4FB', borderRadius: 8 }}>
            <Grid columns={3} stackable verticalAlign='middle'>
                <GridColumn width={3}>
                    <Image loading="lazy" src={subscriptionPackageImgUrl || courseImage} rounded />
                </GridColumn>

                <GridColumn width={9}>
                    <Header style={{ ...f_16_700_text }}>{certificateId ? certificateCode : courseName}</Header>
                    <List horizontal>
                        <List.Item as={Header} style={{ ...f_14_400_text, color: Theme.colors.GREY_70 }}>{subscriptionPackageName}</List.Item>
                        <List.Item as={Header} style={{ ...f_14_400_text, color: Theme.colors.GREY_70 }}>{subscriptionTypeId}</List.Item>
                        <List.Item as={Header} style={{ ...f_14_400_text, color: Theme.colors.GREY_70 }}>{`${t('StartAt')}: ${subscriptionDate && new Date(subscriptionDate).toLocaleString()}`}</List.Item>
                        <List.Item as={Header} style={{ ...f_14_400_text, color: willEndSoon ? Theme.colors.ERROR_75 : Theme.colors.GREY_70 }}>{`${t('EndAt')}: ${expireAt && new Date(expireAt).toLocaleString()}`}</List.Item>
                        <List.Item as={Header} style={{ ...f_14_400_text, color: Theme.colors.PRIMARY_50, cursor: 'pointer' }} onClick={() => onFeaturesOpen({ id: subscriptionPackageId, packageName: subscriptionPackageName, priceUSD: actualCostUSD, discountUSD: 0, imgUrl: subscriptionPackageImgUrl, showOnly: true, validitySeconds: validitySeconds })}>{t('Features')}</List.Item>
                    </List>
                </GridColumn>

                <GridColumn width={4}>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '.2vw' }}>
                        <Button style={{ ...solidBtn, cursor: 'inherit', backgroundColor: Theme.colors.PRIMARY_2, color: isSubscriptionValid ? Theme.colors.SECONDARY_50 : Theme.colors.ERROR_75 }}>{isSubscriptionValid ? t('Active') : t('Inactive')}</Button>
                        {isPaid && <Button fluid icon='check' style={{ ...solidBtn, cursor: 'inherit', backgroundColor: Theme.colors.PRIMARY_05, color: Theme.colors.PRIMARY_50 }} content={`${t('Paid')} $${actualCostUSD}`} />}
                        {!isPaid && <Button fluid style={solidBtn} onClick={() => {
                            window.open(`https://www.q-tickets.com/payfortusd.aspx?paymentId=${paymentId}&amountUSD=${actualCostUSD}&referenceId=${Math.floor(Math.random() * 10000) + 1}&customerName=${user.firstName + " " + user.lastName}&customerContactNumber=${user.contactNumber}&customerEmail=${user.email}&courseName=${subscriptionPackageName || courseName}&courseDescription=`, '_self');
                        }}>{t('PayNow')}</Button>}
                    </div>
                </GridColumn>
            </Grid>
        </Segment>
    );
};


export default SubscriptionCard;