import React, { useContext, useEffect, useRef, useState } from 'react';
import { Icon, Segment } from 'semantic-ui-react';
import request from '../../api';
import { useTranslation } from 'react-i18next';
import { Workbook } from "@fortune-sheet/react";
import "@fortune-sheet/react/dist/index.css";
import { FreeModalContext } from '../Exam/ExamBody/FreeModal';

const transformData = (responseData) => {
    return responseData?.map((sheet) => ({
        id: sheet.id,
        name: sheet.name,
        celldata: sheet?.data?.flatMap((row, rIndex) =>
            row?.map((cell, cIndex) => {
                if (cell !== null) {
                    return {
                        r: rIndex,
                        c: cIndex,
                        v: cell,
                    };
                }

                return undefined;
            })
                ?.filter((cell) => cell !== undefined),
        ),
        calcChain: sheet?.calcChain?.map((item) => {
            const relatedCell = sheet.data[item.r][item.c];
            return {
                r: item.r,
                c: item.c,
                id: item.id,
                v: relatedCell !== null ? relatedCell : null,
            };
        }),
    }));
};


const SpreadsheetEditor = (props) => {
    const { docUrl, data, onBlur = () => { }, readOnly, iconName = 'save' } = props;
    const freeModalWidth = useContext(FreeModalContext);
    const ref = useRef();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [innerData, setInnerData] = useState(data?.value ? transformData(data?.value) : [{ name: 'Sheet1', celldata: [{ r: 0, c: 0, v: null }] }]);
    const [hasMounted, setHasMounted] = useState(false);

    useEffect(() => {
        if (docUrl) {
            setLoading(true);
            request.get(docUrl)
                .then(({ data: res }) => { setInnerData(transformData(res)); setLoading(false); })
                .catch(() => { });
        }
    }, []);

    useEffect(() => {
        if (hasMounted) refreshSheetWidth();
        else setHasMounted(true);
    }, [freeModalWidth, hasMounted]);

    const refreshSheetWidth = () => {
        document.dispatchEvent(new KeyboardEvent('keydown', {
            key: '-',          // The key value
            code: 'Minus',     // The physical key on the keyboard
            ctrlKey: true,     // Indicate that Ctrl key is pressed
            bubbles: true,     // Allow event to bubble up
            cancelable: true   // Allow the event to be canceled
        }));

        setTimeout(() => {
            document.dispatchEvent(new KeyboardEvent('keydown', {
                key: '+',          // The key value
                code: 'Equal',     // The physical key on the keyboard
                ctrlKey: true,     // Indicate that Ctrl key is pressed
                bubbles: true,     // Allow event to bubble up
                cancelable: true   // Allow the event to be canceled
            }));
        }, 0);
    };

    if (loading) return <Segment basic loading />;
    return (
        <div id="parentSheet" style={{ width: "100%", height: "100%" }}>
            <Workbook
                ref={ref}
                data={innerData}
                allowEdit={!readOnly}
                column={26}
                row={36}
                defaultColWidth={150}
                customToolbarItems={readOnly ? [] : [{ key: "saveme", tooltip: iconName === "save" ? t("Save") : t("Upload"), onClick: onBlur, icon: <div style={{ marginLeft: 'auto' }}><Icon name={iconName} /></div> }]}
                lang="en"
                onChange={data?.setValue}
            />
        </div>
    );
};

export default SpreadsheetEditor;