import i18n from 'i18next';
import notify from "devextreme/ui/notify";
import _ from 'lodash';
import { matchPath } from 'react-router-dom';
import {
    USER_EXAM_INFO_CHANGED,
    USER_GET_EXAM_QUESTIONS_START,
    USER_GET_EXAM_QUESTIONS_FAIL,
    USER_GET_EXAM_QUESTIONS_FINISH,
    USER_GET_EXAM_QUESTIONS,
    USER_GET_EXAM_QUESTION_OPTIONS_START,
    USER_GET_EXAM_QUESTION_OPTIONS_FAIL,
    USER_GET_EXAM_QUESTION_OPTIONS_FINISH,
    USER_EXAM_ANSWER_QUESTION,
    USER_EXAM_DELETE_ANSWER_QUESTION,
    USER_EXAM_FLAG_QUESTION,
    USER_EXAM_FAV_QUESTION,
    USER_EXAM_HIDE_SOME_ANSWERS,
    USER_EXAM_EXCLUDE_OPTION,
    USER_EXAM_SELECT_QUESTION,
    USER_GET_ONGOING_EXAM,
    USER_GET_ONGOING_EXAM_FAIL,
    USER_GET_ONGOING_EXAM_FINISH,
    USER_GET_ONGOING_EXAM_START,
    USER_ON_EXAM_START,
    USER_ON_EXAM_FINISH,
    USER_ON_EXAM_START_LOADING_START,
    USER_ON_EXAM_START_LOADING_FINISH,
    USER_ON_EXAM_FINISH_LOADING_START,
    USER_ON_EXAM_FINISH_LOADING_FINISH
} from '../types';

import request from '../../api';
import { getExamByExamId, resolveExam } from '../../helpers/examHelpers';
import { Consts } from '../../res';
import history from '../../history';
import { deleteChoices } from '../../helpers/general';
import { preparingShowingFactsForCma } from '../../helpers/preparingShowingFactsForCma';

export const userExamInfoChanged = ({ props, value }) => {
    return { type: USER_EXAM_INFO_CHANGED, payload: { props, value } };
};

export const userGetOnGoingExam = (examType, topic, examId) => async dispatch => {
    let exam = resolveExam(examType, topic, examId);

    if (exam.type === Consts.EXAM_TYPES.FREQ && exam.orderNum === 1)
        exam = resolveExam(null, null, exam.parentId);

    if (!exam) return;

    dispatch({ type: USER_GET_ONGOING_EXAM_START });
    try {
        const isMockExam = examType === Consts.EXAM_TYPES.FREQ || exam.type === Consts.EXAM_TYPES.FREQ_PARENT;
        let examRounds = [];

        const { data: { data } } = await request.get(`/student/round/exam/${exam.id}/ongoing`);
        examRounds = data;

        if (examRounds[0]?.examId && !examRounds[0]?.examRoundId) {
            dispatch({ type: USER_GET_ONGOING_EXAM, payload: [] });
            history.replace(`/user/exam/${examRounds[0]?.examId}`);
            return;
        }

        if (examRounds[0]?.examId !== exam.id) {
            exam = getExamByExamId(examRounds[0]?.examId);
        }

        dispatch({ type: USER_GET_ONGOING_EXAM, payload: examRounds.map(e => ({ ...exam, ...e, examType, isMockExam, isOld: true, isViewOnly: false, theme: exam.theme })) });
    } catch (err) {
        if (err.response) dispatch({ type: USER_GET_ONGOING_EXAM_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: USER_GET_ONGOING_EXAM_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    } finally {
        dispatch({ type: USER_GET_ONGOING_EXAM_FINISH });
    }
};

export const userClearOnGoingExam = () => {
    return { type: USER_ON_EXAM_FINISH };
};

export const userGetExamQuestions = (examRoundId, examData) => async dispatch => {
    dispatch({ type: USER_GET_EXAM_QUESTIONS_START });
    try {
        const { data: { data } } = await request.get(`/student/round/exam/questions/${examRoundId}`);
        let results = examData?.theme === Consts.EXAM_THEMES.CMA && data[0]?.questionTypeId === Consts.QUESTIONS_TYPES.ESSAY ? preparingShowingFactsForCma(data) : data;
        results = filterFileQuestionBasedOnTopics(results, examData);
        dispatch({ type: USER_GET_EXAM_QUESTIONS, payload: results });
    } catch (err) {
        if (err.response) dispatch({ type: USER_GET_EXAM_QUESTIONS_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: USER_GET_EXAM_QUESTIONS_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    } finally {
        dispatch({ type: USER_GET_EXAM_QUESTIONS_FINISH });
    }
};

export const userExamSelectQuestion = (Q) => async (dispatch, getState) => {
    dispatch({ type: USER_GET_EXAM_QUESTION_OPTIONS_START });
    try {
        const promise1 = userGetExamQuestionOptions(Q)(dispatch);
        const promise2 = userExamGetCorrectAnswer(Q)(dispatch, getState);
        const promise3 = userGetExamQuestionAttachments(Q)(dispatch);
        const data = await Promise.all([promise1, promise2, promise3]);
        dispatch({ type: USER_EXAM_SELECT_QUESTION, payload: { ...Q, options: data[0], correctAnswer: data[1], attachments: data[2] } });
    } catch (err) {

    } finally {
        dispatch({ type: USER_GET_EXAM_QUESTION_OPTIONS_FINISH });
    }
};


export const userGetExamQuestionOptions = (Q) => async dispatch => {
    if (Q.questionTypeId !== Consts.QUESTIONS_TYPES.MCQS)
        return null;

    try {
        if (Q.options) return Q.options;
        const { data: { data } } = await request.get(`/student/question/${Q.questionId}/options`);
        return _.shuffle(data);
    } catch (err) {
        if (err.response) dispatch({ type: USER_GET_EXAM_QUESTION_OPTIONS_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: USER_GET_EXAM_QUESTION_OPTIONS_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    }
};

export const userGetExamQuestionAttachments = (Q) => async dispatch => {
    if (Q.questionTypeId === Consts.QUESTIONS_TYPES.COMPOSITE || Q.questionTypeId === Consts.QUESTIONS_TYPES.File) {

        try {
            if (Q.attachments) return Q.attachments;
            const { data: { data } } = await request.get(`/student/question/${Q.questionId}/docs`);
            return data;
        } catch (err) {
            if (err.response) dispatch({ type: USER_GET_EXAM_QUESTION_OPTIONS_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
            else dispatch({ type: USER_GET_EXAM_QUESTION_OPTIONS_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
        }
    }
    else return null;
};

export const userExamGetCorrectAnswer = (Q) => async (dispatch, getState) => {
    if (Q.correctAnswer) return Q.correctAnswer;
    const { isMockExam, isViewOnly } = getState().userExam.onGoingExam;
    if (isMockExam && !isViewOnly) return {};
    try {
        const { data: { data } } = await request.post('/student/round/question/answer/correction', {
            questionRoundId: Q.userQuestionRoundId,
            answerOptionId: Q.answerOptionId,
            answerText: Q.answerText
        });
        return data;
    } catch (err) {
        if (err.response) dispatch({ type: USER_GET_EXAM_QUESTIONS_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: USER_GET_EXAM_QUESTIONS_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    }
};

export const userExamFlagQuestion = (Q) => async dispatch => {
    Q.isFlagged = !Q.isFlagged;
    try {
        dispatch({ type: USER_EXAM_FLAG_QUESTION, payload: Q });
        await request.post('/student/round/question/flag', {
            questionRoundId: Q.userQuestionRoundId,
            isFlagged: Q.isFlagged
        });
    } catch (err) {
        if (err.response) dispatch({ type: USER_GET_EXAM_QUESTIONS_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: USER_GET_EXAM_QUESTIONS_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    }
};

export const userExamFavQuestion = (Q) => async dispatch => {
    Q.isFav = !Q.isFav;
    try {
        dispatch({ type: USER_EXAM_FAV_QUESTION, payload: Q });
        await request.post('/student/question/fav', {
            questionId: Q.questionId,
            isFav: Q.isFav
        });
    } catch (err) {
        if (err.response) dispatch({ type: USER_GET_EXAM_QUESTIONS_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: USER_GET_EXAM_QUESTIONS_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    }
};

export const userExamHideSomeAnswers = (Q) => async dispatch => {
    if (Q.questionTypeId === Consts.QUESTIONS_TYPES.MCQS) {
        const answerToBeDeleted = Q?.options?.length === 2 ? 0 : Q?.options?.length === 3 ? 1 : 2;
        const correctAnswerId = Q?.correctAnswer?.correctAnswerOptionId;
        Q.options = [...deleteChoices(Q?.options, correctAnswerId, answerToBeDeleted)];
        Q.isHinted = true;
        dispatch({ type: USER_EXAM_HIDE_SOME_ANSWERS, payload: Q });
    }
};

export const userExamExcludeOption = (Q, optionId, isMock = false) => async dispatch => {

    const optionIndex = Q.options.findIndex(o => o.id === optionId);
    if (optionIndex !== -1) {
        const isExcluded = Boolean(Q.options[optionIndex]?.isExcluded);
        const isAnswerd = optionId === Q?.answerOptionId;
        if (isMock && isAnswerd && !isExcluded) userExamDeleteAnswerQuestion(Q)(dispatch);
        Q.options[optionIndex].isExcluded = !isExcluded;
    }

    dispatch({ type: USER_EXAM_EXCLUDE_OPTION, payload: Q });
};

export const userExamAnswerQuestion = (Q, isSubmited = false) => async dispatch => {
    try {
        if (Q.questionTypeId === Consts.QUESTIONS_TYPES.MCQS) {
            if (Q?.answerOptionId === -1) return userExamDeleteAnswerQuestion(Q)(dispatch);
            const option = Q.options.find(o => o.id === Q.answerOptionId);
            option?.isExcluded && userExamExcludeOption(Q, Q.answerOptionId)(dispatch);
        }
        dispatch({ type: USER_EXAM_ANSWER_QUESTION, payload: isSubmited ? { ...Q, isSubmited } : Q });
        await request.post('/student/round/question/answer', {
            questionRoundId: Q.userQuestionRoundId,
            answerOptionId: Q.answerOptionId,
            answerText: Q.answerText,
            answerJson: Q.answerJson,
            isSubmited
        });
    } catch (err) {
        if (err.response) dispatch({ type: USER_GET_EXAM_QUESTIONS_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: USER_GET_EXAM_QUESTIONS_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    }
};

export const userExamDeleteAnswerQuestion = (Q) => async dispatch => {
    try {
        Q.answerOptionId = null;
        Q.answerText = null;
        Q.answerJson = null;
        Q.isSubmited = false;
        dispatch({ type: USER_EXAM_DELETE_ANSWER_QUESTION, payload: Q });
        await request.post('/student/round/question/answer/cancel', { questionRoundId: Q.userQuestionRoundId });
    } catch (err) {
        if (err.response) dispatch({ type: USER_GET_EXAM_QUESTIONS_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: USER_GET_EXAM_QUESTIONS_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    }
};

export const userOnStartExam = (exam, questionsCount, topicsIds, isRandomQuestionsOrder, examName, preferredTypes) => async dispatch => {
    dispatch({ type: USER_ON_EXAM_START_LOADING_START });
    try {
        const { data: { examRoundId, finishAt, mock1LinkAllQuestionsCnt, mock1LinkPassQuestionsCnt, examRoundTopicsIds } } = await request.post('/student/round/exam/start', { examId: exam.id, questionsCount, topicsIds, isRandomQuestionsOrder, name: examName, onlyFavQuestions: preferredTypes?.onlyFavQuestions?.isSelected, onlyAnswerQuestions: preferredTypes?.onlyAnswerQuestions?.isSelected, onlyUnAnswerQuestions: preferredTypes?.onlyUnAnswerQuestions?.isSelected });
        const newExam = {
            ...exam,
            examName,
            questionsCount,
            examId: exam.id,
            examRoundId: examRoundId,
            examType: exam.type,
            isPassed: false,
            isOld: false,
            isViewOnly: false,
            theme: exam.theme,
            startDateTime: new Date().toISOString(),
            finishDateTime: finishAt || new Date(new Date().getTime() + (1000 * 60 * 60 * 3)).toISOString(),
            isMockExam: exam.type === Consts.EXAM_TYPES.FREQ,
            examRoundTopicsIds
        };
        dispatch({ type: USER_ON_EXAM_START, payload: newExam });
        if (newExam.isMockExam && newExam?.orderNum && newExam.orderNum > 1) {
            if (parseInt(mock1LinkAllQuestionsCnt / 2) > mock1LinkPassQuestionsCnt)
                notify({ message: i18n.t('MockNot50Text'), position: 'top center' }, "error", 10000);
        }
    } catch (err) {
        if (err.response) dispatch({ type: USER_GET_ONGOING_EXAM_FAIL, payload: { props: 'body', value: i18n.t(err?.response?.data?.code || 'ErrorBody') } });
        else dispatch({ type: USER_GET_ONGOING_EXAM_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    } finally {
        dispatch({ type: USER_ON_EXAM_START_LOADING_FINISH });
    }
};

export const userOnRetryExam = (exam, bIncludeWrongQ, bIncludeFlaggedQ, bIncludeUnanswerQ) => async dispatch => {
    dispatch({ type: USER_ON_EXAM_START_LOADING_START });
    try {
        const { data } = await request.post('/student/round/exam/retry', { examRoundId: exam.examRoundId, bIncludeWrongQ, bIncludeFlaggedQ, bIncludeUnanswerQ });
        return data;
    } catch (err) {
        if (err.response) dispatch({ type: USER_GET_ONGOING_EXAM_FAIL, payload: { props: 'body', value: i18n.t(err?.response?.data?.code || 'ErrorBody') } });
        else dispatch({ type: USER_GET_ONGOING_EXAM_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    } finally {
        dispatch({ type: USER_ON_EXAM_START_LOADING_FINISH });
    }
};

export const userOnViewOnlyExam = (exam) => dispatch => {
    dispatch({ type: USER_ON_EXAM_START_LOADING_START });
    const newExam = {
        questionsCount: exam.questionsCount,
        examId: exam.id,
        examRoundId: exam.examRoundId,
        examType: exam.examType,
        theme: exam.examTheme,
        isPassed: false,
        isOld: false,
        isViewOnly: true,
        startDateTime: new Date().toISOString(),
        finishDateTime: exam.finishAt,
        isMockExam: false,
        examRoundTopicsIds: exam?.examRoundTopicsIds
    };
    dispatch({ type: USER_ON_EXAM_START, payload: newExam });
    dispatch({ type: USER_ON_EXAM_START_LOADING_FINISH });
};

export const userOnFinishExam = (exam) => async dispatch => {
    dispatch({ type: USER_ON_EXAM_FINISH_LOADING_START });
    try {
        if (!exam.isViewOnly)
            await request.post('/student/round/exam/finish', { examRoundId: exam.examRoundId });
        dispatch({ type: USER_EXAM_INFO_CHANGED, payload: { props: 'isFinished', value: true } });
    } catch (err) {
        if (err.response) dispatch({ type: USER_GET_ONGOING_EXAM_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: USER_GET_ONGOING_EXAM_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    } finally {
        dispatch({ type: USER_ON_EXAM_FINISH_LOADING_FINISH });
    }
};

const filterFileQuestionBasedOnTopics = (questions, exam) => {
    try {
        if (exam.examType === Consts.EXAM_TYPES.FILE || exam.examType === Consts.EXAM_TYPES.TEST_FILE) {
            const topicIds = exam?.examRoundTopicsIds?.split(',')?.map(id => +id) || [];
            const data = matchPath(window.location.pathname);
            if (data?.topicId) topicIds.push(+data.topicId);

            for (let q of questions) {
                const content = JSON.parse(q.contentJson);
                const requirements = content?.requirements;
                if (requirements?.length) {
                    const filterdRequirements = requirements.filter(r => (!r.topicIds) || (!r.topicIds?.length) || (r.topicIds?.some(tId => topicIds.includes(tId))));
                    content.requirements = filterdRequirements;
                    q.contentJson = JSON.stringify(content);
                }
            }
        }

        return questions;
    } catch (error) {
        return questions;
    }
};