import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { toast } from 'react-toastify';
import { NotificationCard } from './components';
import { getAllNotifications } from './actions';
import { store } from './store';
import notificationBeeb from './res/sounds/notification.mp3';


const audio = new Audio(notificationBeeb);

const config = {
    apiKey: "AIzaSyDxSpQx7utJvlVUDvTmAApgNnEYMz1omvc",
    authDomain: "learning-go-7fca9.firebaseapp.com",
    projectId: "learning-go-7fca9",
    storageBucket: "learning-go-7fca9.appspot.com",
    messagingSenderId: "485878182056",
    appId: "1:485878182056:web:6a7790b2c5d6f35cdb929d",
    measurementId: "G-DEXCXENLS0"
};

const firebaseApp = initializeApp(config);

export const firebaseMessagingInit = async (saveNotificationToken) => {
    if (!("Notification" in window)) {
        console.log("Browser doesn't support Notification");
    } else if (Notification.permission === "granted") {
        // next steps for notifications
        generateFCMToken(saveNotificationToken);
    } else if (Notification.permission !== "denied") {
        try {
            const permission = await Notification.requestPermission();
            if (permission === "granted") {
                // next steps for notifications.
                generateFCMToken(saveNotificationToken);
            }

        } catch (error) {
            console.log(error);
        }
    }
};

const fcmForeGroundMessageListen = (messaging) => {
    onMessage(messaging, (payload) => {
        audio.play();
        payload.data.type === "NORMAL" && toast.success((props) => <NotificationCard {...props}{...payload.notification}  {...payload.data} />, { icon: false });
        getAllNotifications()(store.dispatch);
    });
};

const generateFCMToken = async (saveNotificationToken) => {
    try {
        // create messaging instance
        const messaging = getMessaging(firebaseApp);
        // generate FCM Token
        const token = await getToken(messaging, { vapidKey: process.env.REACT_APP_VAPIDKEY });
        saveNotificationToken(token || null);
        fcmForeGroundMessageListen(messaging);
    } catch (err) {
        console.error(err);
    }
};

