import React from 'react';
import { withTranslation } from 'react-i18next';
import { Segment, Table } from 'semantic-ui-react';
import { Consts } from '../../../../res';

const McqsQuestionMockTemplate = ({ Q, onAnswerQuestion, t, onExcludeOption }) => {

    const studentAnswer = Q.questionTypeId === Consts.QUESTIONS_TYPES.MCQS ? Q.answerOptionId : Q.answerText;
    const alphArray = [t('A'), t('B'), t('C'), t('D')];

    return (
        <Segment basic style={{ minHeight: '40vh', padding: 0 }} >
            <Table unstackable basic selectable style={{ borderCollapse: 'collapse' }} >
                <Table.Header>
                    <Table.Row>
                        {Q.options[0].contents.length > 1 && <Table.HeaderCell style={{ border: 'none' }} />}
                        {
                            Q.options[0].contents.length > 1 &&
                            Q.options[0].contents.map(col => <Table.HeaderCell key={col.columnId} style={{ border: 'none' }} >{col.colName.trim()}</Table.HeaderCell>)
                        }
                    </Table.Row>
                </Table.Header>

                <Table.Body>

                    {
                        Q.options.map((o, indx) => <Table.Row
                            key={o.id}
                            onClick={() => onAnswerQuestion({ ...Q, answerOptionId: Q?.answerOptionId === o.id ? -1 : o.id })}
                            style={{ backgroundColor: studentAnswer === o.id ? 'orange' : 'white', cursor: 'pointer', border: 'solid 2px rgba(0,0,0,.1)' }}
                            className='mockExamHoverRow'
                            onContextMenu={() => onExcludeOption(Q, o.id, true)}
                        >
                            <Table.Cell style={{ border: 'none', textDecoration: o.isExcluded && "line-through" }} collapsing>
                                {alphArray[indx]}.
                            </Table.Cell>
                            {
                                o.contents.map(col => <Table.Cell key={`${o.id}${col.columnId}`} style={{ textDecoration: o.isExcluded && "line-through" }} >
                                    {col.content.trim()}
                                </Table.Cell>
                                )
                            }
                        </Table.Row>)
                    }
                </Table.Body>
            </Table>
        </Segment>
    );
};

export default withTranslation()(McqsQuestionMockTemplate);