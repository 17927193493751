import i18n from 'i18next';
import { toast } from 'react-toastify';
import {
    POST_ACTIVATION_CODE_START,
    POST_ACTIVATION_CODE_FAIL,
    POST_ACTIVATION_CODE,
    ACTIVATION_CODE_INFO_CHANGED
} from '../types';
import { badReq, noConnection } from '../../helpers/errors';
import request from '../../api';
import { getFingerPrint, signinSuccess } from './SigninActions';


export const activationInfoChanged = ({ props, value }) => {
    return { type: ACTIVATION_CODE_INFO_CHANGED, payload: { props, value } };
};

export const postActivationCode = (code) => async (dispatch, getState) => {
    dispatch({ type: POST_ACTIVATION_CODE_START });
    try {
        const visitor = await getFingerPrint();
        const notificationToken = getState().notifications.notificationToken;
        const { data } = await request.post('/auth/account/activate', { activateCode: code, visitor, notificationToken });
        if (data) {
            await signinSuccess(dispatch, data);
        }
        dispatch({ type: POST_ACTIVATION_CODE });
    } catch (err) {
        if (err.response.status === 400) {
            return dispatch({ type: POST_ACTIVATION_CODE_FAIL, payload: i18n.t('InvalidActivationCode') });
        }
        if (err.response) return badReq();
        else noConnection();
    }
};

export const reSendActivationCode = (email, callback) => async dispatch => {
    dispatch({ type: POST_ACTIVATION_CODE_START });
    try {
        await request.post('/auth/account/activate/code/resend', { email });
        dispatch({ type: POST_ACTIVATION_CODE });
        toast.info(i18n.t('CheckEmail'));
        callback(false);
    } catch (err) {
        if (err.response.status === 400) {
            return dispatch({ type: POST_ACTIVATION_CODE_FAIL, payload: i18n.t('EmailError') });
        }
        if (err.response) return badReq();
        else noConnection();
    }
};