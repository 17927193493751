import React from 'react';
import { Segment, Button } from 'semantic-ui-react';
import { Styles } from '../../res';

const { noExtraSpaces } = Styles;

const SocialMediaList = ({ data }) => {
    const { facebook, whatsapp, instagram, twitter, linkedin, youtube } = JSON.parse(data || "{}");
    return (
        <Segment basic style={noExtraSpaces} textAlign='right'>
            {facebook && <Button circular color='facebook' icon='facebook' as='a' target="_blank" href={facebook} />}
            {whatsapp && <Button circular color='green' icon='whatsapp' as='a' target="_blank" href={whatsapp} />}
            {instagram && <Button circular color='purple' icon='instagram' as='a' target="_blank" href={instagram} />}
            {twitter && <Button circular color='twitter' icon='twitter' as='a' target="_blank" href={twitter} />}
            {linkedin && <Button circular color='linkedin' icon='linkedin' as='a' target="_blank" href={linkedin} />}
            {youtube && <Button circular color='youtube' icon='youtube' as='a' target="_blank" href={youtube} />}
        </Segment>
    );
};

export default SocialMediaList;