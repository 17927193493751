import React, { useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/ar';
import { Card, Segment, Modal } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { Media } from '../../Media';
import CoursesCalendarDetails from './Details';
import request from '../../api';
import { ErrorSegment, CalendarCard } from '..';

const dateStart = moment().subtract(1, "month").startOf('month').locale('en');
const dateEnd = moment().add(10, 'months').locale('en');

const timeValues = [];

while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
    timeValues.push(dateStart.format('YYYY-MM'));
    dateStart.add(1, 'month');
}

const CoursesCalendar = ({ t }) => {
    const [courses, setCourses] = useState(timeValues.map(m => ({ month: m, courses: [] })));
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const getCourses = async () => {
        setLoading(true);
        try {
            const { data: { data } } = await request.get('/public/calendar/courses', { params: { monthsCntFromNow: 12 } });
            const res = courses.map(i => ({ ...i, courses: data.filter(c => c.startAt.includes(i.month)) }));
            setCourses(res);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const onSelectCourse = (e, c, m) => {
        e.stopPropagation();
        if (c)
            setSelectedCourse({ ...c, month: m });
        else
            setSelectedCourse({ month: m });
    };

    useEffect(() => {
        getCourses();
        //eslint-disable-next-line
    }, []);

    const renderForMobile = () => {
        return (
            <Media lessThan='tablet'>
                {
                    (mediaClassNames, renderChildren) => {
                        return (
                            renderChildren && <Card.Group centered itemsPerRow={2} className={mediaClassNames}>
                                {courses.map((m, indx) => <CalendarCard isMobile key={indx} month={m} onSelectCourse={onSelectCourse} />)}
                            </Card.Group>
                        );
                    }}
            </Media>
        );
    };

    const renderForComputer = () => {
        return (
            <Media greaterThan='tablet'>
                {
                    (mediaClassNames, renderChildren) => {
                        return (
                            renderChildren && <Card.Group centered itemsPerRow={6} className={mediaClassNames}>
                                {courses.map((m, indx) => <CalendarCard key={indx} month={m} onSelectCourse={onSelectCourse} />)}
                            </Card.Group>
                        );
                    }}
            </Media>
        );
    };

    if (loading) return <Segment basic padded loading={loading} />;

    if (error) return <ErrorSegment />;

    return (
        <Segment basic>
            {renderForComputer()}
            {renderForMobile()}

            <Modal
                onClose={() => setSelectedCourse(null)}
                open={!!selectedCourse}
                centered
                size='large'
                closeOnDimmerClick={false}
                style={{ borderRadius: 16 }}
            >
                <Modal.Content scrolling style={{ borderRadius: 16 }}>
                    {selectedCourse &&
                        <Modal.Description>
                            <CoursesCalendarDetails
                                setSelectedCourse={setSelectedCourse}
                                courseData={selectedCourse}
                                onClose={() => setSelectedCourse(null)}
                            />
                        </Modal.Description>
                    }
                </Modal.Content>
            </Modal>
        </Segment>
    );
};

export default withTranslation()(CoursesCalendar);