import React from "react";
import { Header, Segment, Image, Grid, GridColumn } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Theme, Styles } from '../../res';
import promiseImage from '../../res/images/promise.svg';

const { f_12_400_text, f_16_700_text } = Styles;

const LearningGoPromise = () => {
    const { t } = useTranslation();
    return (
        <Segment style={{ border: 'none', borderRadius: 8 }}>
            <Grid verticalAlign="middle" >
                <GridColumn width={4}>
                    <Image loading="lazy" centered src={promiseImage} />
                </GridColumn>

                <GridColumn width={12}>
                    <Header as='h2'>
                        <Header.Content style={{ ...f_16_700_text, color: Theme.colors.PRIMARY_50 }}>
                            {t("LearningGoPromise")}
                            <Header.Subheader style={f_12_400_text}>
                                {t("LearningGoPromiseText")}
                            </Header.Subheader>
                        </Header.Content>
                    </Header>
                </GridColumn>
            </Grid>
        </Segment>
    );
};

export default LearningGoPromise;