import {
    RESET_PASSWORD_OPERATION_FAIL,
    RESET_PASSWORD_OPERATION_FINISH,
    RESET_PASSWORD_OPERATION_START
} from '../../actions/types';

const INITIAL_STATE = {
    error: '',
    loading: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RESET_PASSWORD_OPERATION_START:
            return { ...state, loading: true };
        case RESET_PASSWORD_OPERATION_FAIL:
            return { ...state, loading: false, error: { email: action.payload } };
        case RESET_PASSWORD_OPERATION_FINISH:
            return { ...state, ...INITIAL_STATE };
        default:
            return state;
    }
};

