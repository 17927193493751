import React from 'react';
import { Header, Segment, Image } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { Styles, Theme } from '../../res';
import emptyImage from '../../res/images/walls/empty1.svg';

const { f_20_700_text } = Styles;

const EmptySegment = ({ t, text, color, image }) => {
    return (
        <Segment basic placeholder>
            <Image loading="lazy" size='medium' centered src={image || emptyImage} />
            <Header textAlign='center' style={{ ...f_20_700_text, color: color || Theme.colors.PRIMARY_50 }} >
                {text || t('Empty')}
            </Header>
        </Segment>
    );
};

export default withTranslation()(EmptySegment);