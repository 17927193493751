import {
    SET_NOTIFICATIONS_SEEN,
    GET_NOTIFICATION_TOKEN,
    GET_ALL_NOTIFICATIONS_OPERATION_START,
    GET_ALL_NOTIFICATIONS_OPERATION_FAIL,
    GET_ALL_NOTIFICATIONS_OPERATION_FINISH,
    GET_ALL_NOTIFICATIONS_SUCCESS
} from '../../actions/types';
import { Consts } from '../../res';

const INITIAL_STATE = {
    notificationToken: null,
    error: '',
    loading: false,
    notNormalSeen: 0,
    notImportantSeen: 0,
    notifications: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_NOTIFICATIONS_SEEN:
            const nots = [...state.notifications];
            for (let i = 0; i < nots.length; i++) {
                for (let j = 0; j < action.payload.length; j++) {
                    if (nots[i].notificationId === action.payload[j])
                        nots[i].readAt = new Date().toISOString();
                }
            }

            const notNormalSeen = nots.filter(n => n.type === Consts.NOTIFICATIONS_TYPES.NORMAL && !n.readAt).length;
            const notImportantSeen = nots.filter(n => n.type === Consts.NOTIFICATIONS_TYPES.IMPORTANT && !n.readAt).length;

            return { ...state, notifications: nots, notImportantSeen, notNormalSeen };
        case GET_NOTIFICATION_TOKEN:
            return { ...state, notificationToken: action.payload };
        case GET_ALL_NOTIFICATIONS_OPERATION_START:
            return { ...state, loading: true };
        case GET_ALL_NOTIFICATIONS_OPERATION_FINISH:
            return { ...state, loading: false };
        case GET_ALL_NOTIFICATIONS_OPERATION_FAIL:
            return { ...state, error: { [action.payload.props]: action.payload.value } };
        case GET_ALL_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                notifications: action.payload,
                notNormalSeen: action.payload.filter(n => n.type === Consts.NOTIFICATIONS_TYPES.NORMAL && !n.readAt).length,
                notImportantSeen: action.payload.filter(n => n.type === Consts.NOTIFICATIONS_TYPES.IMPORTANT && !n.readAt).length
            };
        default:
            return state;
    }
};
