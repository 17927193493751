import React from 'react';
import { Header } from 'semantic-ui-react';
import { Styles } from '../../res';

const { f_16_400_text, noExtraSpaces, f_40_700_text } = Styles;

const UnderlinedHeader = ({ title, description, color }) => {
    return (
        <>
            <Header as='h1' style={{ ...f_40_700_text, ...noExtraSpaces, color: color }}>{title}</Header>
            {description ? <Header as='h3' style={{ ...f_16_400_text, marginTop: 10 }}>{description}</Header> : null}
        </>
    );
};

export default UnderlinedHeader;