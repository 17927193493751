import i18n from 'i18next';
import { toast } from 'react-toastify';

export const badReq = () => {
    toast.error(i18n.t('ErrorBody'), { position: toast.POSITION.BOTTOM_CENTER });
};

export const noConnection = () => {
    toast.error(i18n.t('ErrorConn'), { position: toast.POSITION.BOTTOM_CENTER });
};
