import React from 'react';
import { useTimer } from 'react-timer-hook';
import TimerStyled from './TimerStyled';


const Countdown = ({ expiryTimestamp, onExpire, theme }) => {
    const { seconds, minutes, hours, days } = useTimer({ expiryTimestamp, onExpire });
    return (
        <TimerStyled type='COUNTDOWN' theme={theme} seconds={seconds} minutes={minutes} hours={hours} days={days} />
    );
};

export default Countdown;