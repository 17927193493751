import React, { useEffect, useState } from 'react';
import { Segment, Accordion, Icon } from 'semantic-ui-react';
import i18next from 'i18next';
import { ErrorSegment } from '..';
import request from '../../api';
import '../../res/css/general.css';

let resetTimeout;

const FAQsList = ({ id, isCert, all }) => {
    const [faqs, setfaqs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const getfaqsList = async () => {
            setLoading(true);
            try {
                const { data: { data } } = all ? await request('/public/faqs') : await request(`/public/faqs?${isCert ? 'certId' : 'courseId'}=${id}`);
                setfaqs(data || []);
            } catch (error) {
                setError(error);
            }
            finally {
                setLoading(false);
            }
        };
        getfaqsList();

        return () => clearTimeout(resetTimeout);
        // eslint-disable-next-line
    }, []);

    const handleClick = (e, titleProps) => {
        const { index } = titleProps;
        const newIndex = activeIndex === index ? -1 : index;
    
        setActiveIndex(newIndex);
    };

    if (error) 
        return <ErrorSegment />;

    if (!faqs.length) 
        return <Segment basic textAlign='center' loading={loading}><ErrorSegment icon='wait' text={i18next.t('ComingSoon')} /></Segment>;

    return (
        <Segment basic loading={loading}>
            <Accordion fluid styled>
            {
                faqs.map((m, i) =>
                    <div key={m.id}>
                        <Accordion.Title
                            active={activeIndex === i}
                            index={i}
                            onClick={handleClick}
                            style={{fontFamily: 'cairo', color: activeIndex === i ? 'rgb(67 98 203)' : 'inherit'}}
                        >
                            <div style={{display:'flex', alignItems:'baseline'}}>
                                <Icon name='dropdown' /> 
                                <span>{m.question}</span>
                            </div>
                        </Accordion.Title>
                        <Accordion.Content active={activeIndex === i} >
                            <div style={{display:'flex', alignItems:'baseline'}}>
                                <Icon name='dropdown' style={{opacity: 0}} /> 
                                <div className='faqs-content' dangerouslySetInnerHTML={{__html: m.answer }} />
                            </div>
                        </Accordion.Content>
                </div>
            )
        }
        </Accordion>
    </Segment>
    );
};

export default FAQsList;
