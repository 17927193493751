import React from 'react';
import logo from '../../res/images/gifLogo.gif';

const Spinner = () => {
    return (
        <div style={{ height: '100vh', backgroundColor: 'white', display: 'flex', width: 'auto', justifyContent: "center", alignItems: 'center', borderRadius: 8 }}>
            <img id="loaderIn" src={logo} alt='logo' />
        </div>
    );
};

export default Spinner;