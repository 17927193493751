import i18n from 'i18next';
import { toast } from 'react-toastify';
import {
    SIGNUP_INFO_CHANGED,
    SIGNUP_OPERATION_START,
    SIGNUP_OPERATION_FAIL,
    SIGNUP_OPERATION_FINISH,
    SIGNUP_SUCCESS,
} from '../types';
import request from '../../api';
import { badReq, noConnection } from '../../helpers/errors';
import history from '../../history';
import PATHS from '../../Routers/PATHS';

export const signupInfoChanged = ({ props, value }) => {
    return { type: SIGNUP_INFO_CHANGED, payload: { props, value } };
};

export const signup = ({ firstName, lastName, gender, country, mobile, email, password, token }) => async dispatch => {
    dispatch({ type: SIGNUP_OPERATION_START });
    try {
        await request.post('/auth/signup', { firstName, lastName, gender, email, contactNumber: mobile, countryId: country, password, "g-recaptcha-response": token });
        dispatch({ type: SIGNUP_SUCCESS });
        dispatch(signupInfoChanged({ props: 'email', value: email }));
        history.push(PATHS.LANDING_ACTIVATION.URL);
        toast.info(i18n.t('CheckEmail'), { position: toast.POSITION.BOTTOM_CENTER });
    } catch (err) {
        if (err.response.status === 409) {
            return dispatch({ type: SIGNUP_OPERATION_FAIL, payload: { props: 'email', value: i18n.t('ErrorEmailExist') } });
        }
        if (err.response.status === 400) {
            switch (err.response.data.code) {
                case 'InvalidFirstname':
                    return dispatch({ type: SIGNUP_OPERATION_FAIL, payload: { props: 'firstName', value: i18n.t('ErrorName') } });
                case 'InvalidLastname':
                    return dispatch({ type: SIGNUP_OPERATION_FAIL, payload: { props: 'lastName', value: i18n.t('ErrorName') } });
                default:
                    break;
            }
        }
        if (err.response) badReq();
        else noConnection();
    } finally {
        dispatch({ type: SIGNUP_OPERATION_FINISH });
    }
};
