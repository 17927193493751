import React, { Suspense } from 'react';
import { Container, Icon } from 'semantic-ui-react';
import { Sidebar, useProSidebar } from 'react-pro-sidebar';
import { useTranslation } from 'react-i18next';
import { AdminSideBar, MessagesButton, Spinner } from '../components';
import { Theme } from '../res';
import AdminStack from '../Routers/AdminStack';


const AdminLayout = ({ user }) => {
    const { collapsed, toggleSidebar, broken, toggled } = useProSidebar();
    const { i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const opened = ((broken && toggled) || (!broken && !collapsed));

    const openCloseSideBar = (status) => {
        if (broken) return toggleSidebar(status);
    };


    return (
        <div style={{ display: 'flex', height: '100vh' }}>
            <MessagesButton user={user} showSidebar={null} />
            <Sidebar collapsedWidth="20%" breakPoint="always" rtl={isArabic}>
                <AdminSideBar user={user} opened={opened} openCloseSideBar={openCloseSideBar} />
            </Sidebar>
            <main style={{ position: 'relative', display: "flex", flex: 'auto', backgroundColor: Theme.colors.WHITE }}>
                <Icon link circular name={opened ? 'angle double left' : 'angle double right'} onClick={() => openCloseSideBar(!toggled)} style={{ backgroundColor: Theme.colors.WHITE, color: Theme.colors.BLACK, position: 'absolute', zIndex: 999, right: isArabic ? opened ? '20%' : 0 : undefined, left: isArabic ? undefined : opened ? '20%' : 0, top: '1%' }} />
                <div style={{ width: '100%', padding: 20 }}>
                    <Suspense fallback={<Spinner />}>
                        <Container fluid>
                            <AdminStack />
                        </Container>
                    </Suspense>
                </div>
            </main>
        </div >
    );
};

export default AdminLayout;