import React from 'react';
import { Icon } from 'semantic-ui-react';
import i18next from 'i18next';

const WhatsappButton = ({ showSidebar }) => {
    const { ltr, rtl } = style(showSidebar);

    return (
        <div style={i18next.language === 'en' ? ltr : rtl}>
            <a
                href="https://wa.me/message/P3UYHOZMQMESH1"
                target="_blank"
                rel="noopener noreferrer">
                <Icon link name='whatsapp' color='green' size='large' inverted circular />
            </a>
        </div>
    );
};

const style = (showSidebar) => ({
    rtl: {
        position: 'fixed',
        bottom: '3vh',
        zIndex: 999999,
        cursor: 'pointer',
        right: showSidebar ? '12vw' : '2vw'
    },
    ltr: {
        position: 'fixed',
        bottom: '3vh',
        zIndex: 999999,
        cursor: 'pointer',
        left: showSidebar ? '12vw' : '2vw'
    }
});
export default WhatsappButton;