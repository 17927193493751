import React, {useState} from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Button, Icon } from 'semantic-ui-react';
import { Theme } from '../../res';
import Separator from '../Operantinals/Separator';

const ChangePasswordForm = ({ onChange, onSubmit, formData, t, loading }) => {
    const { password, confirm, error } = formData;
    const [showPassword, setShowPassword] = useState(false);

    const onFocus = () => {
        onChange({
            props: 'error', value: ''
        });
    };
    const onActionPressed = () => {
        if (validate()) {
            onSubmit();
        }
    };
    const validate = () => {
        if (!password) {
            onChange({
                props: 'error', value: { ...error, password: t('Required') }
            });
            return false;
        }
        if (!confirm) {
            onChange({
                props: 'error', value: { ...error, confirm: t('Required') }
            });
            return false;
        }
        if (password.length <= 6) {
            onChange({
                props: 'error', value: { ...error, confirm: t('ErrorPassword') }
            });
            return false;
        }
        if (password.length > 20) {
            onChange({
                props: 'error', value: { ...error, confirm: t('ErrorPasswordMax') }
            });
            return false;
        }
        if (password !== confirm) {
            onChange({
                props: 'error', value: { ...error, password: t('PasswordMustMatch'), confirm: t('PasswordMustMatch') }
            });
            return false;
        }
        onFocus();
        return true;
    };
    return (

        <Form loading={loading}>
            <Form.Input
                type={showPassword ? 'text' : 'password'}
                label={t('Password')}
                placeholder='*********'
                required
                minLength={7}
                icon={<Icon name={showPassword ? 'eye' : 'eye slash'} link onClick={() => setShowPassword(!showPassword)} />}
                size='big'
                value={password}
                onChange={(e, data) => onChange({ props: 'password', value: data.value })}
                error={error.password}
                onFocus={onFocus}
                autoComplete='on'
            />
            <Form.Input
                type={showPassword ? 'text' : 'password'}
                label={t('ConfirmPassword')}
                placeholder='*********'
                required
                minLength={7}
                icon={<Icon name={showPassword ? 'eye' : 'eye slash'} link onClick={() => setShowPassword(!showPassword)} />}
                size='big'
                value={confirm}
                onChange={(e, data) => onChange({ props: 'confirm', value: data.value })}
                error={error.confirm}
                onFocus={onFocus}
                autoComplete='on'
            />
            <Separator />
            <Button onClick={onActionPressed} style={{ backgroundColor: Theme.colors.PRIMARY_50, color: Theme.colors.WHITE }} fluid size='big'>{t('Save')}</Button>
        </Form>
    );
};

export default withTranslation()(ChangePasswordForm);
