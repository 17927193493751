import i18n from 'i18next';
import {
    ADMIN_GET_EXPLANATIONS_VIDEOS_OPERATION_START,
    ADMIN_GET_EXPLANATIONS_VIDEOS_OPERATION_FINISH,
    ADMIN_GET_EXPLANATIONS_VIDEOS_OPERATION_FAIL,
    ADMIN_GET_EXPLANATIONS_VIDEOS,
    ADMIN_GET_EXPLANATIONS_IMAGES_OPERATION_START,
    ADMIN_GET_EXPLANATIONS_IMAGES_OPERATION_FINISH,
    ADMIN_GET_EXPLANATIONS_IMAGES_OPERATION_FAIL,
    ADMIN_GET_EXPLANATIONS_IMAGES,
} from '../types';
import request from '../../api';

export const adminGetExplanationsVideoList = (questionId) => async dispatch => {
    dispatch({ type: ADMIN_GET_EXPLANATIONS_VIDEOS_OPERATION_START });
    try {
        const { data: { data } } = await request.get(`/videos/fullinfo/question/${questionId}?type=EXPLANATION`);
        dispatch({ type: ADMIN_GET_EXPLANATIONS_VIDEOS, payload: data });
    } catch (err) {
        if (err.response) dispatch({ type: ADMIN_GET_EXPLANATIONS_VIDEOS_OPERATION_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: ADMIN_GET_EXPLANATIONS_VIDEOS_OPERATION_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    } finally {
        dispatch({ type: ADMIN_GET_EXPLANATIONS_VIDEOS_OPERATION_FINISH });
    }
};

export const adminPostNewExplanationVideo = (details) => async dispatch => {
    dispatch({ type: ADMIN_GET_EXPLANATIONS_VIDEOS_OPERATION_START });
    try {
        await request.post('/video/new', details);
        await adminGetExplanationsVideoList(details.questionId)(dispatch);
    } catch (err) {
        if (err.response) dispatch({ type: ADMIN_GET_EXPLANATIONS_VIDEOS_OPERATION_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: ADMIN_GET_EXPLANATIONS_VIDEOS_OPERATION_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    } finally {
        dispatch({ type: ADMIN_GET_EXPLANATIONS_VIDEOS_OPERATION_FINISH });
    }
};

export const adminDeleteExplanationVideo = (videoId, questionId) => async dispatch => {
    dispatch({ type: ADMIN_GET_EXPLANATIONS_VIDEOS_OPERATION_START });
    try {
        await request.delete('/video/question', { data: { videoId, questionId } });
        await adminGetExplanationsVideoList(questionId)(dispatch);
    } catch (err) {
        if (err.response) dispatch({ type: ADMIN_GET_EXPLANATIONS_VIDEOS_OPERATION_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: ADMIN_GET_EXPLANATIONS_VIDEOS_OPERATION_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    } finally {
        dispatch({ type: ADMIN_GET_EXPLANATIONS_VIDEOS_OPERATION_FINISH });
    }
};

export const adminGetExplanationsImagesList = (questionId) => async dispatch => {
    dispatch({ type: ADMIN_GET_EXPLANATIONS_IMAGES_OPERATION_START });
    try {
        const { data: { data } } = await request.get(`/admin/question/${questionId}/imgs/fullinfo`);
        dispatch({ type: ADMIN_GET_EXPLANATIONS_IMAGES, payload: data });
    } catch (err) {
        if (err.response) dispatch({ type: ADMIN_GET_EXPLANATIONS_IMAGES_OPERATION_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: ADMIN_GET_EXPLANATIONS_IMAGES_OPERATION_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    } finally {
        dispatch({ type: ADMIN_GET_EXPLANATIONS_IMAGES_OPERATION_FINISH });
    }
};

export const adminPostNewExplanationImage = (details) => async dispatch => {
    dispatch({ type: ADMIN_GET_EXPLANATIONS_IMAGES_OPERATION_START });
    try {
        await request.post('/admin/question/imgs', details);
        await adminGetExplanationsImagesList(details.questionId)(dispatch);
    } catch (err) {
        if (err.response) dispatch({ type: ADMIN_GET_EXPLANATIONS_IMAGES_OPERATION_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: ADMIN_GET_EXPLANATIONS_IMAGES_OPERATION_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    } finally {
        dispatch({ type: ADMIN_GET_EXPLANATIONS_IMAGES_OPERATION_FINISH });
    }
};

export const adminDeleteExplanationImage = (imgId, questionId) => async dispatch => {
    dispatch({ type: ADMIN_GET_EXPLANATIONS_IMAGES_OPERATION_START });
    try {
        await request.delete('/question/img', { data: { imgId, questionId } });
        await adminGetExplanationsImagesList(questionId)(dispatch);
    } catch (err) {
        if (err.response) dispatch({ type: ADMIN_GET_EXPLANATIONS_IMAGES_OPERATION_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: ADMIN_GET_EXPLANATIONS_IMAGES_OPERATION_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    } finally {
        dispatch({ type: ADMIN_GET_EXPLANATIONS_IMAGES_OPERATION_FINISH });
    }
};
