import {
    LANDING_COURSES_INFO_CHANGED,
    LANDING_COURSES_OPERATION_START,
    LANDING_COURSES_OPERATION_FINISH,
    LANDING_COURSES_OPERATION_FAIL,
    LANDING_GET_ALL_COURSES,
    LANDING_GET_ALL_COURSES_SORTINGBY,
    LANDING_CLEAR_COURSES_DATA
} from '../../actions/types';

const INITIAL_STATE = {
    courses: [],
    pager: {},
    error: '',
    loading: false,
    allCount: null,
};


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LANDING_COURSES_INFO_CHANGED:
            return { ...state, [action.payload.props]: action.payload.value };
        case LANDING_COURSES_OPERATION_START:
            return { ...state, loading: true };
        case LANDING_COURSES_OPERATION_FINISH:
            return { ...state, loading: false };
        case LANDING_COURSES_OPERATION_FAIL:
            return { ...state, error: { [action.payload.props]: action.payload.value } };
        case LANDING_GET_ALL_COURSES:
            return { ...state, courses: [...state.courses, ...action.payload.courses], pager: action.payload.pager, allCount: action.payload.allCount };
        case LANDING_GET_ALL_COURSES_SORTINGBY:
            return { ...state, courses: [], pager: {}, allCount: null };
        case LANDING_CLEAR_COURSES_DATA:
            return { ...INITIAL_STATE };
        default:
            return state;
    }
};

