import {
    ADMIN_GET_EXPLANATIONS_VIDEOS_OPERATION_START,
    ADMIN_GET_EXPLANATIONS_VIDEOS_OPERATION_FINISH,
    ADMIN_GET_EXPLANATIONS_VIDEOS_OPERATION_FAIL,
    ADMIN_GET_EXPLANATIONS_VIDEOS,
    ADMIN_GET_EXPLANATIONS_IMAGES_OPERATION_START,
    ADMIN_GET_EXPLANATIONS_IMAGES_OPERATION_FINISH,
    ADMIN_GET_EXPLANATIONS_IMAGES_OPERATION_FAIL,
    ADMIN_GET_EXPLANATIONS_IMAGES,
} from '../../actions/types';

const INITIAL_STATE = {
    videos: [],
    error: '',
    loading: false,
    images: [],
    imgsError: '',
    imgsLoading: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADMIN_GET_EXPLANATIONS_VIDEOS_OPERATION_START:
            return { ...state, loading: true };
        case ADMIN_GET_EXPLANATIONS_VIDEOS_OPERATION_FINISH:
            return { ...state, loading: false };
        case ADMIN_GET_EXPLANATIONS_VIDEOS_OPERATION_FAIL:
            return { ...state, error: { [action.payload.props]: action.payload.value } };
        case ADMIN_GET_EXPLANATIONS_VIDEOS:
            return { ...state, videos: action.payload };
        case ADMIN_GET_EXPLANATIONS_IMAGES_OPERATION_START:
            return { ...state, imgsLoading: true };
        case ADMIN_GET_EXPLANATIONS_IMAGES_OPERATION_FINISH:
            return { ...state, imgsLoading: false };
        case ADMIN_GET_EXPLANATIONS_IMAGES_OPERATION_FAIL:
            return { ...state, imgsError: { [action.payload.props]: action.payload.value } };
        case ADMIN_GET_EXPLANATIONS_IMAGES:
            return { ...state, images: action.payload };
        default:
            return state;
    }
};