import React from 'react';
import FooterSocpa from './FooterSocpa';
import FooterCma from './FooterCma';
import FooterCia from './FooterCia';
import FooterDifr from './FooterDifr';
import { Consts } from '../../../../res';

const Footer = (props) => {

    const renderFooter = () => {
        switch (props.theme) {
            case Consts.EXAM_THEMES.SOCPA:
                return <FooterSocpa {...props} />;
            case Consts.EXAM_THEMES.CMA:
                return <FooterCma {...props} />;
            case Consts.EXAM_THEMES.CIA:
                return <FooterCia {...props} />;
            case Consts.EXAM_THEMES.CPA:
                return null;
            case Consts.EXAM_THEMES.DIFR:
                return <FooterDifr {...props} />;
            default:
                return <FooterSocpa {...props} />;
        }
    };

    return (
        <div>
            {renderFooter()}
        </div>
    );
};

export default Footer;