import React from 'react';
import { useLocation } from 'react-router-dom';
import querystring from 'querystring-es3';


export default (ChildComponent) => {

    const RequireParseUrl = (props) => {

        const parsedUrl = querystring.parse((useLocation().search));

        return <ChildComponent {...props} {...parsedUrl} />;
    };


    return RequireParseUrl;
};