import React from 'react';
import { Segment, Table, Image, Grid, GridColumn, Header, Card, Icon } from 'semantic-ui-react';
import i18next from 'i18next';
import moment from 'moment';
import 'moment/locale/ar';
import { withTranslation } from 'react-i18next';
import { DescriptionCard } from '..';
import iconImage from '../../res/images/logo.svg';
import ErrorSegment from '../Operantinals/ErrorSegment';
import { Styles, Theme } from '../../res';


const { f_16_700_text } = Styles;

const CourseCalendarDetails = ({ onClose, setSelectedCourse, courseData, t }) => {

    const headerCellStyle = { ...f_16_700_text, backgroundColor: Theme.colors.SECONDARY_50, color: Theme.colors.WHITE };
    const { month, courseName, startAt, endAt, calendarStructure, calendarFeatures,
        calendarPromise, subscribeUrl } = courseData;


    const renderCourseDetails = () => {
        if (!courseData.courseName) return <ErrorSegment
            icon={courseData.month.courses.length > 0 ? 'hand point left' : 'clock'}
            text={courseData.month.courses.length > 0 ? t('ChooseCourse') : t('Soon')}
        />;
        return (
            <Segment basic>
                {/* <Image loading="lazy" size='tiny' centered src={courseData.certCoverImgUrl || iconImage} /> */}

                <Table celled verticalAlign='middle'>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell collapsing style={headerCellStyle}>{t('Program')}</Table.Cell>
                            <Table.Cell>{courseName}</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell collapsing style={headerCellStyle}>{t('Period')}</Table.Cell>
                            <Table.Cell>{moment(endAt).diff(startAt, 'months', true).toFixed(1)} {t('Month')}</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell collapsing style={headerCellStyle}>{t('StartAt')}</Table.Cell>
                            <Table.Cell>{new Date(startAt).toLocaleString()}</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell collapsing style={headerCellStyle}>{t('EndAt')}</Table.Cell>
                            <Table.Cell>{new Date(endAt).toLocaleString()}</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell collapsing style={headerCellStyle}>{t('Structure')}</Table.Cell>
                            <Table.Cell>
                                <DescriptionCard text={calendarStructure} />
                            </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell collapsing style={headerCellStyle}>{t('Features')}</Table.Cell>
                            <Table.Cell>
                                <DescriptionCard text={calendarFeatures} />
                            </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell collapsing style={headerCellStyle}>{t('Promise')}</Table.Cell>
                            <Table.Cell>
                                <DescriptionCard text={calendarPromise} />
                            </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell collapsing style={headerCellStyle}>{t('Packages')}</Table.Cell>
                            <Table.Cell>
                                <a href={subscribeUrl} rel='noreferrer' target='_blank'>{subscribeUrl}</a>
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Segment>
        );
    };

    const renderMonthCoursesNav = () => {
        return (
            <Segment basic>
                <Header
                    content={moment(month.month).locale(i18next.language).format('MMMM YYYY')}
                    textAlign='center'
                    subheader={`(${month.courses.length})`}
                    style={{ color: Theme.colors.PRIMARY_50 }}
                    as='h3'
                />

                <Segment basic textAlign='center'>
                    <Card.Group size='tiny' itemsPerRow={2}>
                        {month.courses.map((c, index) =>
                            <Card link key={index} style={{width: '77.25px', height: '77.25px'}}>
                                <Image loading="lazy"
                                    style={{ width: '100%', height: '100%', backgroundColor: c.is_started ? 'rgb(0 0 0 / 42%)': 'rgba(0,0,0,.05)'}}
                                    src={c.certCoverImgUrl || iconImage}
                                    onClick={() => setSelectedCourse({ ...c, month })}
                                    centered
                                    fluid
                                    
                                    label={{
                                        icon: { name: 'star', color: c.calendarId === courseData.calendarId ? 'red' : 'black' },
                                        size: 'mini',
                                        corner: true,
                                        style: {borderColor:  c.is_started ? '#969393' : '#e8e8e8'}
                                    }}
                                />
                            </Card>
                        )}
                    </Card.Group>
                </Segment>
            </Segment>
        );
    };


    return (
        <>
            <Segment basic textAlign='right'>
                <Icon link name='times' size='large' onClick={onClose} />
            </Segment>
            <Grid stackable divided columns='2' stretched>
                <GridColumn width={4}>
                    {renderMonthCoursesNav()}
                </GridColumn>

                <GridColumn width={12}>
                    {renderCourseDetails()}
                </GridColumn>
            </Grid>
        </>
    );
};

export default withTranslation()(CourseCalendarDetails);