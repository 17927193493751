import React from 'react';
import { Dropdown, Menu } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import onLangChange from '../../helpers/checkLang';
import { Styles, Theme } from '../../res';

const { f_12_400_text } = Styles;

const Languager = ({ isMenu }) => {

    const { languageOptions, language } = useSelector(state => state.configs);
    const onChange = (newLang) => {
        if (language === newLang) return;
        onLangChange(newLang);
        window.location.reload();
    };

    if (isMenu) return (
        <Menu vertical borderless>
            {
                languageOptions.map(item => {
                    const isActive = language === item.value;
                    return <Menu.Item
                        key={item.key}
                        name={item.text}
                        active={isActive}
                        style={{ backgroundColor: isActive ? Theme.colors.PRIMARY_05 : Theme.colors.WHITE, color: isActive ? Theme.colors.PRIMARY_50 : Theme.colors.GREY_50 }}
                        icon={{ name: isActive ? 'check' : undefined, style: { color: Theme.colors.PRIMARY_50 } }}
                        onClick={(e) => onChange(item.value)}
                    />;
                })}
        </Menu>
    );

    return (
        <Dropdown
            options={languageOptions}
            defaultValue={language}
            onChange={(e, { value }) => onChange(value)}
            inline
            item
            floating
            icon='chevron down'
            pointing
            style={f_12_400_text}
        />
    );
};

export default Languager;;