import i18next from 'i18next';
import { Consts } from "../res";
import { store } from '../store';

const { getState } = store;

export const resolveExam = (examType, topic, examId) => {
    const exams = getState().userCourses.selectedCourseStruct.allowedExams;
    if (!exams || !exams.length) return null;
    if (examId) return getExamByExamId(examId);
    if (topic) return exams.find(e => e.type === examType && e.topicId === topic) || null;
    return exams.find(e => e.type === examType) || null;
};

export const getExamTypeString = (type, orderNum) => {
    switch (type) {
        case Consts.EXAM_TYPES.MCQS: return i18next.t('McqsBank');
        case Consts.EXAM_TYPES.ESSAY: return i18next.t('EssaysBank');
        case Consts.EXAM_TYPES.TBS: return i18next.t('TbsBank');
        case Consts.EXAM_TYPES.FILE: return i18next.t('FileBank');
        case Consts.EXAM_TYPES.TEST_MCQS:
        case Consts.EXAM_TYPES.TEST_ESSAY:
        case Consts.EXAM_TYPES.TEST_TBS:
        case Consts.EXAM_TYPES.TEST_FILE:
            return i18next.t('TestKnowledge');
        case Consts.EXAM_TYPES.FREQ:
            return `${i18next.t('FreqExam')} - ${i18next.t('Part')} ${orderNum}`;
        default:
            return i18next.t('TestKnowledge');
    }
};


export const getExamByExamId = (examId) => {
    const exams = getState().userCourses.selectedCourseStruct.allowedExams;
    const freqExamList = exams?.filter(e => (e.type === Consts.EXAM_TYPES.FREQ_PARENT || e.type === Consts.EXAM_TYPES.FREQ));

    for (let i = 0; i < freqExamList?.length; i++) {
        if (freqExamList[i].id === examId) return freqExamList[i];
        else {
            const e = freqExamList[i]?.childs?.find(ex => ex.id === examId);
            if (e) return e;
        }
    }
    return null;
};

export const findNextFreqExam = (parentId, orderNum) => {
    const exams = getState().userCourses.selectedCourseStruct.allowedExams;
    const freqExam = exams.find(e => e.id === parentId);
    return freqExam.childs[orderNum];
};

export const ResultCalculator = (questions) => {
    const res = {
        correctAnswers: 0,
        wrongAnswers: 0,
        notAnswered: 0,
        flaggedQuestions: 0,
        questions: 0
    };

    questions.forEach(q => {

        if (!q.answerOptionId || !q.correctAnswer)
            res.notAnswered++;
        else if (q.correctAnswer && q.correctAnswer.correctAnswerOptionId === q.answerOptionId)
            res.correctAnswers++;
        else res.wrongAnswers++;

        if (q.isFlagged)
            res.flaggedQuestions++;
    });

    res.questions = questions.length;

    return [
        { label: 'correctAnswers', correctAnswers: res.correctAnswers },
        { label: 'wrongAnswers', wrongAnswers: res.wrongAnswers },
        { label: 'notAnswered', notAnswered: res.notAnswered },
        { label: 'flaggedQuestions', flaggedQuestions: res.flaggedQuestions },
        { label: 'questions', questions: res.questions },
    ];
};


export const calcResult = ({ examTotalDegree, examSuccessRatio, worthDegree }) => {
    const passingScore = (examTotalDegree * examSuccessRatio).toFixed(0);
    return worthDegree >= passingScore;
};

export const showRes = (exam) => {
    if (!exam.isFullCorrected) return i18next.t('Pending');
    return calcResult(exam) ? i18next.t('Passed') : i18next.t('Failed');
};