import _ from 'lodash';
import React, { useState, createRef } from 'react';
import { Button, Divider, Header, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import DataGrid, { Column, Editing, Form, Lookup, RequiredRule } from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import TextEditor from '../../Generals/TextEditor';


const ParagraphSection = ({ data, saveSection, deleteSection, cloneSection, moveSectionDown, moveSectionUp, sectionIndex }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const [textEn, setTextEn] = useState(data?.textEn || "");
    const [textAr, setTextAr] = useState(data?.textAr || "");
    const [tasks, setTasks] = useState(data?.tasks || []);
    const [tasksRefs, setTasksRef] = useState(Array.from({ length: tasks?.length }, () => createRef()) || []);

    return (
        <Segment basic>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <Header icon="align left" content={`${t("Section")} #${data.order}`} />
                <div>
                    <Button icon='save' positive onClick={() => saveSection(data.sectionId, { ...data, textAr, textEn, tasks: tasks.map((t, i) => ({ text: t.text, taskId: t.taskId, options: tasksRefs[i].current._instance.getDataSource()._items })) })} />
                    <Button icon='copy' positive onClick={() => cloneSection({ ...data, textAr, textEn, tasks: tasks.map((t, i) => ({ text: t.text, taskId: `${_.uniqueId('task')}_${new Date().getTime()}`, options: tasksRefs[i].current._instance.getDataSource()._items.map(t => ({ ...t, __KEY__: `${_.uniqueId('option')}_${new Date().getTime()}` })) })) })} />
                    <Button icon='trash' negative onClick={() => deleteSection(data.sectionId)} />
                    <Button title={t("Up")} size='small' icon='arrow up' color='blue' onClick={() => moveSectionUp(sectionIndex)} />
                    <Button title={t("Down")} size='small' icon='arrow down' color='blue' onClick={() => moveSectionDown(sectionIndex)} />

                </div>
            </div>
            <Divider />
            <Header>{t("SelectorSectionHowToWork")}</Header>
            <Divider />
            <Segment>
                <Header>{t("EnglishText")}</Header>
                <TextEditor data={{ value: textEn, setValue: setTextEn }} />
                <Header>{t("ArabicText")}</Header>
                <TextEditor isArabic data={{ value: textAr, setValue: setTextAr }} />
            </Segment>
            <Segment>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Header>{t("TasksManagement")}</Header>
                    <Button icon='cog' content={t('GenerateTasks')} primary onClick={() => {
                        const tsks = generateTasksFromText(isArabic ? textAr : textEn);
                        setTasks(tsks);
                        setTasksRef(Array.from({ length: tsks?.length }, () => createRef()) || []);
                    }} />
                </div>
                {
                    tasks.map((t, i) => <TaskOptionsGrid key={i} text={t?.text || ""} options={t?.options || []} gridRef={tasksRefs[i]} />)
                }
            </Segment>
        </Segment>
    );
};

const TaskOptionsGrid = ({ options, gridRef, text }) => {
    const { t } = useTranslation();
    return (
        <>
            <Header>{t("InsertAnswerForTask", { task: text.slice(2) })}</Header>
            <DataGrid
                ref={gridRef}
                dataSource={options}
                showBorders
                columnHidingEnabled
                wordWrapEnabled
                columnAutoWidth
                onInitNewRow={e => e.data.type = "REPLACE"}
            >
                <Editing
                    mode='row'
                    useIcons
                    allowAdding
                    allowUpdating
                    allowDeleting
                >
                    <Form>
                        <Item itemType="group" colCount={2} colSpan={2}>
                            <Item isRequired dataField="nameEn" />
                            <Item isRequired dataField="nameAr" />
                            <Item isRequired dataField="type" />
                            <Item dataField="correctAnswer" />
                        </Item>
                    </Form>
                </Editing>

                <Column dataField="nameEn" caption={t('EnglishName')} />
                <Column dataField="nameAr" caption={t('ArabicName')} />
                <Column dataField="type" caption={t('Type')}>
                    <Lookup dataSource={["REPLACE", "KEEP", "DELETE"]} />
                    <RequiredRule />
                </Column>
                <Column
                    caption={t('Correct')}
                    dataType="boolean"
                    dataField='correctAnswer'
                />
            </DataGrid>
        </>
    );
};


const generateTasksFromText = (text) => {
    const regexp = /@@{(.*?)}/g;
    return Array.from(text.matchAll(regexp), (t) => ({ text: t[0], options: [], taskId: `${_.uniqueId('task')}_${new Date().getTime()}` })) || [];
};

export default ParagraphSection;