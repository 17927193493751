import React, { useEffect, useState } from 'react';
import { Segment, Card } from 'semantic-ui-react';
import { TeamCard } from '..';
import request from '../../api';

const TeamList = () => {
    const [teamMembers, setTeamMembers] = useState('')

    useEffect(() => {
        request.get('/public/team-member').then(d => {
            setTeamMembers(d.data.data);
        });
        // eslint-disable-next-line
    }, []);

    return (
        <Segment basic>
            <Card.Group stackable itemsPerRow='3'>
                {
                    teamMembers && teamMembers.map(i => <TeamCard key={i.id} member={i} />)
                }
            </Card.Group>
        </Segment >
    );
};

export default TeamList;
