import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Header, Icon } from 'semantic-ui-react';
import Digit from './Digit';
import { Consts } from '../../../res';

const TimerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:center;
  direction:ltr;
  margin-top:-20px;
`;

const SepartorContainer = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-end;
  margin: 0;
`;

const Separtor = styled.span`
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: #404549;
  border-radius: 6px;
  margin: 5px 0px;
`;

const TimerStyled = ({ seconds, minutes, hours, days, t, type, theme }) => {
  const isArabic = i18next.language === 'ar';

  let isLastHour = false;
  if (type === 'COUNTDOWN' && hours === 0 && minutes < 10)
    isLastHour = true;

  if (theme !== Consts.EXAM_THEMES.SOCPA)
    return (
      <Header
        as={theme === Consts.EXAM_THEMES.CIA ? 'h5' : 'h3'}
        textAlign='center'
        style={{ color: isLastHour ? 'red' : ((theme === Consts.EXAM_THEMES.CPA) || (theme === Consts.EXAM_THEMES.DIFR)) ? "black" : 'white' }}
      >
        <Icon name='clock outline' />
        <Header.Content>
          {t('TimeSpent')}
          <Header.Subheader>
            <span style={{ color: isLastHour ? 'red' : ((theme === Consts.EXAM_THEMES.CPA) || (theme === Consts.EXAM_THEMES.DIFR)) ? "black" : 'white' }}>
              {
                isArabic ? `${seconds} : ${minutes} : ${hours} : ${days}`
                  : `${days} : ${hours} : ${minutes} : ${seconds}`
              }
            </span>
          </Header.Subheader>
        </Header.Content>
      </Header>
    );

  return (
    <TimerContainer>
      {days ? <Digit value={days} title={t('Day')} addSeparator isLastHour={isLastHour} /> : null}
      {days ? <SepartorContainer><Separtor /><Separtor /></SepartorContainer> : null}
      {hours ? <Digit value={hours} title={t('Hour')} addSeparator isLastHour={isLastHour} /> : null}
      {hours ? <SepartorContainer><Separtor /><Separtor /></SepartorContainer> : null}
      <Digit value={minutes} title={t('Minute')} addSeparator isLastHour={isLastHour} />
      <SepartorContainer><Separtor /><Separtor /></SepartorContainer>
      <Digit value={seconds} title={t('Second')} isLastHour={isLastHour} />
    </TimerContainer>
  );
};

export default withTranslation()(TimerStyled);