import React from 'react';
import TextQuestionTemplate from './TextQuestionTemplate';
import SelectorQuestionTemplate from './SelectorQuestionTemplate';
import ParagraphQuestionTemplate from './ParagraphQuestionTemplate';
import TableQuestionTemplate from './TableQuestionTemplate';
import CheckBoxesQuestionTemplate from './CheckBoxesQuestionTemplate';
import { Consts } from '../../../../../res';


const CompositeTemplate = ({ Q, onAnswerQuestion, isViewOnly }) => {
    const sections = JSON.parse(Q?.contentJson || "[]");
    const sectionsAnswers = JSON.parse(Q?.answerJson || "[]");

    const onUserAnswerSection = (sectionAnswer) => {
        const sectionIndex = sectionsAnswers.findIndex(s => s.sectionId === sectionAnswer.sectionId);
        if (sectionIndex !== -1) {
            const temp = [...sectionsAnswers];
            temp.splice(sectionIndex, 1, sectionAnswer);
            onAnswerQuestion({ ...Q, answerJson: JSON.stringify(temp) });
        } else {
            onAnswerQuestion({ ...Q, answerJson: JSON.stringify([...sectionsAnswers, sectionAnswer]) });
        }
    };

    return (
        <div>
            {
                sections.map((s, i) => <Section key={i} data={{ ...s, userAnswer: sectionsAnswers.find(answer => answer?.sectionId === s.sectionId), onUserAnswerSection, isViewOnly, isSubmited: Q?.isSubmited }} />)
            }
        </div>
    );
};


const Section = ({ data }) => {
    switch (data.type) {
        case Consts.QUESTION_BUILDER_SECTIONS_TYPES.TEXT_SECTION:
            return <TextQuestionTemplate {...data} />;
        case Consts.QUESTION_BUILDER_SECTIONS_TYPES.SELECTOR_SECTION:
            return <SelectorQuestionTemplate {...data} />;
        case Consts.QUESTION_BUILDER_SECTIONS_TYPES.PARAGRAPH_SECTION:
            return <ParagraphQuestionTemplate {...data} />;
        case Consts.QUESTION_BUILDER_SECTIONS_TYPES.TABLE_SECTION:
            return <TableQuestionTemplate {...data} />;
        case Consts.QUESTION_BUILDER_SECTIONS_TYPES.CHECKBOXES_SECTION:
            return <CheckBoxesQuestionTemplate {...data} />;

        default:
            return null;
    }
};

export default CompositeTemplate;