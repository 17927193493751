import cookies from 'react-cookies';
import history from '../history';

export const savePath = (pathPref) => {
    const cookiesPath = cookies.load(pathPref);
    const path = window.location.pathname + window.location.search;
    if (cookiesPath) cookies.remove(pathPref);
    cookies.save(pathPref, path, {
        path: '/',
        expires: new Date().setDate(Date.now() + 1000 * 60 * 60),
        maxAge: 60 * 60 * 24
    });
};

export const moveToPath = (pathPref) => {
    const oldPath = cookies.load(pathPref);
    if (oldPath) {
        cookies.save(pathPref, "", { path: '/' });
        history.push(oldPath);
        return true;
    }

    return false;
};