import React from 'react';

const allowCopyPaste = (e) => {
    if (e.type === 'copy') {
        const text_only = document.getSelection().toString();
        const clipdata = e.clipboardData || window.clipboardData;
        clipdata.setData('text/plain', text_only);
        clipdata.setData('text/html', text_only);
        e.preventDefault();
    }
    e.stopPropagation();
};

const AllowCopyPaste = ({ children }) => {
    return (
        <div unselectable='off' onCopy={allowCopyPaste} onPaste={allowCopyPaste} onDrag={allowCopyPaste} onDrop={allowCopyPaste} onSelect={allowCopyPaste} className='allowCopyPaste' >
            {children}
        </div>
    );
};

export default AllowCopyPaste;