import React, { useState, useEffect } from 'react';
import { Router as PlainRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Segment } from 'semantic-ui-react';
import i18n from 'i18next';
import { ErrorBoundary } from "react-error-boundary";
import cookies from 'react-cookies';
import Layouts from './layouts';
import history from './history';
import { checkAuth, getAllCountries, saveNotificationToken } from './actions';
import onLangChange from './helpers/checkLang';
import { firebaseMessagingInit } from './NotificationsManager';
import { ErrorSegment } from './components';
import request from './api';

function FallbackComp({ error, resetErrorBoundary }) {
    return (
        <Segment basic textAlign='center'>
            <ErrorSegment text={i18n.t("ErrorUncaught")} />
            <Button negative onClick={resetErrorBoundary} icon='redo' content={i18n.t("TryAgain")} />
            <Button primary as={Link} to='/' onClick={resetErrorBoundary} icon='home' content={i18n.t("GoHome")} />
        </Segment>
    );
}

const Main = (props) => {
    const cookiesLang = cookies.load('languagePref');
    const { user, checkAuth, getAllCountries, saveNotificationToken } = props;
    const [lang, setLang] = useState(cookiesLang || i18n.language);
    // const divRef = createRef();
    // const [showSidebar, setShowSidebar] = useState(false);

    // const onToggleSidebar = (value) => {
    //     setShowSidebar(value);
    // };

    useEffect(() => {
        checkAuth();
        getAllCountries();
        onLangChange(lang, setLang);
        firebaseMessagingInit(saveNotificationToken);
        // eslint-disable-next-line
    }, []);

    const logError = (error, { componentStack }) => {
        request.post('/public/log',
            { type: "CLIENT_ERROR", message: error?.message, userId: user?.id, stack: componentStack, pageUrl: window.location.pathname },
            { headers: { "Log-Auth": (new Date().getUTCHours() * 19) ^ 19930727 } });
    };


    return (
        <PlainRouter history={history}>
            <ErrorBoundary
                FallbackComponent={FallbackComp}
                onError={logError}
            >
                <Layouts user={user} />
            </ErrorBoundary>
        </PlainRouter>
        /* <SideBarNav toggle={onToggleSidebar} showSidebar={showSidebar} user={user}>
            <div ref={divRef}>
                <Suspense fallback={<Spinner />}>
                    <Sticky context={divRef}>
                            <NavBar lang={lang} setLang={setLang} />
                        </Sticky>

                    <Footer />


                </Suspense>
            </div>
        </SideBarNav>

        <ScrollToTop />
        <WhastsappButton showSidebar={showSidebar} />
        <MessagesButton user={user} showSidebar={showSidebar} />
        <PanelButton lang={lang} toggle={onToggleSidebar} showSidebar={showSidebar} user={user} /> */

    );
};

const mapStateTopProps = ({ auth }) => {
    const { user } = auth;
    return { user };
};
export default connect(mapStateTopProps, { checkAuth, getAllCountries, saveNotificationToken })(Main);